import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Button,
  Message,
  MessageKind,
} from '@lumx/react';

import { useStoreActions } from 'hooks';

interface NotConnectedToMicroOrGoogleProps {
  microsoft?: boolean;
}
const NotConnectedToMicroOrGoogle = ({
  microsoft,
}: NotConnectedToMicroOrGoogleProps) => {
  const openUserProfileDialog = useStoreActions(
    (actions) => actions.user.openUserProfileDialog,
  );
  return (
    <Message kind={MessageKind.info} hasBackground>
      <p>
        {microsoft ? (
          <FormattedMessage id="NOT_MICROSOFT_CONNECTED_HELPER" />
        ) : (
          <FormattedMessage id="NOT_GOOGLE_CONNECTED_HELPER" />
        )}
      </p>
      <div className="lumx-spacing-padding-big">
        <Button onClick={() => openUserProfileDialog()}>
          <FormattedMessage id="OPEN_USER_PROFILE" />
        </Button>
      </div>
    </Message>
  );
};

export default NotConnectedToMicroOrGoogle;
