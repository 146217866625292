import { useStoreState } from 'hooks'
import React from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

const ImportWorkflow = () => {
    const currentStep = useStoreState(state => state.workflow.modal.currentStep)
    return (
        <div>
            {currentStep === 0 && <Step1 />}
            {currentStep === 1 && <Step2 />}
            {currentStep === 2 && <Step3 />}
        </div>
    )
}

export default ImportWorkflow
