/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { STATUS_CODES } from 'http';
import { IProviderToken, IUser, NotificationsErrors } from 'models';
import ApiClient from 'services/client';
import { IInjections, IStore } from 'store';
import { getParentOrigin } from 'utils';

const initialUserModel = {
  currentLumappsPlatformBaseUrl: '',
  currentLumappsPlatformID: '',
  isAuthenticated: false,
  email: undefined,
  fullname: undefined,
  profilePictureUrl: undefined,
  notificationsEnabled: undefined,
  lang: undefined,
  isAuthenticatedWith: undefined,
  tokens: undefined,
  isSuperAdminOnLumappsPlatform: false,
};

export interface IUserModel extends IUser {
  isUserProfileDialogOpen: boolean;
  openUserProfileDialog: Action<IUserModel>;
  closeUserProfileDialog: Action<IUserModel>;

  setUser: Action<IUserModel, IUser>;
  _changeNotifications: Action<IUserModel>;
  updateGoogleToken: Action<IUserModel, IProviderToken>;
  updateMicrosotToken: Action<IUserModel, IProviderToken>;
  removeProviderToken: Action<IUserModel, string>

  userLumappsSites: Array<any> | undefined;
  setUserLumappsSites: Action<IUserModel, any>;
  getUserLumappsSites: Thunk<IUserModel, void, IInjections, IStore>;

  initUser: Thunk<IUserModel, any, IInjections>;
  changeNotifications: Thunk<IUserModel, void, IInjections>;

  refreshCurrentUser: Thunk<IUserModel, void, IInjections>;
  logoutUserFromProvider: Thunk<IUserModel, string, IInjections>;
  loginUser: Thunk<IUserModel, string, IInjections>;
  getProviderToken: Thunk<
    IUserModel,
    'google' | 'microsoft',
    IInjections,
    IStore
  >;
  setUserLang: Action<IUserModel, string>
}

export const userModel: IUserModel = {
  ...initialUserModel,

  isUserProfileDialogOpen: false,
  openUserProfileDialog: action((state) => {
    state.isUserProfileDialogOpen = true;
  }),
  closeUserProfileDialog: action((state) => {
    state.isUserProfileDialogOpen = false;
  }),

  // Actions
  setUser: action((state, user) => {
    Object.entries(user).forEach(([key, val]) => {
      // @ts-ignore
      state[key] = val;
    });
  }),
  _changeNotifications: action((state) => {
    state.notificationsEnabled = !state.notificationsEnabled;
  }),
  updateGoogleToken: action((state, token) => {
    const googleToken = {
      token: token.token,
      expiresAt: token.expiresAt,
    };
    state.tokens
      ? (state.tokens.google = googleToken)
      : (state.tokens = { google: googleToken });
  }),
  updateMicrosotToken: action((state, token) => {
    const microsoftToken = {
      token: token.token,
      expiresAt: token.expiresAt,
    };
    state.tokens
      ? (state.tokens.microsoft = microsoftToken)
      : (state.tokens = { microsoft: microsoftToken });
  }),
  removeProviderToken: action((state, provider) => {
    switch (provider) {
      case 'google':
        delete state?.tokens?.google
        break;

      case 'microsoft':
        delete state?.tokens?.microsoft
        break;

      default:
        break;
    }
  }),

  // User lumapps sites
  userLumappsSites: undefined,
  setUserLumappsSites: action((state, sites) => {
    state.userLumappsSites = sites;
  }),
  getUserLumappsSites: thunk(async (actions, _, { injections, getState }) => {
    const { lumappsService } = injections;
    const state = getState();

    const { data: sites, status } = await lumappsService.getUserLumappsSites(
      state.currentLumappsPlatformID,
      state.currentLumappsPlatformBaseUrl
    );
    if (status === 200) {
      actions.setUserLumappsSites(sites);
    }
  }),

  // Thunks
  initUser: thunk(async (actions, _, { injections }) => {
    const { api, lumappsService } = injections;

    try {
      const currentLumappsPlatformBaseUrl =
        getParentOrigin() || 'https://sites.lumapps.com';
      const lumappsUser = await lumappsService.getCurrentUserFromLumapps(
        currentLumappsPlatformBaseUrl
      );
      let profilePictureUrl;
      const thumbnail = lumappsUser?.apiProfile?.thumbnail;
      if (thumbnail) {
        const photoData = thumbnail?.photoData;
        const mimeType = thumbnail?.mimeType
          ? thumbnail.mimeType
          : 'image/jpeg';
        profilePictureUrl = `data:${mimeType};base64,${photoData}`;
      } else {
        profilePictureUrl = lumappsUser?.profilePictureUrl;
      }
      const { data, status } = await ApiClient.init();

      if (status === 200 || status === 201) {
        api.client.defaults.headers.Authorization = `Bearer ${data.token}`;
        actions.setUser({
          ...data.user,
          currentLumappsPlatformID:
            data?.user?.customer || lumappsUser.customer,
          currentLumappsPlatformBaseUrl,
          profilePictureUrl,
          isAuthenticated: true,
        });
        actions.getUserLumappsSites();
      }
    } catch {}
  }),
  changeNotifications: thunk((actions) => {
    // TODO: Save user
    actions._changeNotifications();
  }),
  refreshCurrentUser: thunk(async (actions, _, { injections }) => {
    const { userService } = injections;
    const { data: user }: any = await userService.getCurrentUser();
    // Dirty fix
    delete user.isSuperAdminOnLumappsPlatform;
    actions.setUser({ ...user });
  }),
  logoutUserFromProvider: thunk(async (actions, provider, { injections }) => {
    const { userService } = injections;
    await userService.logoutUserFromProvider(provider);
    const { data: user }: any = await userService.getCurrentUser();
    // Dirty fix
    delete user.isSuperAdminOnLumappsPlatform;
    actions.setUser({ ...user });
    actions.removeProviderToken(provider)
  }),
  loginUser: thunk(async (_, payload, { injections }) => {
    const { userService } = injections;
    const { data }: any = await userService.loginWithProvider(payload);
    return data;
  }),
  getProviderToken: thunk(
    async (actions, provider, { injections, getState, getStoreActions }) => {
      const { userService } = injections;
      const user = getState();
      const notifAction = getStoreActions().notifications;

      // @ts-ignore
      if (
        user.tokens &&
        user.tokens[provider] &&
        // @ts-ignore
        new Date(user.tokens[provider].expiresAt * 1000) > new Date()
      ) {
        // Avoid refreshing token if we already have one that's valid
        return;
      }
      const { data, status }: any = await userService.getProviderToken(
        provider
      );
      if (![200, 204].includes(status)) {
        notifAction.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        return;
      }
      switch (provider) {
        case 'google':
          actions.updateGoogleToken({
            token: data.token,
            expiresAt: data.expiresAt,
          });
          break;

        case 'microsoft':
          actions.updateMicrosotToken({
            token: data.token,
            expiresAt: data.expiresAt,
          });
          break;

        default:
          break;
      }
    }
  ),
  setUserLang: action((state, lang) => {
    state.lang = lang
  })
};
