/* eslint-disable no-param-reassign */
import {
  ActionOn, actionOn, Thunk, thunk,
} from 'easy-peasy';
import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import { IInjections, IStore } from 'store';
import { GroupImportDTO } from 'models/dtos';
import BaseImportStore from './baseImportStore';

export interface GroupImportStore extends BaseImportStore {
  startImport: Thunk<GroupImportStore, void, IInjections, IStore>;

  validateStep: Thunk<GroupImportStore, number, IInjections, IStore, boolean>;
  endWorkflow: ActionOn<GroupImportStore, IStore>;
}

const groupImportStore: GroupImportStore = {
  startImport: thunk(
    async (
      actions,
      _,
      { injections, getStoreActions, getStoreState },
    ) => {
      const storeState = getStoreState();
      const storeActions = getStoreActions();
      const { importService } = injections;

      const fileInfos = storeActions.workflow.import.common.prepareImportFileInfos();
      const groupImportDTO: GroupImportDTO = {
        platformID: storeState.user.currentLumappsPlatformID,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        fileInfos,
        instanceId: '0',
        instanceName: '[PLATFORM]',
      };
      const { status } = await importService.startImportGroup(groupImportDTO);
      if (status !== 200) {
        storeActions.notifications.notify({
          message: NotificationsErrors.GROUP_IMPORT_ERROR,
          severity: 'error',
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.IMPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
    },
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions }) => {
    // Validate or redirect validating depending on the current step we are in
    switch (stepNumber) {
      case 0:
        return getStoreActions().workflow.import.common.validateStep(
          stepNumber,
        );
      default:
        return true;
    }
  }),
  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {},
  ),
};

export default groupImportStore;
