import React from 'react';

import { useStoreState } from 'hooks';
import { DashboardCategory } from 'models/dashboard';

import ContentSummary from './ContentSummary';
import DirectoryExportSummary from './DirectoryExportSummary';
import GroupExportSummary from './GroupExportSummary';
import MetadataExportSummary from './MetadataExportSummary';
import PlanificationSummary from './PlanificationSummary';
import UserExportSummary from './UserExportSummary';
import ExportDestinationSummary from './ExportDestinationSummary';
import CommunityExportSummary from './CommunityExportSummary';

const Step4: React.FC = () => {
  const category = useStoreState((state) => state.workflow.modal.category);
  const isPlanified = useStoreState(
    (state) => state.workflow.export.planification.isPlanified
  );
  return (
    <div className="lumx-spacing-padding-huge">
      {isPlanified && <PlanificationSummary />}
      {category === DashboardCategory.user && <UserExportSummary />}
      {category === DashboardCategory.directory && <DirectoryExportSummary />}
      {category === DashboardCategory.group && <GroupExportSummary />}
      {category === DashboardCategory.metadata && <MetadataExportSummary />}
      {category === DashboardCategory.content && <ContentSummary />}
      {category === DashboardCategory.community && <CommunityExportSummary />}
      <ExportDestinationSummary />
    </div>
  );
};
export default Step4;
