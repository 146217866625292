/* eslint-disable no-param-reassign */
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { IStore, IInjections } from 'store';

interface Notif {
  message: string | undefined;
  severity: 'error' | 'warning' | 'info' | 'success' | undefined;
}

export interface INotificationsStore {
  message: string | undefined;
  severity: 'error' | 'warning' | 'info' | 'success' | undefined;

  isOpen: boolean;

  notify: Thunk<INotificationsStore, Notif, IInjections, IStore>;
  createNotif: Action<INotificationsStore, Notif>;
  clear: Action<INotificationsStore>;
}

const notificationsStore: INotificationsStore = {
  message: undefined,
  severity: undefined,
  isOpen: false,
  notify: thunk((actions, payload) => {
    actions.createNotif(payload);
    setTimeout(() => actions.clear(), 4000);
  }),

  createNotif: action((state, payload) => {
    state.message = payload.message;
    state.severity = payload.severity;
    state.isOpen = true;
  }),
  clear: action((state) => {
    state.severity = undefined;
    state.message = undefined;
    state.isOpen = false;
  }),
};

export default notificationsStore;
