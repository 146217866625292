import { AxiosInstance, AxiosResponse } from 'axios';
import { IUser } from 'models';

export interface IUserService {
  getCurrentUser: () => Promise<AxiosResponse>;
  loginWithProvider: (provider: string) => Promise<AxiosResponse>;
  getProviderToken: (provider: string) => Promise<AxiosResponse>;
  logoutUserFromProvider: (provider: string) => Promise<AxiosResponse>;
  saveUser: (user: IUser) => Promise<AxiosResponse>;
}

export const userServiceFactory = (client: AxiosInstance): IUserService => {
  const userService: IUserService = {
    getCurrentUser: async () => {
      return client.get('/api/user/me');
    },
    loginWithProvider: async (provider: string) => {
      return client.get(`/api/getLoginUrl`, {
        params: {
          provider,
        },
      });
    },
    getProviderToken: async (provider: string) => {
      return client.get(`/api/getAccessToken`, {
        params: {
          provider,
        },
      });
    },
    logoutUserFromProvider: async (provider: string) => {
      return client.get(`/api/revokeToken`, {
        params: {
          provider,
        },
      });
    },
    saveUser: async (user: IUser) => {
      return client.patch('/api/user', user);
    },
  };
  return userService;
};
