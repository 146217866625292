/* eslint-disable no-param-reassign */
import {
  action, Action, ActionOn, actionOn, Thunk, thunk
} from 'easy-peasy';
import { ILumDirectory, ILumSite, NotificationsErrors, NotificationsSuccessMessages } from 'models';
import { UserImportDTO } from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseImportStore from './baseImportStore';

export interface UserImportStore extends BaseImportStore {
  setImportUserDirectorySite: Action<UserImportStore, ILumSite>;
  setImportUserDirectory: Action<UserImportStore, ILumDirectory>;

  loadingAvailableImportDirectories: boolean;
  setLoadingAvailableImportDirectories: Action<UserImportStore, boolean>;
  setAvailableImportDirectories: Action<UserImportStore, any>;
  fetchAvailableImportDirectories: Thunk<
  UserImportStore,
  string,
  IInjections,
  IStore
  >;

  availableImportDirectories: Array<any> | undefined;
  directorySiteName: string | undefined;
  directorySiteId: string | number | undefined;
  directoryName: string | undefined;
  directoryId: string | number | undefined;

  startImport: Thunk<UserImportStore, void, IInjections, IStore>;

  validateStep: Thunk<UserImportStore, number, IInjections, IStore, boolean>;
  endWorkflow: ActionOn<UserImportStore, IStore>;
}

const userImportStore: UserImportStore = {
  availableImportDirectories: undefined,
  directorySiteName: undefined,
  directorySiteId: undefined,
  directoryName: undefined,
  directoryId: undefined,
  loadingAvailableImportDirectories: false,

  setImportUserDirectorySite: action((state, payload) => {
    state.directorySiteId = payload.id;
    state.directorySiteName = payload.name;
  }),
  setImportUserDirectory: action((state, payload) => {
    state.directoryId = payload.id;
    state.directoryName = payload.name;
  }),

  setLoadingAvailableImportDirectories: action((state, loading) => {
    state.loadingAvailableImportDirectories = loading;
  }),
  setAvailableImportDirectories: action((state, directories) => {
    state.availableImportDirectories = directories;
  }),
  fetchAvailableImportDirectories: thunk(
    async (actions, siteId, { injections, getStoreActions, getStoreState }) => {
      actions.setLoadingAvailableImportDirectories(true);
      const { lumappsService } = injections;
      const storeState = getStoreState();

      const {
        data: directories,
        status,
      } = await lumappsService.getSiteUserDirectories(
        siteId,
        storeState.user.currentLumappsPlatformID,
        storeState.user.currentLumappsPlatformBaseUrl,
      );

      if (status === 200) {
        actions.setAvailableImportDirectories(directories);
      } else {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
      }
      actions.setLoadingAvailableImportDirectories(false);
    },
  ),

  startImport: thunk(
    async (
      actions,
      _,
      {
        injections, getStoreActions, getState, getStoreState,
      },
    ) => {
      const state = getState();
      const storeState = getStoreState();
      const storeActions = getStoreActions();
      const { importService } = injections;

      const fileInfos = storeActions.workflow.import.common.prepareImportFileInfos();
      const useImportDTO: UserImportDTO = {
        platformID: storeState.user.currentLumappsPlatformID,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        fileInfos,
        instanceId: '0',
        instanceName: '[PLATFORM]',
        DirectorySiteID: state.directorySiteId?.toString(),
        DirectoryID: state.directoryId?.toString(),
      };
      const { status } = await importService.startImportUsers(useImportDTO);
      if (status !== 200) {
        storeActions.notifications.notify({
          message: NotificationsErrors.USER_IMPORT_ERROR,
          severity: 'error',
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.IMPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
    },
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions, getState }) => {
    let success: boolean = true;

    // Validate or redirect validating depending on the current step we are in
    switch (stepNumber) {
      case 0:
        return getStoreActions().workflow.import.common.validateStep(
          stepNumber,
        );
      case 1:
        // if (!directorySiteId) {
        //   getStoreActions().notifications.notify({
        //     message: NotificationsErrors.USER_IMPORT_NO_SITE_SELECTED,
        //     severity: 'error',
        //   });
        //   success = false;
        // }
        // if (!directoryId) {
        //   getStoreActions().notifications.notify({
        //     message: NotificationsErrors.USER_IMPORT_NO_DIRECTORY_SELECTED,
        //     severity: 'error',
        //   });
        //   success = false;
        // }
        return success;

      default:
        return success;
    }
  }),
  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.availableImportDirectories = undefined;
      state.directoryId = undefined;
      state.directoryName = undefined;
      state.directorySiteId = undefined;
      state.directorySiteName = undefined;
    },
  ),
};

export default userImportStore;
