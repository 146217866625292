import React, { useState, useMemo, useEffect } from 'react';

import dayjs from 'dayjs';
import {
  DatePicker,
  Button,
  Emphasis,
  Size,
  Dropdown,
  Placement,
  List,
  ListItem,
  TextField,
} from '@lumx/react';
import { mdiClockOutline, mdiMenuDown } from '@lumx/icons';

const HALF_AN_HOUR = 30;
const HOURS_IN_A_DAY = 24;

enum DateTimePickerVariants {
  inline = 'inline',
  // modal = 'modal'
}

interface InlineDatePickerProps {
  onDatePicked?: (pickedDate: any) => void;
  locale: string;
}
const InlineDatePicker: React.FC<InlineDatePickerProps> = ({
  onDatePicked,
  locale,
}: InlineDatePickerProps) => {
  /*
   * Date picker
   */
  const [pickedDate, setPickedDate] = useState(dayjs());
  const anchorSimpleRef = React.useRef(null);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const closeDatePicker = () => {
    setDatePickerIsOpen(false);
  };

  useEffect(() => {
    if (onDatePicked && pickedDate) {
      onDatePicked(pickedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedDate]);

  return (
    <>
      <Button
        emphasis={Emphasis.low}
        buttonRef={anchorSimpleRef}
        onClick={() => setDatePickerIsOpen(true)}
        rightIcon={mdiMenuDown}
      >
        {dayjs(pickedDate).locale(locale).format('LL')}
      </Button>

      <Dropdown
        isOpen={datePickerIsOpen}
        closeOnClick
        closeOnEscape
        onClose={closeDatePicker}
        placement={Placement.AUTO_START}
        anchorRef={anchorSimpleRef}
        fitToAnchorWidth={false}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DatePicker
            value={pickedDate.toDate()}
            onChange={setPickedDate}
            locale={locale}
          />
        </div>
      </Dropdown>
    </>
  );
};

interface InlineTimePickerProps {
  onTimePicked?: (hours: any) => void;
  locale: string;
}
const InlineTimePicker: React.FC<InlineTimePickerProps> = ({
  onTimePicked,
  locale,
}: InlineTimePickerProps) => {
  /*
   * Time picker
   */
  const [pickedTime, setPickedTime] = useState<any>(dayjs());

  const [text, setText] = useState(dayjs().format('hh:mm'));

  const handleTextChanged = (val: string) => {
    setText(val);

    const timeRegex = /[0-9]{2}:[0-9]{2}/i;
    if (!timeRegex.test(val)) {
      setPickedTime(null);
      return;
    }
    setPickedTime(val);
  };

  useEffect(() => {
    if (onTimePicked) {
      onTimePicked(pickedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedTime]);

  return (
    <TextField
      icon={mdiClockOutline}
      onChange={handleTextChanged}
      value={text}
      label=""
      helper="Format: hh:mm"
      hasError={!pickedTime}
    />
  );
};

interface DateTimePickerProps {
  variant?: DateTimePickerVariants;
  onDateTimePicked?: (pickedDateTime: any) => void;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  variant = DateTimePickerVariants.inline,
  onDateTimePicked,
}: DateTimePickerProps) => {
  const [pickedDateTime, setPickedDateTime] = useState<any>(dayjs());

  useEffect(() => {
    if (onDateTimePicked) {
      onDateTimePicked(pickedDateTime.toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedDateTime]);

  const onDatePicked = (pickedDate: any) => {
    const hours = pickedDateTime.hour();
    const minutes = pickedDateTime.minute();
    setPickedDateTime(dayjs(pickedDate).hour(hours).minute(minutes));
  };

  const onTimePicked = (pickedTime: any) => {
    if (!pickedTime) {
      setPickedDateTime(null);
    }
    const pi = dayjs(pickedTime, 'hh:mm');
    const hours = pi.hour();
    const minutes = pi.minute();
    setPickedDateTime(dayjs(pickedDateTime).hour(hours).minute(minutes));
  };

  // eslint-disable-next-line no-undef
  const locale = navigator.language;
  return (
    <>
      {variant === DateTimePickerVariants.inline && (
        <>
          <InlineDatePicker onDatePicked={onDatePicked} locale={locale} />
          <InlineTimePicker onTimePicked={onTimePicked} locale={locale} />
        </>
      )}
    </>
  );
};

export default DateTimePicker;
