import React from 'react';

import { FormattedMessage } from 'react-intl';

import { List, ListItem, ListSubheader } from '@lumx/react';

import { useStoreState } from 'hooks';
import { GroupExportType } from 'models/dtos';

const GroupExportSummary = () => {
  const { exportType, selectedSiteName } = useStoreState(
    (state) => state.workflow.export.group,
  );
  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="EXPORT_CHOICE" />
        </ListSubheader>
        <ListItem>
          {exportType === GroupExportType.exportAllGroups && (
            <FormattedMessage id="GROUP_EXPORT_PARAMS_EXPORT_ALL_GROUPS" />
          )}
          {exportType === GroupExportType.exportPlatformGroups && (
            <FormattedMessage id="GROUP_EXPORT_PARAMS_EXPORT_PLATFORM_GROUPS" />
          )}
          {exportType === GroupExportType.exportSiteGroups && (
            <FormattedMessage id="GROUP_EXPORT_PARAMS_EXPORT_SITE_GROUPS" />
          )}
        </ListItem>
        {exportType === GroupExportType.exportSiteGroups && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_SITE" />
            </ListSubheader>
            <ListItem>{selectedSiteName}</ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export default GroupExportSummary;
