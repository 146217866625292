import { mdiPlus } from '@lumx/icons';
import {
  Button,
  Icon,
  IconButton,
  Placement,
  Popover,
  Size,
} from '@lumx/react';
import { useStoreActions, useStoreState } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'Theme';
import WorkflowTypeChoice from './WorkflowTypeChoice';
import WorkflowDialog from './WorkflowDialog';

interface AddButton {
  buttonContent?: string;
  buttonSize?: Size.m | Size.s;
  buttonClasses?: string;
  containerClasses?: string;
}

const AddButton: React.FC<AddButton> = ({
  buttonContent = null,
  buttonSize,
  buttonClasses = '',
  containerClasses = '',
}) => {
  const { theme } = useTheme();

  const category = useStoreState((state) => state.dashboard.category);
  const { setWorkflowCategory, setCurrentStep } = useStoreActions(
    (actions) => actions.workflow.modal
  );

  const [isTooltipDisplayed, setTooltipDisplayed] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (isTooltipDisplayed) {
      setTooltipDisplayed(false);
    }
  }, [category]);

  const openWorkflowDialog = useCallback(() => {
    setWorkflowCategory(category);
    setCurrentStep(-1);
  }, [category]);

  return (
    <div className={containerClasses}>
      {buttonContent ? (
        <Button
          theme={theme.theme}
          className={buttonClasses}
          leftIcon={mdiPlus}
          size={buttonSize}
          buttonRef={anchorRef}
          onClick={openWorkflowDialog}
        >
          {buttonContent}
        </Button>
      ) : (
        <IconButton
          theme={theme.theme}
          className={buttonClasses}
          icon={mdiPlus}
          size={buttonSize}
          buttonRef={anchorRef}
          onClick={openWorkflowDialog}
        />
      )}

      <WorkflowDialog />
    </div>
  );
};

export default AddButton;
