/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import {
  action,
  Action,
  actionOn,
  ActionOn,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
} from 'easy-peasy';
import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import {
  DirectoryEntriesExportType,
  DirectoryExportDTO,
  DirectoryExportType,
  ExportLang,
  IExportFileInfos,
  PlanifDTO,
} from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

interface ISelectChoice {
  value: any;
  name: string;
  displayName: string;
}

export interface DirectoryExportStore extends BaseExportStore {
  selectedLang: ExportLang | undefined;
  selectLang: Action<DirectoryExportStore, ISelectChoice>;

  directoryExportType: DirectoryExportType;
  directoryEntriesExportType: DirectoryEntriesExportType;
  whatToExport: 'directories' | 'directoryEntries';

  selectExportDirectoriesOrExportDirectoriesEntries: Action<
  DirectoryExportStore,
  'directories' | 'directoryEntries'
  >;

  selectExportAllDirectoriesOrExportAllSiteDirectories: Action<
  DirectoryExportStore,
  DirectoryExportType | string
  >;

  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  selectSite: Action<DirectoryExportStore, ISelectChoice>;

  selectDirectoriesEntriesSubparams: Action<DirectoryExportStore, string>;

  selectedDirectoryName: string | undefined;
  selectedDirectoryId: string | undefined;
  siteDirectories: Array<any> | undefined;
  loadingSiteDirectories: boolean;
  setLoadingSiteDirectories: Action<DirectoryExportStore, boolean>;
  fetchSiteDirectories: Thunk<
  DirectoryExportStore,
  string,
  IInjections,
  IStore
  >;
  fetchSiteDirectoriesOnSiteSelect: ThunkOn<
  DirectoryExportStore,
  IInjections,
  IStore
  >;
  setSiteDirectories: Action<DirectoryExportStore, any>;
  selectDirectory: Action<DirectoryExportStore, ISelectChoice>;

  resetChoosenSite: ActionOn<DirectoryExportStore, IStore>;
  startExport: Thunk<DirectoryExportStore, void, IInjections, IStore>;
  validateStep: Thunk<DirectoryExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<DirectoryExportStore, IStore>;
}

const directoryExportStore: DirectoryExportStore = {
  /*
   * Lang choice
   */
  selectedLang: ExportLang.all,
  selectLang: action((state, choice) => {
    state.selectedLang = choice?.name as ExportLang;
  }),

  whatToExport: 'directories',
  directoryExportType: DirectoryExportType.exportAllDirectories,
  directoryEntriesExportType:
    DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries,

  /*
   * Main choices (export directories or export directories entries)
   */
  selectExportDirectoriesOrExportDirectoriesEntries: action((state, choice) => {
    state.whatToExport = choice;
  }),

  /*
   * Export directories sub choices
   */
  selectExportAllDirectoriesOrExportAllSiteDirectories: action(
    (state, directoryExportType) => {
      state.directoryExportType = directoryExportType as DirectoryExportType;
      switch (directoryExportType) {
        case DirectoryExportType.exportAllDirectories:
          state.selectedSiteName = '[ALL_SITES]';
          state.selectedSiteId = '0';
      }
    },
  ),

  selectedSiteId: undefined,
  selectedSiteName: undefined,
  selectSite: action((state, selectedSite) => {
    state.selectedSiteId = selectedSite?.value;
    state.selectedSiteName = selectedSite?.displayName;
  }),

  selectDirectoriesEntriesSubparams: action(
    (state, directoryEntriesExportType) => {
      state.directoryEntriesExportType = directoryEntriesExportType as DirectoryEntriesExportType;
      switch (directoryEntriesExportType) {
        case DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries:
          state.selectedSiteName = '[PLATFORM]';
          state.selectedSiteId = '0';
      }
    },
  ),

  selectedDirectoryName: undefined,
  selectedDirectoryId: undefined,
  siteDirectories: undefined,
  loadingSiteDirectories: false,
  setLoadingSiteDirectories: action((state, loading) => {
    state.loadingSiteDirectories = loading;
  }),

  /*
   * Fetch directories of a site
   */
  fetchSiteDirectories: thunk(
    async (actions, siteId, { injections, getStoreActions, getStoreState }) => {
      actions.setLoadingSiteDirectories(true);
      const { lumappsService } = injections;
      const storeState = getStoreState();

      const { data, status } = await lumappsService.getSiteDirectories(
        siteId,
        storeState.user.currentLumappsPlatformID,
        storeState.user.currentLumappsPlatformBaseUrl,
      );

      if (status !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        return;
      }

      actions.setSiteDirectories(data);
      actions.setLoadingSiteDirectories(false);
    },
  ),

  /*
   * Listener on site select when the choice is
   * export directory entries and from a specific directory
   * we will fetch the site directories
   */
  fetchSiteDirectoriesOnSiteSelect: thunkOn(
    (actions) => actions.selectSite,
    (actions, target, { getState }) => {
      const state = getState();
      if (
        state.selectedSiteId
        && state.whatToExport === 'directoryEntries'
        && state.directoryEntriesExportType
          === DirectoryEntriesExportType.exportDirectoryEntries
      ) {
        actions.fetchSiteDirectories(state.selectedSiteId);
      }
    },
  ),
  setSiteDirectories: action((state, siteDirectories) => {
    state.siteDirectories = siteDirectories;
  }),
  selectDirectory: action((state, selectedDirectory) => {
    state.selectedDirectoryName = selectedDirectory.displayName;
    state.selectedDirectoryId = selectedDirectory.value;
  }),

  resetChoosenSite: actionOn(
    (actions) => [
      actions.selectDirectoriesEntriesSubparams,
      actions.selectExportAllDirectoriesOrExportAllSiteDirectories,
    ],
    (state) => {
      state.selectedSiteId = undefined;
      state.selectedSiteName = undefined;
    },
  ),

  startExport: thunk(
    async (
      actions,
      _,
      {
        injections, getState, getStoreActions, getStoreState,
      },
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();

      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: DirectoryExportDTO = {
        exportToInfos,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        limit: undefined,
        lang: state.selectedLang,
        title: undefined,

        instanceId: state.selectedSiteId || '',
        instanceName: state.selectedSiteName || '',

        directoryEntriesExportType: state.directoryEntriesExportType,
        directoryExportType: state.directoryExportType,

        exportDirectoryEntries: state.whatToExport === 'directoryEntries',
        directoryIds: state.selectedDirectoryId
          ? [state.selectedDirectoryId]
          : [],

        ...planifDTO,
      };

      const { data, status } = await exportService.startDirectoryExport(
        exportData,
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          severity: 'error',
          message: NotificationsErrors.UNEXPECTED_ERROR,
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
      return data;
    },
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions, getState }) => {
    const state = getState();
    let success = true;
    const notifAction = getStoreActions().notifications
    switch (stepNumber) {
      // Parameters step
      case 1:
        if (!state.selectedLang) {
          notifAction.notify({
            message: NotificationsErrors.NO_LANG_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        if (
          state.whatToExport === 'directories'
          && state.directoryExportType
            === DirectoryExportType.exportSiteDirectories
          && !state.selectedSiteId
        ) {
          notifAction.notify({
            message: NotificationsErrors.NO_SITE_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        if (
          state.whatToExport === 'directoryEntries'
          && (state.directoryExportType
            === DirectoryExportType.exportSiteDirectories
            || state.directoryEntriesExportType
              === DirectoryEntriesExportType.exportAllSiteDirectoriesEntries
            || state.directoryEntriesExportType
              === DirectoryEntriesExportType.exportDirectoryEntries)
          && !state.selectedSiteId
        ) {
          notifAction.notify({
            message: NotificationsErrors.NO_SITE_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        if (
          state.whatToExport === 'directoryEntries'
          && state.directoryEntriesExportType
            === DirectoryEntriesExportType.exportDirectoryEntries
          && state.selectedSiteId
          && !state.selectedDirectoryId
        ) {
          notifAction.notify({
            message: NotificationsErrors.NO_SITE_DIRECTORY_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        return success;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.selectedLang = ExportLang.all;

      state.whatToExport = 'directories';
      state.directoryExportType = DirectoryExportType.exportAllDirectories;
      state.directoryEntriesExportType = DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries;

      state.selectedSiteId = undefined;
      state.selectedSiteName = undefined;

      state.siteDirectories = undefined;
      state.selectedDirectoryId = undefined;
      state.selectedDirectoryName = undefined;
    },
  ),
};

export default directoryExportStore;
