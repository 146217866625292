/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { action, Action, actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';
import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import {
  IExportFileInfos,
  MetadataExportDTO,
  MetadataExportType,
  PlanifDTO,
} from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

interface ISelectChoice {
  value: any;
  name: string;
  displayName: string;
}

export interface MetadataExportStore extends BaseExportStore {
  exportType: MetadataExportType;
  setMetadataExportType: Action<
    MetadataExportStore,
    MetadataExportType | string
  >;

  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  selectSite: Action<MetadataExportStore, ISelectChoice>;

  startExport: Thunk<MetadataExportStore, void, IInjections, IStore>;
  validateStep: Thunk<MetadataExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<MetadataExportStore, IStore>;
}

const metadataExportStore: MetadataExportStore = {
  exportType: MetadataExportType.exportAllMetadata,
  setMetadataExportType: action((state, exportType) => {
    state.exportType = exportType as MetadataExportType;
    switch (exportType) {
      case MetadataExportType.exportAllMetadata:
        state.selectedSiteName = '[ALL_SITES]';
        break;
      case MetadataExportType.exportPlatformMetadata:
        state.selectedSiteName = '[PLATFORM]';
        break;
      default:
        state.selectedSiteName = undefined;
        break;
    }
  }),

  selectedSiteId: undefined,
  selectedSiteName: undefined,
  selectSite: action((state, site) => {
    state.selectedSiteId = site?.value;
    state.selectedSiteName = site?.displayName;
  }),

  startExport: thunk(
    async (
      actions,
      _,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();

      const instanceId = state.selectedSiteId || '';
      const instanceName = state.selectedSiteName || '';

      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: MetadataExportDTO = {
        exportToInfos,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        limit: undefined,
        lang: undefined,
        title: undefined,

        exportType: state.exportType,
        instanceId,
        instanceName,

        ...planifDTO,
      };

      const { data, status } = await exportService.startMetadataExport(
        exportData
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          severity: 'error',
          message: NotificationsErrors.UNEXPECTED_ERROR,
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
      return data;
    }
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions, getState }) => {
    const state = getState();
    let success = true;
    switch (stepNumber) {
      // Parameters step
      case 1:
        if (
          state.exportType === MetadataExportType.exportSiteMetadata &&
          !state.selectedSiteName
        ) {
          getStoreActions().notifications.notify({
            message: NotificationsErrors.NO_SITE_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        return success;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.exportType = MetadataExportType.exportAllMetadata;
      state.selectedSiteId = undefined;
      state.selectedSiteName = undefined;
    }
  ),
};

export default metadataExportStore;
