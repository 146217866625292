/* eslint-disable no-param-reassign */
import {
  action, Action, ActionOn, actionOn, Thunk, thunk,
} from 'easy-peasy';
import { NotificationsErrors } from 'models';
import { IInjections, IStore } from 'store';
import { FileProvider } from 'models/provider';
import { IImportFileInfos } from 'models/dtos';
import BaseImportStore from './baseImportStore';

interface PickedFile {
  displayName: string;
  name: string;
  id: string | undefined;
  deleteUrl?: string | undefined;
  file?: any;
  driveID?: string;
}

export interface CommonImportStore extends BaseImportStore {
  provider: FileProvider;
  setProvider: Action<CommonImportStore, FileProvider>;

  pickedFile: PickedFile | undefined;
  setPickedFilePrivate: Action<CommonImportStore, PickedFile | undefined>;
  setPickedFile: Thunk<
  CommonImportStore,
  PickedFile | undefined,
  IInjections,
  IStore
  >;
  deleteUnpickedFile: Thunk<
  CommonImportStore,
  PickedFile | undefined,
  IInjections,
  IStore
  >;
  // IImportFileInfos
  uploadAndPickFile: Thunk<CommonImportStore, File, IInjections, IStore>;

  prepareImportFileInfos: Thunk<
  CommonImportStore,
  void,
  IInjections,
  IStore,
  IImportFileInfos
  >;

  validateStep: Thunk<CommonImportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<CommonImportStore, IStore>;

  launchExport: Thunk<CommonImportStore>;
}

const commonImportStore: CommonImportStore = {
  provider: FileProvider.csv,
  setProvider: action((state, provider) => {
    state.provider = provider;
  }),

  pickedFile: undefined,
  setPickedFilePrivate: action((state, pickedFile) => {
    state.pickedFile = pickedFile;
  }),
  setPickedFile: thunk((actions, pickedFile, { getState }) => {
    if (!pickedFile) {
      const currentPickedFile = getState().pickedFile;
      actions.deleteUnpickedFile(currentPickedFile);
    }
    actions.setPickedFilePrivate(pickedFile);
  }),
  deleteUnpickedFile: thunk(
    async (actions, pickedFile, { injections, getStoreActions }) => {
      const { importService } = injections;
      const deleteUrl = pickedFile?.deleteUrl;
      if (deleteUrl) {
        const { data, status } = await importService.deleteFile(deleteUrl);
        if (status !== 200) {
          getStoreActions().notifications.notify({
            message: NotificationsErrors.UNEXPECTED_ERROR,
            severity: 'error',
          });
        }
      }
    },
  ),

  uploadAndPickFile: thunk(
    async (actions, file, { injections, getStoreActions, getStoreState }) => {
      const { importService } = injections;
      const storeState = getStoreState();

      const platformID = storeState.user.currentLumappsPlatformID;
      const originalFilename = file.name;
      const {
        data: uploadedFile,
        status: uploadStatus,
      } = await importService.getUploadUrl(originalFilename, platformID);
      if (uploadStatus !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UPLOAD_FILE_ERROR,
          severity: 'error',
        });
        return;
      }

      const { url: uploadUrl, deleteUrl, filename } = uploadedFile;

      let uploadError;
      try {
        const { status } = await importService.uploadFile(file, uploadUrl);
        if (status !== 200) {
          uploadError = true;
        }
      } catch (e) {
        console.error(e);
        uploadError = true;
      }

      if (uploadError) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UPLOAD_FILE_ERROR,
          severity: 'error',
        });
        actions.setPickedFile(undefined);
        return;
      }

      actions.setPickedFile({
        displayName: originalFilename,
        name: filename,
        id: uploadedFile.gcsFilePath || filename,
        deleteUrl,
        file,
      });
    },
  ),

  prepareImportFileInfos: thunk((a, p, { getState }) => {
    const state = getState();
    const fileInfos: IImportFileInfos = {
      source: state.provider,
      fileID: state.pickedFile?.id || '',
      driveID: state.pickedFile?.driveID || '',
      filename: state.pickedFile?.name || '',
    };
    return fileInfos;
  }),

  validateStep: thunk((actions, stepNumber, { getState, getStoreActions }) => {
    const state = getState();
    switch (stepNumber) {
      case 0:
        if (!state.pickedFile || !state?.pickedFile?.id) {
          getStoreActions().notifications.notify({
            message: NotificationsErrors.IMPORT_NO_FILE_SELECTED,
            severity: 'error',
          });
          return false;
        }
        return true;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.provider = FileProvider.csv;
      state.pickedFile = undefined;
      // TODO: GDPR => remove picked file (server side)
    },
  ),

  launchExport: thunk((actions) => {}),
};

export default commonImportStore;
