import React, { useReducer } from 'react';
import { Theme } from '@lumx/react';

import ThemeContext from './ThemeContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ITheme } from './model';

interface IThemeProvider {
  initialState: ITheme;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
}

const reducer = (theme: ITheme, action: string) => {
  const currentTheme = { ...theme };
  currentTheme.backgroundClass = currentTheme.theme === Theme.dark
    ? 'lumx-theme-background-light-N'
    : 'lumx-theme-background-dark-N';
  currentTheme.colorClass = currentTheme.theme === Theme.dark
    ? 'lumx-theme-color-dark-N'
    : 'lumx-theme-color-light-N';

  switch (action) {
    case 'changeTheme':
      currentTheme.theme = currentTheme.theme === Theme.dark ? Theme.light : Theme.dark;
      return theme;
    default:
      return theme;
  }
};

const ThemeProvider: React.FunctionComponent<IThemeProvider> = ({
  initialState,
  children,
}: IThemeProvider): React.ReactElement => {
  const [theme, dispatch] = useReducer(reducer, initialState);

  return (
    <ThemeContext.Provider value={{ theme, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
