/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';

import { IntlProvider, FormattedMessage } from 'react-intl';

import { Notification, Progress, Theme } from '@lumx/react';

import { ThemeProvider } from 'Theme';
import { useStoreActions, useStoreState } from 'hooks';

import translations_fr from 'translations/locale/fr_FR.json';
import translations_en from 'translations/locale/en.json';
import translations_es from 'translations/locale/es.json';
import Dashboard from './containers/Dashboard';

const translations: any = {
  fr: translations_fr,
  en: translations_en,
  es: translations_es,
};

// eslint-disable-next-line no-nested-ternary
const defaultLocale = (window as any).CONNECTED_USER
  ? (window as any).CONNECTED_USER.lang
  : navigator.language
  ? navigator.language.split('-')[0]
  : 'en';

const supportedLangs = ['en', 'es', 'fr'];

const ThemeInitialState = {
  theme: Theme.light,
  spacing: 4,
};

const App = () => {
  const [loading, setLoading] = useState(true);

  const notificationsState = useStoreState((state) => state.notifications);
  const userState = useStoreState((state) => state.user);

  const { refreshCurrentUser, initUser, setUserLang } = useStoreActions(
    (actions) => actions.user
  );

  const locale =
    userState.lang && supportedLangs.includes(defaultLocale)
      ? defaultLocale
      : 'en';

  const init = React.useCallback(async () => {
    setLoading(true);
    //@ts-ignore
    await initUser();
    setLoading(false);
    refreshCurrentUser();
    setUserLang(locale);
  }, [locale, initUser, setLoading, refreshCurrentUser]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initUser]);

  useEffect(() => {
    setUserLang(locale);
  }, [locale]);

  return (
    <>
      {!loading && !userState.isAuthenticated && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          Could not authenticate
        </div>
      )}
      {loading && !userState.isAuthenticated && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Progress />
        </div>
      )}
      {!loading && userState.isAuthenticated && (
        <ThemeProvider initialState={ThemeInitialState}>
          <IntlProvider locale={locale} messages={translations[locale]}>
            <Dashboard />
            <Notification
              isOpen={notificationsState.isOpen}
              content={
                (<FormattedMessage id={notificationsState.message} />) as any
              }
              type={notificationsState.severity as any}
            />
          </IntlProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
