/* eslint-disable no-param-reassign */
import {
    action,
    Action,
    actionOn,
    ActionOn,
    thunk,
    Thunk,
    ThunkOn,
    thunkOn
} from 'easy-peasy';
import {
    ILumCCT, NotificationsErrors,
    NotificationsSuccessMessages
} from 'models';
import { ContentExportDTO, IExportFileInfos, PlanifDTO } from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

interface ISelectChoice {
  value: any;
  name: string;
  displayName: string;
}

export interface ContentExportStore extends BaseExportStore {
  selectedLang: string;
  selectLang: Action<ContentExportStore, ISelectChoice>;

  exportAllContents: boolean;
  exportSiteContents: boolean;
  setContentExportChoice: Action<ContentExportStore, string>;
  onSetContentExportChoice: ActionOn<ContentExportStore, IStore>;

  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  selectSite: Action<ContentExportStore, ISelectChoice>;

  exportAllContentTypes: boolean;
  exportContentType: boolean;
  setExportSiteContentsChoice: Action<ContentExportStore, string>;

  loadingContentType: boolean;
  setLoadingContentType: Action<ContentExportStore, boolean>;
  siteContentTypes: Array<ILumCCT> | undefined;
  setSiteContentTypes: Action<ContentExportStore, Array<ILumCCT> | undefined>;
  fetchSiteContentTypes: Thunk<ContentExportStore, string, IInjections, IStore>;
  fetchSiteContentTypesOnSiteSelect: ThunkOn<
    ContentExportStore,
    IInjections,
    IStore
  >;

  selectedContentTypeId: string | undefined;
  selectedContentTypeName: string | undefined;
  selectContentType: Action<ContentExportStore, ISelectChoice>;

  startExport: Thunk<ContentExportStore, void, IInjections, IStore>;
  validateStep: Thunk<ContentExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<ContentExportStore, IStore>;
}

const contentExportStore: ContentExportStore = {
  /*
   * Lang choice
   */
  selectedLang: 'ALL',
  selectLang: action((state, choice) => {
    state.selectedLang = choice?.name;
  }),

  exportAllContents: true,
  exportSiteContents: false,
  setContentExportChoice: action((state, choice) => {
    state.exportAllContents = choice === 'exportAllContents';
    state.exportSiteContents = choice === 'exportSiteContents';
  }),
  onSetContentExportChoice: actionOn(
    (actions) => actions.setContentExportChoice,
    (state) => {
      if(state.exportAllContents) {
        state.selectedSiteId = '0'
        state.selectedSiteName = '[PLATFORM]'
      }
    }
  ),

  selectedSiteId: undefined,
  selectedSiteName: undefined,
  selectSite: action((state, site) => {
    state.selectedSiteId = site?.value;
    state.selectedSiteName = site?.displayName;
  }),

  exportAllContentTypes: true,
  exportContentType: false,
  setExportSiteContentsChoice: action((state, choice) => {
    state.exportAllContentTypes = choice === 'exportAllContentTypes';
    state.exportContentType = choice === 'exportContentType';
  }),

  loadingContentType: false,
  setLoadingContentType: action((state, loading) => {
    state.loadingContentType = loading;
  }),
  siteContentTypes: undefined,
  setSiteContentTypes: action((state, ccts) => {
    state.siteContentTypes = ccts;
  }),

  fetchSiteContentTypes: thunk(
    async (actions, siteId, { injections, getStoreState, getStoreActions }) => {
      actions.setLoadingContentType(true);

      const { lumappsService } = injections;
      const storeState = getStoreState();

      const { data: ccts, status } = await lumappsService.getSiteCCTs(
        siteId,
        storeState.user.currentLumappsPlatformID,
        storeState.user.currentLumappsPlatformBaseUrl
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        actions.setLoadingContentType(false);
        return;
      }

      actions.setSiteContentTypes(ccts);
      actions.setLoadingContentType(false);
    }
  ),

  fetchSiteContentTypesOnSiteSelect: thunkOn(
    (actions) => [actions.selectSite, actions.setExportSiteContentsChoice],
    (actions, target, { getState }) => {
      const state = getState();
      if (state.selectedSiteId && state.exportContentType) {
        actions.fetchSiteContentTypes(state.selectedSiteId);
      }
    }
  ),

  selectedContentTypeId: undefined,
  selectedContentTypeName: undefined,
  selectContentType: action((state, contentType) => {
    state.selectedContentTypeId = contentType?.value;
    state.selectedContentTypeName = contentType?.displayName;
  }),

  startExport: thunk(
    async (
      actions,
      _,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();

      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: ContentExportDTO = {
        exportToInfos,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        limit: undefined,
        lang: undefined,
        title: undefined,

        instanceId: state.selectedSiteId || '',
        instanceName: state.selectedSiteId || '',

        customContentType: state.selectedContentTypeId,

        ...planifDTO,
      };

      const { data, status } = await exportService.startContentExport(
        exportData
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          severity: 'error',
          message: NotificationsErrors.CONTENT_EXPORT_CREATE_ERROR,
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
      return data;
    }
  ),

  validateStep: thunk((_, stepNumber, { getStoreActions, getState }) => {
    const state = getState();
    let success = true;
    const notifyAction = getStoreActions().notifications
    switch (stepNumber) {
      // Parameters step
      case 1:
        if (state.exportSiteContents && !state.selectedSiteName) {
          notifyAction.notify({
            message: NotificationsErrors.NO_SITE_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        if (
          state.exportSiteContents &&
          state.exportContentType &&
          !state.selectedContentTypeName
        ) {
          notifyAction.notify({
            message: NotificationsErrors.NO_CONTENT_TYPE_SELECTED,
            severity: 'error',
          });
          success = false;
        }
        return success;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (_, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.selectedLang = 'ALL';
      state.exportAllContents = true;
      state.exportSiteContents = false;
      state.exportAllContentTypes = true;
      state.exportContentType = false;
      state.selectedSiteId = undefined;
      state.selectedSiteName = undefined;
      state.selectedContentTypeId = undefined;
      state.selectedContentTypeName = undefined;
    }
  ),
};

export default contentExportStore;
