import { FormattedMessage } from '@lumapps-extensions-playground/translations';
import {
  Alignment,
  Button,
  Dialog,
  Emphasis,
  ExpansionPanel,
  FlexBox,
  Icon,
  List,
  ListItem,
  Orientation,
  Size,
  Toolbar,
} from '@lumx/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SelectableRowsOptions } from '../lumx-react-extended/src';
import './BaseDrivePicker.css';
import BaseDriveTable from './BaseDriveTable';
import { PickedFile, Provider, ProviderName } from './models';
import { pickerStructure } from './pickerStructure';
import { usePicker } from './state/hooks';

export interface BaseDrivePickerProps {
  isOpen: boolean;
  onlyFolders?: boolean;
  onlyCanAddChildren?: boolean;
  withGallery?: boolean;
  onCloseModal?: () => void;
  onPick?: (choosenFile: PickedFile) => void;
  providers: Array<Provider>;
  selectableRows?: SelectableRowsOptions;
  lang?: string;
}

const BaseDrivePicker: React.FC<BaseDrivePickerProps> = ({
  isOpen,
  onCloseModal = () => {},
  onPick,
  onlyFolders = false,
  onlyCanAddChildren = false,
  withGallery = true,
  providers = [],
  selectableRows,
  lang = 'en',
}) => {
  const [highlighted, setHightlighted] = useState<string | undefined>();

  const [choosenFile, setChoosenFile] = useState<any>();

  const tableRef = useRef(null);

  const enabledProviders = providers.map((provider: Provider) => {
    return provider.name;
  });

  const footerBtns = useMemo(
    () => (
      <>
        <Button emphasis={Emphasis.medium} onClick={onCloseModal}>
          <FormattedMessage id="drivepicker.button.cancel" />
        </Button>

        <Button
          className="lumx-spacing-margin-left-regular"
          onClick={() => {
            if (onPick) {
              onPick(choosenFile);
            }
            onCloseModal();
          }}
        >
          <FormattedMessage id="drivepicker.button.pick" />
        </Button>
      </>
    ),
    [onCloseModal, onPick, choosenFile]
  );

  const {
    state: pickerState,
    setPickerMainCategory,
    setPickerSubCategory,
  } = usePicker();

  useEffect(() => {
    const provName = providers[0].name;
    setPickerMainCategory(provName);
    const { children } = pickerStructure[provName];
    if (children) {
      setPickerSubCategory(children[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedFile = (file: PickedFile) => {
    if (
      !choosenFile ||
      choosenFile.item.id !== file.item.id ||
      choosenFile.provider !== file.provider
    ) {
      setChoosenFile(file);
    }
  };

  /*
   *  Get the currently selected Provider from it name
   */
  const selectedProvider: Provider | undefined = useMemo(
    () =>
      providers.find(
        (provider: Provider) =>
          provider.name === pickerState.selectedPickerMainCateg
      ),
    [providers, pickerState.selectedPickerMainCateg]
  );

  return (
    <Dialog
      contentRef={tableRef}
      isOpen={isOpen}
      onClose={onCloseModal}
      size={Size.huge}
      footer={<Toolbar className="driver-picker-footer" after={footerBtns} />}
    >
      <div className="drive-picker-container">
        <div className="drive-picker-menu">
          <List>
            {Object.values(pickerStructure).map((provider: any) => {
              if (!enabledProviders.includes(provider.id)) {
                return null;
              }
              return (
                <div key={provider.id}>
                  {provider.children && provider.children.length ? (
                    <ListItem size={Size.big}>
                      <ExpansionPanel
                        label={provider.label}
                        hasHeaderDivider
                        isOpen={
                          pickerState.selectedPickerMainCateg === provider.id
                        }
                        toggleCallback={() =>
                          pickerState.selectedPickerMainCateg === provider.id
                            ? setPickerMainCategory(undefined)
                            : setPickerMainCategory(provider.id, provider)
                        }
                      >
                        <header>
                          <FlexBox
                            className="lumx-spacing-margin-left-regular"
                            orientation={Orientation.horizontal}
                            hAlign={Alignment.center}
                          >
                            {provider.icon && <Icon icon={provider.icon} />}
                            <span className="lumx-spacing-margin-left-big lumx-typography-body1">
                              {provider.label}
                            </span>
                          </FlexBox>
                        </header>
                        <List
                          style={{
                            marginLeft: '10px',
                          }}
                          isClickable={false}
                        >
                          {provider.children.map((child: any) => (
                            <ListItem
                              key={child.id}
                              className="drive-picker-menu-item"
                              onMouseEnter={() => setHightlighted(child.id)}
                              onMouseLeave={() => setHightlighted(undefined)}
                              isHighlighted={highlighted === child.id}
                              isSelected={
                                pickerState.selectedPickerSubCategory ===
                                child.id
                              }
                              onItemSelected={() =>
                                setPickerSubCategory(child.id, child)
                              }
                            >
                              <FlexBox
                                className="lumx-spacing-margin-left-regular"
                                orientation={Orientation.horizontal}
                                hAlign={Alignment.center}
                              >
                                {child.icon && <Icon icon={child.icon} />}
                                <span className="lumx-spacing-margin-left-big lumx-typography-body1">
                                  {child.label}
                                </span>
                              </FlexBox>
                            </ListItem>
                          ))}
                        </List>
                      </ExpansionPanel>
                    </ListItem>
                  ) : (
                    <ListItem
                      key={provider.id}
                      className="drive-picker-menu-item"
                      onMouseEnter={() => setHightlighted(provider.id)}
                      onMouseLeave={() => setHightlighted(undefined)}
                      isHighlighted={highlighted === provider.id}
                      isSelected={
                        pickerState.selectedPickerMainCateg === provider.id
                      }
                      onItemSelected={() =>
                        setPickerMainCategory(provider.id, provider)
                      }
                    >
                      <FlexBox
                        className="lumx-spacing-margin-left-regular"
                        orientation={Orientation.horizontal}
                        hAlign={Alignment.center}
                      >
                        {provider.icon && <Icon icon={provider.icon} />}
                        <span className="lumx-spacing-margin-left-big lumx-typography-body1">
                          {provider.label}
                        </span>
                      </FlexBox>
                    </ListItem>
                  )}
                </div>
              );
            })}
          </List>
        </div>

        {selectedProvider && selectedProvider.name !== ProviderName.Local && (
          <BaseDriveTable
            key={selectedProvider.name}
            mainCategory={pickerState.selectedPickerMainCateg}
            subCategory={pickerState.selectedPickerSubCategory}
            provider={selectedProvider}
            onPick={setSelectedFile}
            parentWin={tableRef}
            withGallery={withGallery}
            onlyFolders={onlyFolders}
            onlyCanAddChildren={onlyCanAddChildren}
            selectableRows={selectableRows}
            rootFolder="root"
            lang={lang}
          />
        )}
      </div>
    </Dialog>
  );
};

export default BaseDrivePicker;
