/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import { useEffect, useRef, useMemo } from 'react';

const useResizeObserver = ({ onResize }: any = {}) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    if (
      typeof ref !== 'object'
      || ref === null
      || !(ref.current instanceof Element)
    ) {
      return;
    }
    const element = ref.current;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) {
        return;
      }
      // Since we only observe the one element, we don't need to loop over the
      // array
      if (!entries.length) {
        return;
      }
      const entry = entries[0];
      // `Math.round` is in line with how CSS resolves sub-pixel values
      const newWidth = Math.round(entry.contentRect.width);
      const newHeight = Math.round(entry.contentRect.height);
      onResize({ width: newWidth, height: newHeight });
    });

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, [ref, onResize]);

  return useMemo(() => ({ ref }), [ref]);
};

export default useResizeObserver;
