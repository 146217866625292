/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useRef, useState, useEffect } from 'react';

import {
  IconButton, Size, Emphasis, Chip, Icon, Progress,
} from '@lumx/react';
import { mdiUpload, mdiClose } from '@lumx/icons';

import './Dropzone.css';

interface DropzoneProps {
  disabled?: boolean;
  onFileAdded?: (file: any) => void;
  uploadingFile?: boolean;
  pickerMessage: any;
}

const Dropzone: React.FC<DropzoneProps> = ({
  disabled = false,
  onFileAdded,
  uploadingFile = false,
  pickerMessage,
}: DropzoneProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const fileInputRef = useRef(null);
  const [hightlight, setHightlight] = useState(false);

  useEffect(() => {
    setLoading(uploadingFile);
  }, [uploadingFile]);

  const openFileDialog = () => {
    if (disabled) return;
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.click();
    }
  };

  const _onFileAdded = (evt: any) => {
    if (disabled) return;
    const { files } = evt.target;
    setSelectedFile(files[0]);
    if (onFileAdded) {
      onFileAdded(files[0]);
    }
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    if (disabled) return;
    setHightlight(true);
  };

  const onDragLeave = (event: any) => {
    setHightlight(false);
  };

  const onDrop = (event: any) => {
    event.preventDefault();
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (onFileAdded) {
      onFileAdded(files[0]);
    }
    setHightlight(false);
  };

  const dismissSelectedFile = () => {
    setSelectedFile(undefined);
    if (onFileAdded) {
      onFileAdded(undefined);
    }
  };

  return (
    <div
      className={`Dropzone ${hightlight ? 'Highlight' : ''}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: disabled || loading ? 'default' : 'pointer' }}
    >
      {loading && <Progress />}
      {!loading && (
        <>
          {selectedFile && (
            <>
              <Chip
                after={(
                  <Icon
                    icon={mdiClose}
                    size={Size.xs}
                    onClick={dismissSelectedFile}
                  />
                )}
              >
                {selectedFile.name}
              </Chip>
            </>
          )}
          <input
            ref={fileInputRef}
            className="FileInput"
            type="file"
            multiple
            onChange={_onFileAdded}
          />
          <IconButton
            icon={mdiUpload}
            emphasis={Emphasis.low}
            disabled
            size={Size.m}
          />
          <span>{pickerMessage}</span>
        </>
      )}
    </div>
  );
};

export default Dropzone;
