/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import {
  Link,
  RadioGroup,
  RadioButton,
  Button,
  Chip,
  Icon,
  Size,
  Message,
  MessageKind,
} from '@lumx/react';

import Dropzone from 'components/Dropzone';
import { useStoreActions, useStoreState } from 'hooks';
import './index.css';
import { FileProvider } from 'models/provider';
import { mdiClose, mdiFile, mdiOnedrive } from '@lumx/icons';
import MicrosoftChooser from 'components/MicrosoftDrivePicker';
import NotConnectedToMicroOrGoogle from 'containers/NotConnectedToMicroOrGoogle';
import axios from 'axios';
import {
  PickedFile,
  ProviderName,
} from 'components/lab-filepicker/DrivePicker/models';
import { SelectableRowsOptions } from 'components/DataTable';
import DrivePicker from 'components/lab-filepicker/DrivePicker';

const Step1 = () => {
  const { uploadAndPickFile, setPickedFile, setProvider } = useStoreActions(
    (actions) => actions.workflow.import.common
  );
  const { pickedFile, provider } = useStoreState(
    (state) => state.workflow.import.common
  );
  const getProviderToken = useStoreActions(
    (actions) => actions.user.getProviderToken
  );

  const user = useStoreState((state) => state.user);
  const [uploadingLocalFile, setUploadingLocalFile] = useState(false);
  const docLink = `${document.location.origin}/doc/`;

  const handleFileChoosen = async (choosenFile: any) => {
    if (!choosenFile) {
      setPickedFile(undefined);
      return;
    }
    setUploadingLocalFile(true);
    await uploadAndPickFile(choosenFile);
    setUploadingLocalFile(false);
  };

  const unselectedFile = () => {
    setPickedFile({
      ...pickedFile,
      id: undefined,
      driveID: undefined,
      name: '',
      displayName: '',
    });
  };

  useEffect(() => {
    unselectedFile();
  }, [provider]);

  /**
   *  {
   *      "webUrl": null,
   *      "value": [
   *          {
   *          "id": "6A85C6C21E282F93!104",
   *          "parentReference": {
   *              "driveId": "6A85C6C21E282F93"
   *          },
   *          "folder": {},
   *          "endpoint": "https://api.onedrive.com/v1.0"
   *          }
   *      ]
   *  }
   * @param pickedFiles The selected files
   */
  const handleMicrosoftFilePicked = async (pickedFiles: any) => {
    const file = pickedFiles?.value[0];

    let filename = file?.name;
    const driveId = file?.parentReference?.driveId;
    const itemId = file?.id;
    if (!filename) {
      const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}`;
      const headers = { Authorization: microsoftToken };
      const { data } = await axios.get(url, { headers });
      filename = data?.name;
    }

    setPickedFile({
      id: itemId,
      driveID: driveId,
      name: filename,
      displayName: filename,
    });
  };

  const [isDrivePickerOpen, setIsDrivePickerOpen] = useState(false);

  const handleDrivePickerFile = (
    pickedFile: PickedFile,
    provider: FileProvider
  ) => {
    setPickedFile({
      id: pickedFile?.item?.id,
      driveID: (pickedFile?.item?.parents || [undefined])[0],
      name: pickedFile.item.name,
      displayName: pickedFile.item.name,
    });
  };

  const intl = useIntl();

  const isGoogleConnected =
    user.isAuthenticatedWith && user.isAuthenticatedWith.google === true;
  const googleToken = user.tokens?.google?.token;
  const isMicrosoftConnected =
    user.isAuthenticatedWith && user.isAuthenticatedWith.microsoft === true;
  const microsoftToken = user.tokens?.microsoft?.token;

  useEffect(() => {
    if (isGoogleConnected) {
      getProviderToken('google');
    }
    if (isMicrosoftConnected) {
      getProviderToken('microsoft');
    }
  }, []);

  const styles = {
    radioButtonSubContent: {
      marginLeft: '20px',
    },
  };

  return (
    <div className="lumx-spacing-padding-huge">
      <RadioGroup>
        <RadioButton
          checked={provider === FileProvider.csv}
          label={<FormattedMessage id="IMPORT_FROM_CSV" />}
          name={FileProvider.csv}
          value={FileProvider.csv}
          onChange={setProvider}
        />
        {provider === FileProvider.csv && (
          <>
            <div className="toolbox-import-step1-main">
              <Dropzone
                uploadingFile={uploadingLocalFile}
                onFileAdded={handleFileChoosen}
                pickerMessage={<FormattedMessage id="UPLOAD_FILE" />}
              />
            </div>
            <div className="toolbox-import-step1-main">
              <Link href={docLink}>
                <FormattedMessage id="IMPORT_HELP_DOC_LINK" />
              </Link>
            </div>
          </>
        )}

        <RadioButton
          checked={provider === FileProvider.google}
          label={<FormattedMessage id="IMPORT_FROM_GDRIVE" />}
          name={FileProvider.google}
          value={FileProvider.google}
          onChange={setProvider}
          disabled={!isGoogleConnected && !googleToken}
        />
        {!isGoogleConnected && !googleToken && (
          <div style={styles.radioButtonSubContent}>
            <NotConnectedToMicroOrGoogle />
          </div>
        )}
        {provider === FileProvider.google && isGoogleConnected && googleToken && (
          <>
            <div className="toolbox-import-step1-main">
              <DrivePicker
                isOpen={isDrivePickerOpen}
                selectableRows={SelectableRowsOptions.single}
                providers={[
                  {
                    name: ProviderName.Google,
                    token: googleToken,
                    additionalParams:
                      "(mimeType = 'application/vnd.google-apps.folder' or mimeType = 'application/vnd.google-apps.spreadsheet')",
                  },
                ]}
                onPick={(pickedFile: PickedFile) =>
                  handleDrivePickerFile(pickedFile, FileProvider.google)
                }
                onCloseModal={() => setIsDrivePickerOpen(false)}
                lang={user.lang}
                withGallery={false}
              />
              <Button
                leftIcon={mdiOnedrive}
                title={intl.formatMessage({ id: 'CHOOSE_GDRIVE_FILE' })}
                onClick={() => setIsDrivePickerOpen(true)}
              >
                <FormattedMessage id="CHOOSE_GDRIVE_FILE" />
              </Button>
              {pickedFile?.id && (
                <Chip
                  before={<Icon icon={mdiFile} size={Size.xs} />}
                  after={<Icon icon={mdiClose} size={Size.xs} />}
                  onClick={unselectedFile}
                >
                  {pickedFile?.name}
                </Chip>
              )}
            </div>
          </>
        )}

        <RadioButton
          checked={provider === FileProvider.microsoft}
          label={<FormattedMessage id="IMPORT_FROM_ONEDRIVE" />}
          name={FileProvider.microsoft}
          value={FileProvider.microsoft}
          onChange={setProvider}
          disabled={!isMicrosoftConnected && !microsoftToken}
        />
        {!isMicrosoftConnected && !microsoftToken && (
          <div style={styles.radioButtonSubContent}>
            <NotConnectedToMicroOrGoogle microsoft={true} />
          </div>
        )}
        {provider === FileProvider.microsoft &&
          isMicrosoftConnected &&
          microsoftToken && (
            <>
              <div className="toolbox-import-step1-main">
                <MicrosoftChooser
                  onFilesPicked={handleMicrosoftFilePicked}
                  accessToken={microsoftToken}
                  clientId="69175d32-190f-421b-98de-81cca332f1f6"
                  viewType="files"
                  filter=".csv,.xlsx"
                  multiSelect={false}
                >
                  <Button
                    leftIcon={mdiOnedrive}
                    title={<FormattedMessage id="CHOOSE_ONEDRIVE_FILE" />}
                  >
                    <FormattedMessage id="CHOOSE_ONEDRIVE_FILE" />
                  </Button>
                </MicrosoftChooser>
                {pickedFile?.id && (
                  <Chip
                    before={<Icon icon={mdiFile} size={Size.xs} />}
                    after={<Icon icon={mdiClose} size={Size.xs} />}
                    onClick={unselectedFile}
                  >
                    {pickedFile?.name}
                  </Chip>
                )}
              </div>
            </>
          )}
      </RadioGroup>
    </div>
  );
};

export default Step1;
