import { mdiClose, mdiExport, mdiImport } from '@lumx/icons';
import {
  Alignment,
  Emphasis,
  FlexBox,
  Icon,
  IconButton,
  List,
  ListDivider,
  ListItem,
  Size,
} from '@lumx/react';
import { useStoreActions, useStoreState } from 'hooks';
import { DashboardCategory } from 'models/dashboard';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import workflowSteps from './WorkflowStepsHeaders';

const centerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};

const allowedImportCategories = [
  DashboardCategory.user,
  DashboardCategory.group,
  DashboardCategory.directory,
  DashboardCategory.metadata,
  DashboardCategory.content,
];

interface WorkflowTypeChoiceProps {
  onClose: () => void;
}
const WorkflowTypeChoice = ({ onClose }: WorkflowTypeChoiceProps) => {
  const category = useStoreState((state) => state.dashboard.category);
  const { startWorkflow } = useStoreActions(
    (actions) => actions.workflow.modal
  );
  const handleStartWorkflow = (workflowType: 'export' | 'import') => {
    // Set the workflow type and total step
    const totalSteps = workflowSteps[workflowType][category].length || 0;
    startWorkflow({ type: workflowType, totalSteps });
  };

  const [isHighlighted, setIsHighlighted] = useState<string | null>(null);

  return (
    <>
      <FlexBox vAlign={Alignment.right}>
        <IconButton emphasis={Emphasis.low} icon={mdiClose} onClick={onClose} />
      </FlexBox>
      <div style={{ margin: 'auto', width: '85%', padding: '16px' }}>
        <List itemPadding={Size.big}>
          <ListItem
            size={Size.huge}
            isHighlighted={isHighlighted === 'export'}
            onMouseEnter={() => setIsHighlighted('export')}
            onMouseLeave={() => setIsHighlighted(null)}
            onItemSelected={() => handleStartWorkflow('export')}
            before={<Icon icon={mdiExport} size={Size.m} />}
          >
            <div style={centerStyle}>
              <span>
                <FormattedMessage id="EXPORT" />
              </span>
            </div>

            <div style={centerStyle}>
              <span className="lumx-color-font-dark-L2">
                <FormattedMessage id="EXPORT_BUTTON_HELPER" />
              </span>
            </div>
          </ListItem>

          {allowedImportCategories.includes(category) && (
            <>
              <ListDivider />
              <ListItem
                size={Size.huge}
                isHighlighted={isHighlighted === 'import'}
                onMouseEnter={() => setIsHighlighted('import')}
                onMouseLeave={() => setIsHighlighted(null)}
                onItemSelected={() => handleStartWorkflow('import')}
                before={<Icon icon={mdiImport} size={Size.m} />}
              >
                <div style={centerStyle}>
                  <span>
                    <FormattedMessage id="IMPORT" />
                  </span>
                </div>

                <div style={centerStyle}>
                  <span className="lumx-color-font-dark-L2">
                    <FormattedMessage id="IMPORT_BUTTON_HELPER" />
                  </span>
                </div>
              </ListItem>
            </>
          )}
        </List>
      </div>
    </>
  );
};

export default WorkflowTypeChoice;
