// eslint-disable-next-line import/prefer-default-export
export enum DashboardCategory {
  'user' = 'user',
  'group' = 'group',
  'community' = 'community',
  'metadata' = 'metadata',
  'directory' = 'directory',
  'content' = 'content',
  'site' = 'site',
}
