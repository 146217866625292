/* eslint-disable consistent-return */
import axios, { AxiosInstance, AxiosError } from 'axios';
import { ProviderName } from '../models';

import { BoxFile, DriveServiceOptions } from './interface';

class boxDriveService {
  boxBaseUrl: string = 'https://api.box.com/2.0/';

  token: string;

  serverRequestUrl: string | undefined;

  lumappsBaseUrl: string | undefined;

  onlyFolders: boolean;

  currentCursor: number;

  api: AxiosInstance;

  constructor(
    token: string,
    lumappsBaseUrl: string | undefined,
    serverRequestUrl: string | undefined = undefined,
    provider?: ProviderName,
    options?: DriveServiceOptions
  ) {
    this.token = token;
    this.onlyFolders = options?.onlyFolders || false;
    this.serverRequestUrl = serverRequestUrl;
    this.lumappsBaseUrl = lumappsBaseUrl;
    this.currentCursor = 0;
    this.api = this.createApiInstance();
  }

  createApiInstance = () => {
    const api = axios.create({
      baseURL: this.serverRequestUrl,
    });

    api.interceptors.response.use(
      (response: any) => {
        const offset = response?.data['offset'] || 0;
        const total_count = response?.data['total_count'] || 0;
        const count = response?.data['entries']?.length || 0;
        this.currentCursor = offset + count < total_count ? offset + count : 0;

        return response;
      },
      (error: AxiosError) => error.response
    );
    return api;
  };

  axiosGet = async (route: string, requestParams: Object) => {
    const newUrl = new URL(route);

    for (const [key, value] of Object.entries(requestParams)) {
      if (value !== undefined) {
        newUrl.searchParams.append(key, value);
      }
    }

    const params = {
      token: this.token,
      base_url: this.lumappsBaseUrl,
    };

    const ret = await this.api.get(
      `/rewriteGet?url=${encodeURIComponent(newUrl.toString())}`,
      { params }
    );
    return ret;
  };

  axiosPost = async (route: string, requestParams: Object) => {
    const newUrl = new URL(route);

    const params = {
      token: this.token,
      base_url: this.lumappsBaseUrl,
      url: newUrl.toString(),
      data: requestParams,
    };

    return await this.api.post(`/rewritePost`, params);
  };

  hydrateFiles = (files: any) =>
    files
      ? files
          .map((el: any) => this.fileItemTransform(el))
          .filter(Boolean)
          .sort(
            (a: BoxFile, b: BoxFile) =>
              -(Number(a.isFolder) - Number(b.isFolder))
          )
      : null;

  // eslint-disable-next-line class-methods-use-this
  fileItemTransform(fileItem: any): BoxFile | null {
    if (!fileItem) {
      return null;
    }

    const imageLink = '';
    // const imageLink = fileItem.thumbnails && fileItem.thumbnails.length > 0
    //   ? fileItem.thumbnails[0]?.large?.url || ''
    //   : '';

    const isFolder = fileItem.type === 'folder';
    const isWebLink = fileItem.type === 'web_link';
    const id = fileItem.id === 'root' ? '0' : fileItem.id;
    let url;
    if (isFolder) {
      url = `https://app.box.com/folder/${id}`;
    } else if (isWebLink) {
      url = `https://app.box.com/web_link/${id}`;
    } else {
      url = `https://app.box.com/file/${id}`;
    }

    let createdAt = '';
    if (fileItem.content_created_at) {
      createdAt = fileItem.content_created_at;
      createdAt = new Date(Date.parse(createdAt)).toDateString() || '';
    }

    let updatedAt = '';
    if (fileItem.modified_at) {
      updatedAt = fileItem.modified_at;
      updatedAt = new Date(Date.parse(updatedAt)).toDateString() || '';
    }

    let parentFolder = fileItem.parent?.id;
    if (!parentFolder && fileItem.id !== '0') {
      parentFolder = '0';
    }

    return {
      id,
      parentId: parentFolder,
      name: fileItem.name,
      isFolder,
      isWebLink,
      imageLink: imageLink,
      createdAt: createdAt,
      createdBy: fileItem?.created_by?.name,
      updatedAt: updatedAt,
      updatedBy: fileItem?.modified_by?.name,
      size: fileItem.size,
      details: fileItem,
      url,
    };
  }

  getFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false,
    additionalParams: any = undefined,
    searchText: string | null = null
  ) => {
    if (nextPage && !this.currentCursor) {
      return;
    }

    let currentFolder = folderId;
    if (!currentFolder || currentFolder === 'root') {
      currentFolder = '0';
    }

    interface queryParams {
      limit: number;
      offset: number;
      fields: string;
      ancestor_folder_ids: Array<string> | undefined;
    }

    const params: queryParams = {
      limit: 20,
      offset: this.currentCursor,
      fields:
        'content_created_at,created_by,extension,id,modified_at,modified_by,name,parent,shared_link,type,size',
      ancestor_folder_ids: undefined,
    };

    if (searchText) {
      if (currentFolder) {
        params['ancestor_folder_ids'] = [currentFolder];
      }

      const res = await this.axiosGet(
        `${this.boxBaseUrl}/search?query='${searchText}'`,
        params
      );
      if (!res || res.status !== 200) {
        return null;
      }

      const { data } = res;
      if (!data || !data.entries) {
        return null;
      }

      return this.hydrateFiles(data.entries);
    }

    const res = await this.axiosGet(
      `${this.boxBaseUrl}/folders/${currentFolder}/items/`,
      params
    );

    if (!res || res.status !== 200) {
      return null;
    }

    const { data } = res;
    if (!data || !data.entries) {
      return null;
    }

    return this.hydrateFiles(data.entries);
  };

  getMyFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false
  ) => this.getFiles(folderId, nextPage);

  searchFilesByName = async (
    searchText: string,
    folderId: string | null = null
  ) => this.getFiles(folderId, false, null, searchText);

  addFolder = async (folderName: string, folderId: string | null = null) => {
    const rootFolder = !folderId || folderId === 'root' ? '0' : folderId;

    const params: Object = {
      name: folderName,
      parent: { id: rootFolder },
    };

    const res = await this.axiosPost(`${this.boxBaseUrl}/folders`, params);

    if (res && res.status === 201) {
      return {
        success: true,
        code: 'folder_created',
      };
    } else if (
      res &&
      res.status === 409 &&
      res.data.code === 'item_name_in_use'
    ) {
      return {
        success: false,
        code: 'name_already_used',
      };
    } else if (
      res &&
      res.status === 400 &&
      ['item_name_too_long', 'item_name_invalid'].includes(res.data.code)
    ) {
      return {
        success: false,
        code: res.data.code.replace('item_', ''),
      };
    } else {
      return {
        success: false,
        code: 'unexpected_error',
      };
    }
  };

  folderInfo = async (folderId: string | null = null) => {
    if (!folderId) {
      return null;
    }

    const id = folderId === 'root' ? '0' : folderId;
    const params = {
      fields: 'id,name,modified_at',
    };

    const res = await this.axiosGet(`${this.boxBaseUrl}/folders/${id}`, params);

    if (!res || res.status !== 200) {
      return null;
    }

    const { data } = res;
    if (data) {
      let updatedAt = '';
      if (data.modified_at) {
        updatedAt = data.modified_at;
        updatedAt = new Date(Date.parse(updatedAt)).toDateString() || '';
      }
      return {
        ...data,
        url: `https://app.box.com/folder/${id}`,
        updatedAt,
      };
    }

    return null;
  };
}

export default boxDriveService;
