export enum ProviderName {
    Box = "Box",
    Google = "Google",
    Local = "Local",
    Microsoft = "Microsoft",
}

export interface Provider {
    name: ProviderName;
    token?: string;
    lumappsBaseUrl?: string;
    serverRequestUrl?: string;
    additionalParams?: any
}

export interface PickedFile {
    item: any;
    provider: ProviderName;
}