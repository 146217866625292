import React from 'react';

import { FormattedMessage } from 'react-intl';

import { DashboardCategory } from 'models/dashboard';

const baseExportSteps: Array<any> = [
  {
    label: <FormattedMessage id="BASE_EXPORT_DIALOG_STEP_1_TITLE" />,
    // helper: "helper 1"
  },
  {
    label: <FormattedMessage id="BASE_EXPORT_DIALOG_STEP_2_TITLE" />,
  },
  {
    label: <FormattedMessage id="BASE_EXPORT_DIALOG_STEP_3_TITLE" />,
  },
];

const baseImportSteps: any = [
  {
    label: <FormattedMessage id="BASE_IMPORT_DIALOG_STEP_0_TITLE" />,
    // helper: "helper 1"
  },
  {
    label: <FormattedMessage id="BASE_IMPORT_DIALOG_STEP_1_TITLE" />,
    // helper: "helper 2"
  },
  {
    label: <FormattedMessage id="BASE_IMPORT_DIALOG_STEP_2_TITLE" />,
    // helper: "helper 2"
  },
];

const workflowSteps: any = {
  export: {
    [DashboardCategory.user]: baseExportSteps,
    [DashboardCategory.group]: baseExportSteps,
    [DashboardCategory.community]: baseExportSteps,
    [DashboardCategory.content]: baseExportSteps,
    [DashboardCategory.metadata]: baseExportSteps,
    [DashboardCategory.directory]: baseExportSteps,
    [DashboardCategory.site]: baseExportSteps,
  },
  import: {
    [DashboardCategory.user]: baseImportSteps,
    [DashboardCategory.group]: baseImportSteps,
    [DashboardCategory.community]: baseImportSteps,
    [DashboardCategory.content]: baseImportSteps,
    [DashboardCategory.metadata]: baseImportSteps,
    [DashboardCategory.directory]: baseImportSteps,
    [DashboardCategory.site]: baseImportSteps,
  },
};

export default workflowSteps;
