/* eslint-disable global-require */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-undef */
/* eslint-disable block-scoped-var */
import React, { useEffect, useState } from 'react';

import {
  StateProvider as DrivePickerStateProvider,
  allReducers,
} from './state';
import BaseDrivePicker, { BaseDrivePickerProps } from './BaseDrivePicker';
import { INITIAL_STATE } from './state/reducers';

// TODO: replace with reactIntl when available in the marketplace
import { IntlProvider } from '@lumapps-extensions-playground/translations';

import messagesEn from './translations/en.json';
import messagesFr from './translations/fr.json';

var setupEnv = () => {};

if (
  process.env.NODE_ENV === 'development' &&
  process.env.MIRAGE_JS_LAB_FILEPICKER === 'enable'
) {
  // T avoid including it in the production build
  var { makeServer } = require('./mockServer/server');
  var MOCK_SERVER: any = null;
}

const DrivePicker = ({ lang = 'en', ...props }: BaseDrivePickerProps) => {
  useEffect(() => {
    setupEnv();
  }, []);

  const [mockServer, setMockServer] = useState<any>(
    process.env.NODE_ENV === 'development'
  );
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.MIRAGE_JS_LAB_FILEPICKER === 'enable'
  ) {
    // T avoid including it in the production build
    setupEnv = () => {
      if (mockServer && MOCK_SERVER) {
        console.log('Shutdown mock server ....');
        // @ts-ignore
        MOCK_SERVER.shutdown();
        MOCK_SERVER = null;
        setMockServer(false);
      } else {
        console.log('Setup mock server ...');
        MOCK_SERVER = makeServer();
        setMockServer(true);
      }
    };
  }

  const DEFAULT_LANG = 'en';
  const messages: any = {
    en: messagesEn,
    fr: messagesFr,
  };
  const language = Object.keys(messages).includes(lang) ? lang : DEFAULT_LANG;
  return (
    <DrivePickerStateProvider
      initialState={{ drive: INITIAL_STATE }}
      reducer={allReducers}
    >
      {/* {process.env.NODE_ENV === 'development' && (
          <div>
          <Button
          style={{
            position: 'absolute',
            top: 0,
            left: 200,
            zIndex: 3984747237663,
          }}
          onClick={() => setupEnv()}
          >
          {mockServer ? 'Deactivate mock server' : 'activate mock server'}
          </Button>
          </div>
        )} */}
      <IntlProvider messages={messages[language]} locale={language}>
        <BaseDrivePicker {...props} />
      </IntlProvider>
    </DrivePickerStateProvider>
  );
};

export default DrivePicker;
