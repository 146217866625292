import React, { useRef, useState } from 'react';
import {
  Emphasis,
  Icon,
  IconButton,
  Placement,
  Popover,
  Progress,
} from '@lumx/react';
import { mdiHelpCircleOutline } from '@lumx/icons';

interface LineWithIconProps {
  color: string;
  icon: string;
  content: React.ReactNode;
  withHelp?: boolean;
  helpContent?: string | React.ReactNode;
}
const LineWithIcon: React.FC<LineWithIconProps> = ({
  color,
  icon = 'progress',
  content,
  withHelp = false,
  helpContent = '',
}: LineWithIconProps) => {
  const errorPopoverRef = useRef(null);

  const [isErrorPopoverOpen, setIsErrorPopoverOpen] = useState<boolean>(false);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color,
      }}
    >
      {content}
      {' '}
      {icon !== 'progress' ? <Icon icon={icon} color={color} /> : <Progress />}
      {withHelp && (
        <IconButton
          aria-label="Status additional informations"
          color="red"
          buttonRef={errorPopoverRef}
          emphasis={Emphasis.low}
          icon={mdiHelpCircleOutline}
          onClick={() => {
            setIsErrorPopoverOpen(!isErrorPopoverOpen);
          }}
        />
      )}
      {isErrorPopoverOpen && (
        <Popover
          isOpen={isErrorPopoverOpen}
          className="lumx-spacing-padding-huge"
          anchorRef={errorPopoverRef}
          placement={Placement.BOTTOM}
        >
          {helpContent}
        </Popover>
      )}
    </div>
  );
};
export default LineWithIcon;
