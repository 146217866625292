import React, { useState, useEffect } from 'react'
import './temp.css'
import { mdiMagnify } from '@lumx/icons'

import { TextField, Theme } from '@lumx/react'
import { useDebounce } from '@lumapps-extensions-playground/common'
// TODO: replace with reactIntl when available in the marketplace
import { useIntl } from '@lumapps-extensions-playground/translations'

interface SearchBarProps {
    theme?: Theme
    onSearch: (searchText: string) => void
}
const SearchBar: React.FC<SearchBarProps> = ({
    theme = Theme.light,
    onSearch,
}) => {
    const [searchTerm, setValue] = useState<any>('')
    const debouncedSearchText = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchText) {
            if (onSearch) {
                onSearch(debouncedSearchText);
            }
        }
    }, [debouncedSearchText, onSearch]);

    const intl = useIntl();

    return (
        <TextField
            title="Search"
            placeholder={intl.formatMessage({id: 'datatable.searchbar'})}
            icon={mdiMagnify}
            value={searchTerm}
            onChange={setValue}
            theme={theme}
            className="this_class_removes_padding_from_input_will_need_to_be_removed_when_ds_evolves"
        />
    )
}

export default SearchBar
