import React from 'react';

import { FormattedMessage } from 'react-intl';

import { List, ListItem, ListSubheader } from '@lumx/react';
import { useStoreState } from 'hooks';

const UserImportSummary = () => {
  const { directoryName, directorySiteName } = useStoreState(
    (state) => state.workflow.import.user
  );

  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="PARAMETERS" />
        </ListSubheader>
        <ListItem>
          <div>
            <span>
              <FormattedMessage id="SELECTED_DIRECTORY_SITE" />
            </span>
          </div>
          <div>
            <span className="lumx-color-font-dark-L2">{directorySiteName}</span>
          </div>
        </ListItem>
        <ListItem>
          <div>
            <span>
              <FormattedMessage id="SELECTED_DIRECTORY" />
            </span>
          </div>
          <div>
            <span className="lumx-color-font-dark-L2">{directoryName}</span>
          </div>
        </ListItem>
      </List>
    </div>
  );
};

export default UserImportSummary;
