import { mdiViewWeek } from '@lumx/icons';
import {
  Checkbox,
  Emphasis,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Popover,
  Theme,
} from '@lumx/react';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DataTableHeader } from './models';

interface ViewColumnsProps {
  theme?: Theme;
  displayedHeaders: Array<DataTableHeader>;
  setDisplayedHeaders: (headers: Array<DataTableHeader>) => void;
  tableHeaders: Array<DataTableHeader>;
  viewColumnsButtonTooltipLabel?: string;
}

const ViewColumns = ({
  theme = Theme.light,
  setDisplayedHeaders,
  tableHeaders,
  displayedHeaders,
  viewColumnsButtonTooltipLabel = 'Columns display options',
}: ViewColumnsProps) => {
  /*
   * ViewColumns filter
   */

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const anchorRef = useRef(null);

  /*
   * Add or remove a headers when checking or unchecking it in the
   * filter popover
   */
  const changeDisplayedHeaders = (
    checked: boolean,
    header: DataTableHeader
  ) => {
    if (checked) {
      setDisplayedHeaders([
        ...displayedHeaders.filter(
          (h: DataTableHeader) => h.name !== header.name
        ),
      ]);
    } else {
      const tableHeadersNames = tableHeaders.map(
        (t: DataTableHeader) => t.name
      );
      const selectedHeaderIndex = tableHeadersNames.indexOf(header.name);
      const headerToAdd = tableHeaders[selectedHeaderIndex];
      const headersToSet = [...displayedHeaders];
      headersToSet.push(headerToAdd);

      setDisplayedHeaders(
        headersToSet.sort(
          (a, b) =>
            tableHeadersNames.indexOf(a.name) -
            tableHeadersNames.indexOf(b.name)
        )
      );
    }
  };
  return (
    <div>
      <div ref={anchorRef}>
        <IconButton
          title={viewColumnsButtonTooltipLabel}
          theme={theme}
          emphasis={Emphasis.low}
          icon={mdiViewWeek}
          onClick={() => setIsTooltipDisplayed(!isTooltipDisplayed)}
        />
      </div>
      <Popover
        className="lumx-spacing-padding-horizontal-huge"
        anchorRef={anchorRef}
        isOpen={isTooltipDisplayed}
      >
        <List onMouseLeave={() => setIsTooltipDisplayed(false)} theme={theme}>
          <ListSubheader>
            <FormattedMessage id="VIEW_COLUMNS" />
          </ListSubheader>
          {tableHeaders.map((header: DataTableHeader, index: number) => {
            const checked =
              displayedHeaders.findIndex(
                (h: DataTableHeader) => h.name === header.name
              ) !== -1;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem theme={theme} key={index}>
                <Checkbox
                  theme={theme}
                  value={checked}
                  onChange={() => changeDisplayedHeaders(checked, header)}
                  label={header.label}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </div>
  );
};

export default ViewColumns;
