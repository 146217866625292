/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  List, ListItem, ListSubheader, TextField,
} from '@lumx/react';
import { Divider } from 'components/LumxWrappers';
import DateTimePicker from 'components/LumxWrappers/DateTimePicker';
import { useStoreActions, useStoreState } from 'hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const validateIsNumber = (
  val: string,
  notGreaterThan: number | undefined = undefined,
): boolean => {
  const isNumber = /^[0-9]+$/.test(val);
  return (
    isNumber
    && (notGreaterThan
      ? parseInt(val) < notGreaterThan
      : isNumber)
  );
};

const PlanificationParams = () => {
  const { interval, maxExecutionsNb } = useStoreState(
    (state) => state.workflow.export.planification.planificationParams,
  );

  const { isIntervalValid, isMaxExecutionsNbValid } = useStoreState(
    (state) => state.workflow.export.planification,
  );

  const {
    setInterval,
    setMaxExecutionsNb,
    setIsIntervalValid,
    setIsMaxExecutionsNbValid,
    setNextExecutionDate,
  } = useStoreActions((actions) => actions.workflow.export.planification);

  return (
    <>
      <List>
        <ListSubheader>
          <FormattedMessage id="EXPORT_PLANIF_PARAMS" />
        </ListSubheader>
        <ListItem>
          <TextField
            label={
              (<FormattedMessage id="EXPORT_PLANIF_PARAMS_INTERVAL" />) as any
            }
            onChange={(val: string) => {
              validateIsNumber(val, 30)
                ? setIsIntervalValid(true)
                : setIsIntervalValid(false);

              // eslint-disable-next-line @typescript-eslint/no-implied-eval
              setInterval(val);
            }}
            value={interval}
            hasError={!isIntervalValid}
            // helper={
            //   <FormattedMessage id="EXPORT_PLANIF_PARAMS_INTERVAL_HELPER" />
            // }
          />
        </ListItem>
        <ListItem>
          <TextField
            label={
              (
                <FormattedMessage id="EXPORT_PLANIF_PARAMS_LIMIT_EXECUTIONS_NB" />
              ) as any
            }
            onChange={(val: string) => {
              validateIsNumber(val, 0)
                ? setIsMaxExecutionsNbValid(true)
                : setIsMaxExecutionsNbValid(false);
              setMaxExecutionsNb(val);
            }}
            value={maxExecutionsNb}
            hasError={!isMaxExecutionsNbValid}
            // helper={
            //   <FormattedMessage id="EXPORT_PLANIF_PARAMS_LIMIT_EXECUTIONS_NB_HELPER" />
            // }
          />
        </ListItem>
        <ListItem>
          <div className="lumx-input-label--theme-light lumx-input-label">
            <FormattedMessage id="EXPORT_PLANIF_PARAMS_FIRST_EXEC_DATE" />
          </div>
          <DateTimePicker
            onDateTimePicked={(pickedDateTime) => {
              setNextExecutionDate(pickedDateTime);
            }}
          />
        </ListItem>
      </List>
      <Divider />
    </>
  );
};

export default PlanificationParams;
