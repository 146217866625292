import userExportStore, { UserExportStore } from './userStore';
import planificationStore, { PlanificationStore } from './planificationStore';
import commonExportStore, { CommonExportStore } from './commonStore';
import directoryExportStore, { DirectoryExportStore } from './directoryStore';
import groupExportStore, { GroupExportStore } from './groupStore';
import metadataExportStore, { MetadataExportStore } from './metadataStore';
import contentExportStore, { ContentExportStore } from './contentStore';
import communityExportStore, { CommunityExportStore } from './communityStore';
import siteExportStore, { SiteExportStore } from './siteExportStore';

export interface ExportStore {
  common: CommonExportStore;
  planification: PlanificationStore;
  user: UserExportStore;
  directory: DirectoryExportStore;
  group: GroupExportStore;
  metadata: MetadataExportStore;
  content: ContentExportStore;
  community: CommunityExportStore;
  site: SiteExportStore;
}

const exportStore: ExportStore = {
  common: commonExportStore,
  planification: planificationStore,
  user: userExportStore,
  directory: directoryExportStore,
  group: groupExportStore,
  metadata: metadataExportStore,
  content: contentExportStore,
  community: communityExportStore,
  site: siteExportStore,
};

export default exportStore;
