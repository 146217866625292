import { useContext } from 'react';

import ThemeContext from './ThemeContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ITheme } from './model';

interface IUseTheme {
  theme: ITheme;
  dispatch: (action: string) => void;
}
const useTheme = (): IUseTheme => useContext(ThemeContext) as IUseTheme;

export default useTheme;
