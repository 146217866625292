import React from 'react';

import { FormattedMessage } from 'react-intl';

import { List, ListItem, ListSubheader } from '@lumx/react';

import { useStoreState } from 'hooks';

import SubChoiceFormmatedName from 'components/SubChoiceFormmatedName';

const ContentSummary = () => {
  const {
    exportAllContents,
    exportSiteContents,
    exportAllContentTypes,
    exportContentType,
    selectedSiteName,
    selectedContentTypeName,
    selectedLang,
  } = useStoreState((state) => state.workflow.export.content);

  return (
    <List>
      <ListSubheader>
        <FormattedMessage id="EXPORT_CHOICE" />
      </ListSubheader>
      <ListItem>
        {exportAllContents && (
          <FormattedMessage id="CONTENT_EXPORT_ALL_CONTENTS" />
        )}
        {exportSiteContents && (
          <>
            <FormattedMessage id="CONTENT_EXPORT_SITE_CONTENTS" />
            {exportAllContentTypes && (
              <SubChoiceFormmatedName id="CONTENT_EXPORT_ALL_CONTENT_TYPES" />
            )}
            {exportContentType && (
              <SubChoiceFormmatedName id="CONTENT_EXPORT_CONTENT_TYPE" />
            )}
          </>
        )}
      </ListItem>
      {selectedLang && (
        <>
          <ListSubheader>
            <FormattedMessage id="EXPORT_SELECTED_LANG" />
          </ListSubheader>
          <ListItem>
            <span>{selectedLang}</span>
          </ListItem>
        </>
      )}

      {selectedSiteName && (
        <>
          <ListSubheader>
            <FormattedMessage id="EXPORT_SELECTED_SITE" />
          </ListSubheader>
          <ListItem>{selectedSiteName}</ListItem>
        </>
      )}
      {exportContentType && selectedContentTypeName && (
        <>
          <ListSubheader>
            <FormattedMessage id="EXPORT_SELECTED_CONTENT_TYPE" />
          </ListSubheader>
          <ListItem>{selectedContentTypeName}</ListItem>
        </>
      )}
    </List>
  );
};

export default ContentSummary;
