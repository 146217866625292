import React from 'react';

import { FormattedMessage } from 'react-intl';

import { List, ListItem, ListSubheader } from '@lumx/react';

import { useStoreState } from 'hooks';

import { MetadataExportType } from 'models/dtos';

const MetadataExportSummary = () => {
  const { exportType, selectedSiteName } = useStoreState(
    (state) => state.workflow.export.metadata,
  );
  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="EXPORT_CHOICE" />
        </ListSubheader>
        <ListItem>
          {exportType === MetadataExportType.exportAllMetadata && (
            <FormattedMessage id="METADATA_EXPORT_PARAMS_EXPORT_ALL_METADATA" />
          )}
          {exportType === MetadataExportType.exportPlatformMetadata && (
            <FormattedMessage id="METADATA_EXPORT_PARAMS_EXPORT_PLATFORM_METADATA" />
          )}
          {exportType === MetadataExportType.exportSiteMetadata && (
            <FormattedMessage id="METADATA_EXPORT_PARAMS_EXPORT_SITE_METADATA" />
          )}
        </ListItem>
        {exportType === MetadataExportType.exportSiteMetadata && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_SITE" />
            </ListSubheader>
            <ListItem>{selectedSiteName}</ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export default MetadataExportSummary;
