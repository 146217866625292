import { mdiAlert } from '@lumx/icons';
import {
  Checkbox,
  Icon,
  List,
  ListItem,
  ListSubheader,
  RadioButton,
  RadioGroup,
  Size,
} from '@lumx/react';
import { SubRadioButtonContentWrapper } from 'components/LumxWrappers';
import SimpleSelect from 'components/LumxWrappers/SimpleSelect';
import { useStoreActions, useStoreState } from 'hooks';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import './userParams.css';

const UserParamsPlatformGroupChoice = () => {
  // Platform groups
  const { platformGroupId, platformGroupName } = useStoreState(
    (state) => state.workflow.export.user.exportData
  );

  const { loadingPlatformGroups, platformGroups } = useStoreState(
    (state) => state.workflow.export.user
  );

  const { fetchPlatformGroups, selectPlatformGroup } = useStoreActions(
    (actions) => actions.workflow.export.user
  );

  // Loading async when the component mount, no need to wait
  useEffect(() => {
    // allow keeping data if we go to next and previous
    if (!platformGroups && !platformGroupId) {
      fetchPlatformGroups();
    }
  }, [platformGroups, platformGroupId, fetchPlatformGroups]);

  return (
    <>
      <SimpleSelect
        choices={platformGroups}
        value={platformGroupName}
        setValue={selectPlatformGroup}
        label={
          <FormattedMessage id="USER_EXPORT_PARAMS_CHOOSE_PLATFORM_GROUP" />
        }
        isLoading={loadingPlatformGroups}
      />
    </>
  );
};

const UserParamsSiteGroupChoice = () => {
  const userSites = useStoreState((state) =>
    state.user.userLumappsSites?.map((el: any) => ({
      value: el.id,
      displayName: el.name,
      name: el.name,
    }))
  );
  const { instanceName: selectedSiteName, siteGroupName } = useStoreState(
    (state) => state.workflow.export.user.exportData
  );

  const { siteGroups, loadingSiteGroups } = useStoreState(
    (state) => state.workflow.export.user
  );

  const { selectSite, selectSiteGroup } = useStoreActions(
    (state) => state.workflow.export.user
  );

  return (
    <>
      <SimpleSelect
        choices={userSites}
        value={selectedSiteName}
        setValue={(choice: any) => {
          selectSite(choice);
        }}
        label={<FormattedMessage id="USER_EXPORT_PARAMS_CHOOSE_SITE" />}
      />
      {selectedSiteName && (
        <>
          <SimpleSelect
            choices={siteGroups}
            value={siteGroupName}
            setValue={selectSiteGroup}
            label={
              <FormattedMessage id="USER_EXPORT_PARAMS_CHOOSE_SITE_GROUP" />
            }
            isLoading={loadingSiteGroups}
          />
        </>
      )}
    </>
  );
};

const UserParamsChoices = () => {
  const exportChoice = useStoreState(
    (state) => state.workflow.export.user.exportChoice
  );
  const setExportChoice = useStoreActions(
    (actions) => actions.workflow.export.user.setExportChoice
  );

  return (
    <RadioGroup>
      <RadioButton
        checked={exportChoice === 'platform'}
        value="platform"
        name="platform"
        onChange={setExportChoice}
        label={<FormattedMessage id="USER_EXPORT_PARAMS_EXPORT_ALL_PLATFORM" />}
      />
      <RadioButton
        checked={exportChoice === 'platformGroup'}
        value="platformGroup"
        name="platformGroup"
        onChange={setExportChoice}
        label={
          <FormattedMessage id="USER_EXPORT_PARAMS_EXPORT_PLATFORM_GROUP" />
        }
      />
      {exportChoice === 'platformGroup' && (
        <SubRadioButtonContentWrapper>
          <UserParamsPlatformGroupChoice />
        </SubRadioButtonContentWrapper>
      )}
      <RadioButton
        checked={exportChoice === 'siteGroup'}
        value="siteGroup"
        name="siteGroup"
        onChange={setExportChoice}
        label={<FormattedMessage id="USER_EXPORT_PARAMS_EXPORT_SITE_GROUP" />}
      />
      {exportChoice === 'siteGroup' && (
        <SubRadioButtonContentWrapper>
          <UserParamsSiteGroupChoice />
        </SubRadioButtonContentWrapper>
      )}
    </RadioGroup>
  );
};

const UserParamsOptions = () => {
  const exportOnlyActiveUsers = useStoreState(
    (state) => state.workflow.export.user.exportData.onlyActiveUsers
  );
  const setExportOnlyActiveUsers = useStoreActions(
    (actions) => actions.workflow.export.user.setExportOnlyActiveUsers
  );

  // Set default
  useEffect(() => {
    setExportOnlyActiveUsers(true);
  }, []);

  return (
    <div>
      <Checkbox
        label={
          <FormattedMessage id="USER_EXPORT_PARAMS_ONLY_EXPORT_ACTIVE_USERS" />
        }
        value={exportOnlyActiveUsers}
        onChange={() => setExportOnlyActiveUsers(!exportOnlyActiveUsers)}
      />
    </div>
  );
};

const UserParamsAddionnalExportInfos = () => {
  const { exportUserDirectoryFields, exportUserSettings } = useStoreState(
    (state) => state.workflow.export.user.exportData
  );
  const {
    setExportUserDirectoryFields,
    setExportUserSettings,
  } = useStoreActions((actions) => actions.workflow.export.user);

  const { isSuperAdminOnLumappsPlatform } = useStoreState(
    (state) => state.user
  );

  return (
    <div>
      <FormattedMessage id="USER_PARAMS_MORE_INFOS_SUBTITLE" />
      <List>
        <ListItem>
          <Checkbox
            value={exportUserDirectoryFields}
            onChange={() =>
              setExportUserDirectoryFields(!exportUserDirectoryFields)
            }
            label={
              <FormattedMessage id="USER_PARAMS_EXPORT_USER_PROFILE_FIELDS" />
            }
            helper={
              (
                <FormattedMessage id="USER_PARAMS_EXPORT_USER_PROFILE_FIELDS_HELPER" />
              ) as any
            }
          />
        </ListItem>
        {/* Only user that are superadmins on their platform
        can have access to it because it will only work for them as
        the user/list endpoint of lumapps returns the infos only
        if the requestor is superadmin
         */}
        {isSuperAdminOnLumappsPlatform && (
          <ListItem>
            <Checkbox
              value={exportUserSettings}
              onChange={() => setExportUserSettings(!exportUserSettings)}
              label={<FormattedMessage id="USER_PARAMS_EXPORT_USER_SETTINGS" />}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

const UserParams: React.FC = () => (
  <>
    <List>
      {/* User to export choice */}
      <ListSubheader>
        <FormattedMessage id="USER_EXPORT_PARAMS_CHOICE_USER_TO_EXPORT" />
      </ListSubheader>
      <ListItem className="lumx-spacing-margin-left-regular">
        <UserParamsChoices />
      </ListItem>

      {/* Generic options */}
      <ListSubheader>
        <FormattedMessage id="USER_EXPORT_PARAMS_OPTIONS" />
      </ListSubheader>
      <ListItem className="lumx-spacing-margin-left-regular">
        <UserParamsOptions />
      </ListItem>

      {/* Additionnal infos to export */}
      <ListSubheader>
        <FormattedMessage id="USER_EXPORT_PARAMS_MORE_INFOS" />
      </ListSubheader>
      <ListItem className="lumx-spacing-margin-left-regular">
        <UserParamsAddionnalExportInfos />
      </ListItem>
    </List>
  </>
);

export default UserParams;
