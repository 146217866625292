import { useStoreState } from 'hooks';
import { DashboardCategory } from 'models/dashboard';
import React from 'react';
import {
  CommunityParams,
  ContentParams,
  DirectoryParams,
  GroupParams,
  MetadataParams,
  PlanificationParams,
  SiteParams,
  UserParams,
} from '../Parameters';

const Parameters: React.FC = () => {
  const category = useStoreState((state) => state.workflow.modal.category);
  const isPlanified = useStoreState(
    (state) => state.workflow.export.planification.isPlanified
  );

  return (
    <div className="lumx-spacing-padding-huge">
      {isPlanified && (
        <>
          <PlanificationParams />
        </>
      )}
      {category === DashboardCategory.user && <UserParams />}
      {category === DashboardCategory.directory && <DirectoryParams />}
      {category === DashboardCategory.group && <GroupParams />}
      {category === DashboardCategory.metadata && <MetadataParams />}
      {category === DashboardCategory.content && <ContentParams />}
      {category === DashboardCategory.community && <CommunityParams />}
      {category === DashboardCategory.site && <SiteParams />}
    </div>
  );
};

export default Parameters;
