import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Divider, List, ListItem, RadioButton, RadioGroup } from '@lumx/react';

import { useStoreActions, useStoreState } from 'hooks';

import { SimpleSelect } from 'components/LumxWrappers';
import LumappsLangs from 'containers/LumappsLangs';

const CommunityParams = () => {
  const {
    selectedSiteName,
    communityLang,
    exportSiteCommunityPosts,
    exportAllSiteCommunities,
    communityName,
    selectedSiteCommunities,
    loadingSiteCommunities,
  } = useStoreState((state) => state.workflow.export.community);

  const {
    selectSite,
    selectCommunityLang,
    setCommunityExportChoice,
    selectCommunity,
    searchSiteCommunities
  } = useStoreActions((actions) => actions.workflow.export.community);
  const userSites = useStoreState((state) => {
    const sites = state.user.userLumappsSites?.map((el: any) => ({
      value: el.id,
      displayName: el.name,
      name: el.name,
    }));
    return sites;
  });

  const langs: Array<any> = [...LumappsLangs];
  return (
    <List>
      <ListItem>
        <SimpleSelect
          choices={langs}
          value={communityLang}
          setValue={selectCommunityLang}
          label={<FormattedMessage id="SELECT_EXPORT_LANG" />}
          helper={<FormattedMessage id="SELECT_EXPORT_LANG_HELPER" />}
        />
        <Divider />
      </ListItem>
      <ListItem>
        <SimpleSelect
          choices={userSites}
          label={<FormattedMessage id="SELECT_SITE" />}
          value={selectedSiteName}
          setValue={selectSite}
        />
      </ListItem>
      <ListItem>
        <RadioGroup>
          <RadioButton
            checked={exportAllSiteCommunities}
            value="exportAllSiteCommunities"
            name="exportAllSiteCommunities"
            onChange={setCommunityExportChoice}
            label={
              <FormattedMessage id="COMMUNITY_EXPORT_ALL_SITE_COMMUNITIES" />
            }
          />
          <RadioButton
            checked={exportSiteCommunityPosts}
            value="exportSiteCommunityPosts"
            name="exportSiteCommunityPosts"
            onChange={setCommunityExportChoice}
            label={
              <FormattedMessage id="COMMUNITY_EXPORT_ALL_COMMUNITY_POSTS" />
            }
          />
          {exportSiteCommunityPosts && (
            <SimpleSelect
              isLoading={loadingSiteCommunities}
              choices={selectedSiteCommunities}
              label={<FormattedMessage id="SELECT_COMMUNITY" />}
              value={communityName}
              setValue={selectCommunity}
              searchFunc={(value: any) => {
                searchSiteCommunities(value)
              } }
            />
          )}
        </RadioGroup>
      </ListItem>
    </List>
  );
};

export default CommunityParams;
