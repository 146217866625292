export interface ILumSite {
  id: number | undefined;
  name: string | undefined;
}

export const makeFakeLumSite = (id: number): ILumSite => {
  return {
    id,
    name: `fake n${id}`,
  };
};

export interface ILumDirectory {
  id: number | undefined;
  name: string | undefined;
}

export const makeFakeLumDirectory = (id: number): ILumDirectory => {
  return {
    id,
    name: `fake n${id}`,
  };
};

export interface ILumGroup {
  id: number | undefined;
  name: string | undefined;
}

export const makeFakeLumGroup = (id: number): ILumGroup => {
  return {
    id,
    name: `fake n${id}`,
  };
};

export interface ILumCCT {
  id: number | undefined;
  name: string | undefined;
}
