/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import {
  action,
  Action,
  actionOn,
  ActionOn,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from 'easy-peasy';
import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import {
  CommunityExportDTO,
  ExportLang,
  IExportFileInfos,
  PlanifDTO,
} from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

interface ISelectChoice {
  value: any;
  name: string;
  displayName: string;
}

export interface CommunityExportStore extends BaseExportStore {
  exportAllSiteCommunities: boolean;
  exportSiteCommunityPosts: boolean;
  setCommunityExportChoice: Action<CommunityExportStore, string>;

  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  selectSite: Action<CommunityExportStore, ISelectChoice>;
  onSelectSite: ThunkOn<CommunityExportStore, IInjections, IStore>;
  searchSiteCommunities: Thunk<CommunityExportStore, any, IInjections, IStore>

  loadingSiteCommunities: boolean
  setLoadingSiteCommunities: Action<CommunityExportStore, boolean>
  selectedSiteCommunities: Array<ISelectChoice> | undefined;
  setSelectedSiteCommunities: Action<
    CommunityExportStore,
    undefined | Array<ISelectChoice>
  >;

  communityLang: ExportLang | undefined;
  selectCommunityLang: Action<CommunityExportStore, ISelectChoice>;

  communityId: string | undefined;
  communityName: string | undefined;
  selectCommunity: Action<CommunityExportStore, ISelectChoice>;

  startExport: Thunk<CommunityExportStore, void, IInjections, IStore>;
  validateStep: Thunk<CommunityExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<CommunityExportStore, IStore>;
}

const communityExportStore: CommunityExportStore = {
  exportAllSiteCommunities: true,
  exportSiteCommunityPosts: false,
  setCommunityExportChoice: action((state, choice) => {
    state.exportAllSiteCommunities = choice === 'exportAllSiteCommunities';
    state.exportSiteCommunityPosts = choice === 'exportSiteCommunityPosts';
  }),

  selectedSiteId: undefined,
  selectedSiteName: undefined,
  selectSite: action((state, site) => {
    state.selectedSiteId = site?.value;
    state.selectedSiteName = site?.displayName;
  }),
  onSelectSite: thunkOn(
    (actions) => actions.selectSite,
    async (actions, target, { injections, getState, getStoreState }) => {
      const { lumappsService } = injections;
      const state = getState();
      const storeState = getStoreState();

      const siteId = state.selectedSiteId;
      if (!siteId) {
        return;
      }
      actions.setLoadingSiteCommunities(true)
      const {
        data: siteCommunities,
        status,
      } = await lumappsService.getSiteCommunities(
        siteId,
        storeState.user.currentLumappsPlatformID,
        storeState.user.currentLumappsPlatformBaseUrl
      );
      if (![200,204].includes(status)) {
        return;
      }
      actions.setSelectedSiteCommunities(
        siteCommunities.map((commu: any) => ({
          value: commu.id,
          displayName: commu.name,
          name: commu.name,
        }))
      );
      actions.setLoadingSiteCommunities(false)
    }
  ),
  searchSiteCommunities: thunk(async (actions, search, {injections, getState, getStoreState}) => {
    const { lumappsService } = injections;
    const state = getState();
    const storeState = getStoreState();

    const siteId = state.selectedSiteId;
    if (!siteId) {
      return;
    }
    actions.setLoadingSiteCommunities(true)
    const {
      data: siteCommunities,
      status,
    } = await lumappsService.getSiteCommunities(
      siteId,
      storeState.user.currentLumappsPlatformID,
      storeState.user.currentLumappsPlatformBaseUrl,
      search
    );
    if (![200,204].includes(status)) {
      return;
    }
    actions.setSelectedSiteCommunities(
      siteCommunities.map((commu: any) => ({
        value: commu.id,
        displayName: commu.name,
        name: commu.name,
      }))
    );
    actions.setLoadingSiteCommunities(false)
  }),

  loadingSiteCommunities: false,
  setLoadingSiteCommunities: action((state, loading) => {
    state.loadingSiteCommunities = loading
  }),
  selectedSiteCommunities: undefined,
  setSelectedSiteCommunities: action((state, communities) => {
    state.selectedSiteCommunities = communities;
  }),

  communityLang: ExportLang.all,
  selectCommunityLang: action((state, communityLang) => {
    state.communityLang = communityLang?.name as ExportLang;
  }),

  communityId: undefined,
  communityName: undefined,
  selectCommunity: action((state, choice) => {
    state.communityId = choice?.value;
    state.communityName = choice?.name;
  }),

  startExport: thunk(
    async (
      actions,
      _,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();

      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: CommunityExportDTO = {
        exportToInfos,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        lang: state.communityLang,

        instanceId: state.selectedSiteId || '',
        instanceName: state.selectedSiteName || '',

        communityPosts: !!state.communityId,
        communityIds: state.communityId ? [state.communityId] : undefined,

        ...planifDTO,
      };

      const { data, status } = await exportService.startCommunityExport(
        exportData
      );
      const notifyAction = getStoreActions().notifications
      if (status !== 200) {
        notifyAction.notify({
          severity: 'error',
          message: NotificationsErrors.COMMUNITY_EXPORT_CREATE_ERROR,
        });
        return;
      }
      notifyAction.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
      return data;
    }
  ),

  validateStep: thunk((_, stepNumber, { getStoreActions, getState }) => {
    let success = true;
    const state = getState();
    const notifyAction = getStoreActions().notifications;
    switch (stepNumber) {
      // Parameters step
      case 1:
        if (!state.selectedSiteId) {
          notifyAction.notify({
            message: NotificationsErrors.NO_SITE_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        if (state.exportSiteCommunityPosts && !state.communityId) {
          notifyAction.notify({
            message: NotificationsErrors.NO_COMMUNITY_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        return success;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (_, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.exportAllSiteCommunities = true;
      state.exportSiteCommunityPosts = false;
      state.selectedSiteId = undefined;
      state.selectedSiteName = undefined;
      state.selectedSiteCommunities = undefined;
      state.communityId = undefined;
      state.communityLang = ExportLang.all;
      state.communityName = undefined;
    }
  ),
};

export default communityExportStore;
