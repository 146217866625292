import React from 'react';

import { FormattedMessage } from 'react-intl';

import { mdiCheck, mdiClose } from '@lumx/icons';
import {
  Icon, List, ListItem, ListSubheader,
} from '@lumx/react';

import { useStoreState } from 'hooks';

interface CheckOrCrossProps {
  cond: boolean;
}
const CheckOrCross = ({ cond }: CheckOrCrossProps) => (cond ? (
  <Icon color="green" icon={mdiCheck} />
) : (
  <Icon color="red" icon={mdiClose} />
));

const UserExportSummary = () => {
  const { exportChoice, exportData } = useStoreState(
    (state) => state.workflow.export.user,
  );
  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="USER_EXPORT_PARAMS_CHOICE_USER_TO_EXPORT" />
        </ListSubheader>
        <ListItem className="lumx-spacing-margin-left-regular">
          {exportChoice === 'platform' && (
            <FormattedMessage id="USER_EXPORT_PARAMS_EXPORT_ALL_PLATFORM" />
          )}
          {exportChoice === 'platformGroup' && (
            <>
              <FormattedMessage id="USER_EXPORT_PARAMS_EXPORT_PLATFORM_GROUP" />
              {' > '}
              {exportData.platformGroupName}
            </>
          )}
          {exportChoice === 'siteGroup' && (
            <>
              <FormattedMessage id="USER_EXPORT_PARAMS_EXPORT_SITE_GROUP" />
              {' > '}
              {exportData.instanceName}
              {' > '}
              {exportData.siteGroupName}
            </>
          )}
        </ListItem>

        <ListSubheader>
          <FormattedMessage id="USER_EXPORT_PARAMS_OPTIONS" />
        </ListSubheader>
        <ListItem
          before={<CheckOrCross cond={exportData.onlyActiveUsers as boolean} />}
          className="lumx-spacing-margin-left-regular"
        >
          <FormattedMessage id="USER_EXPORT_PARAMS_ONLY_EXPORT_ACTIVE_USERS" />
        </ListItem>

        {/* Additionnal infos to export */}
        <ListSubheader>
          <FormattedMessage id="USER_EXPORT_PARAMS_MORE_INFOS" />
        </ListSubheader>
        <ListItem className="lumx-spacing-margin-left-regular">
          <List>
            <ListItem
              before={(
                <CheckOrCross
                  cond={exportData.exportUserDirectoryFields as boolean}
                />
              )}
            >
              <FormattedMessage id="USER_PARAMS_EXPORT_USER_PROFILE_FIELDS" />
            </ListItem>

            <ListItem
              before={
                <CheckOrCross cond={exportData.exportUserSettings as boolean} />
              }
            >
              <FormattedMessage id="USER_PARAMS_EXPORT_USER_SETTINGS" />
            </ListItem>
          </List>
        </ListItem>
      </List>
    </div>
  );
};

export default UserExportSummary;
