import { AxiosInstance, AxiosResponse } from 'axios';

export interface ITransactionService {
  deleteTransactions: (transactionIds: Array<string>) => Promise<AxiosResponse>;
  listTransactions: (
    category: string,
    platformID: string,
    planifKey?: string
  ) => Promise<AxiosResponse>;
  getTransaction: (id: string) => Promise<AxiosResponse>;
  getReports: (ids: Array<string>) => Promise<AxiosResponse>;
}

export const transactionServiceFactory = (
  client: AxiosInstance,
): ITransactionService => {
  const transactionService: ITransactionService = {
    deleteTransactions: async (transactionIds: Array<string>) => {
      const params = {
        transactionIds: transactionIds.join(','),
      };
      return client.delete('/api/transactions/', { params });
    },
    listTransactions: async (
      category: string,
      platformID: string,
      planifKey?: string,
    ) => {
      const params: any = { category, platformID };

      if (planifKey) {
        params.planifKey = planifKey;
      }
      return client.get('/api/transactions/', { params });
    },
    getTransaction: async (id: string) => client.get(`/api/transactions/${id}`),
    getReports: async (ids: Array<string>) => {
      const params = {
        transactionIds: ids.join(','),
      };
      return client.get('/api/transactions/report', { params });
    },
  };
  return transactionService;
};
