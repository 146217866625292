/* eslint-disable no-param-reassign */
import {
  action,
  Action,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
} from 'easy-peasy';
import { IInjections, IStore } from 'store';

interface IWorkflowStartPayload {
  totalSteps: number;
  type: 'import' | 'export';
}
export interface ModalStore {
  currentStep: number;
  totalSteps: number | undefined;
  type: string | undefined;
  category: string | undefined;

  setCurrentStep: Action<ModalStore, number>;
  nextStep: Thunk<ModalStore, void, IInjections, IStore>;
  setNextStep: Action<ModalStore>;
  previousStep: Action<ModalStore>;

  startWorkflow: Action<ModalStore, IWorkflowStartPayload>;
  endWorkflow: Action<ModalStore>;
  refreshTable: ThunkOn<ModalStore, void, IStore>;

  setWorkflowCategory: Action<ModalStore, string>;

  // Stepper
  currentStepHasErrors: boolean;
  setCurrentStepHasErrors: Action<ModalStore, boolean>;

  isLoading: boolean;
  setIsLoading: Action<ModalStore, boolean>;
}

const modalStore: ModalStore = {
  currentStep: 0,
  totalSteps: undefined,
  type: undefined,
  category: undefined,
  currentStepHasErrors: false,

  isLoading: false,
  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading;
  }),

  // Actions
  nextStep: thunk(
    async (actions, _, { getStoreActions, getState, getStoreState }) => {
      const {
        category, type, currentStep, totalSteps,
      } = getState();
      const store: any = `${type}`;

      if (totalSteps && currentStep >= totalSteps - 1) {
        actions.setIsLoading(true);
        if (type === 'export') {
          // @ts-ignore
          await getStoreActions().workflow[store][category].startExport();
        } else if (type === 'import') {
          // @ts-ignore
          await getStoreActions().workflow[store][category].startImport();
        }
        actions.setIsLoading(false);
        return;
      }

      const { isPlanified } = getStoreState().workflow.export.planification;

      // @ts-ignore
      let validated: any = getStoreActions().workflow[store][
        category
      ].validateStep(currentStep);

      // If it's a planification, check the planif specific args
      if (isPlanified) {
        validated = validated
          // @ts-ignore
          && getStoreActions().workflow[store].planification.validateStep(
            currentStep,
          );
      }

      if (validated) {
        actions.setCurrentStepHasErrors(false);
        actions.setNextStep();
      } else {
        actions.setCurrentStepHasErrors(true);
      }
    },
  ),
  setNextStep: action((state) => {
    state.currentStep += 1;
  }),
  previousStep: action((state) => {
    state.currentStep -= 1;
  }),
  setCurrentStep: action((state, payload) => {
    state.currentStep = payload;
  }),
  startWorkflow: action((state, payload) => {
    state.currentStep = 0;
    state.type = payload.type;
    state.totalSteps = payload.totalSteps;
  }),
  endWorkflow: action((state) => {
    // TODO: [GDPR] import => tell server to remove the picked file if one has been picked
    state.currentStep = -2;
    state.totalSteps = undefined;
    state.type = undefined;
    state.category = undefined;
    state.currentStepHasErrors = false;
  }),
  refreshTable: thunkOn(
    (_, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state, target, { getStoreActions, getStoreState }) => {
      const storeState = getStoreState();
      if (storeState.dashboard.tab === 0) {
        getStoreActions().transaction.fetchTransactions(false);
      } else {
        getStoreActions().planification.fetchPlanifications(false);
      }
    },
  ),
  setWorkflowCategory: action((state, category) => {
    state.category = category;
  }),
  setCurrentStepHasErrors: action((state, hasErrors) => {
    state.currentStepHasErrors = hasErrors;
  }),
};

export default modalStore;
