/* eslint-disable consistent-return */
import axios, { AxiosInstance, AxiosError } from 'axios';
import { ProviderName } from '../models';

interface SearchParams {
  // The author of documents in this category
  authors?: string;
  // 'File' if the category only contains files, 'Folder' if the category only contains folders
  model?: 'File' | 'Folder';
  // True if this category lists files recursively
  recursive?: boolean;
  // True if the files in this category are shared
  shared?: boolean;
  // True if the files in this category are shared with me
  sharedWithMe?: boolean;
  // True if the files in this category are starred
  starred?: boolean;
  // True if the files in this category are trashed
  trashed?: boolean;
}

interface DocumentListParams {
  // The cursor for continuing a previous request on the next page. This should be set to the value of 'cursor' from the previous response.
  cursor?: number;
  // The docPath of the item. It can be a provider, a site, a drive, or a resource (file or folder)
  docPath: string;
  // Maximum results returned. Acceptable values are 1 to MAX_INT, inclusive. (Default: 30)
  maxResults: number;
  // The document type within the provider, (possible: FOLDER, OTHER, IMAGE)
  searchTypes?: string[];
  // The text to search for
  searchText?: string;
  //
  searchParameters?: SearchParams;
}

const providerNameToLumappsProvider: any = {
  [ProviderName.Google]: 'drive',
  [ProviderName.Microsoft]: 'onedrive',
};

class lumappsDriveService {
  token: string;

  lumappsBaseUrl: string | undefined;

  onlyFolders: boolean;

  currentCursor?: number;

  api: AxiosInstance;

  provider: 'drive' | 'onedrive';

  drive?: string;

  constructor(
    token: string,
    onlyFolders: boolean,
    lumappsBaseUrl: string | undefined,
    serverRequestUrl: string | undefined = undefined,
    provider?: ProviderName
  ) {
    this.token = token;
    this.onlyFolders = onlyFolders;
    this.lumappsBaseUrl = lumappsBaseUrl;
    this.currentCursor = undefined;
    this.provider =
      providerNameToLumappsProvider[provider || ProviderName.Google];
    this.drive = undefined;
    this.api = this.createApiInstance();
  }

  createApiInstance = () => {
    const api = axios.create({
      baseURL: `${this.lumappsBaseUrl}/_ah/api/lumsites/v1`,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    api.interceptors.response.use(
      (response: any) => {
        this.currentCursor = response?.data?.cursor;
        return response;
      },
      (error: AxiosError) => {
        console.error(error);
        return error.response;
      }
    );
    return api;
  };

  hydrateFiles = (files: any) =>
    files
      ? files
          .map((el: any) => this.fileItemTransform(el))
          .filter(Boolean)
          .sort((a: any, b: any) => -(Number(a.isFolder) - Number(b.isFolder)))
      : null;

  // eslint-disable-next-line class-methods-use-this
  fileItemTransform(fileItem: any): any {
    if (!fileItem) {
      return null;
    }

    const imageLink = fileItem.thumbnail?.en;

    const isFolder = fileItem.isFolder;

    const parentFolder = fileItem?.parentFolderId;
    const createdAt = fileItem?.createdAt;
    const updatedAt = fileItem?.updatedAt;

    const fileItemContent = fileItem.content[0];

    const id = fileItemContent.fileId;
    const fileName = fileItemContent?.name;
    const fileSize = fileItemContent?.size;
    const ext = fileItemContent.mimeType

    return {
      id,
      parentId: parentFolder,
      name: fileName,
      isFolder,
      imageLink: imageLink,
      createdAt: new Date(createdAt).toDateString(),
      createdBy: fileItem?.created_by?.name,
      updatedAt: new Date(updatedAt).toDateString(),
      updatedBy: fileItem?.modified_by?.name,
      size: fileSize,
      extension: ext,
      url: fileItemContent.url,
      docPath: fileItem.docPath,
      details: fileItem,
    };
  }

  getFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false,
    searchText: string | null = null,
    searchParams?: SearchParams,
    sharedDrives: boolean = false
  ) => {
    if (nextPage && !this.currentCursor) {
      return;
    }

    let currentFolder = folderId;

    let docPath: string;
    // Google
    if (this.provider === 'drive') {
      if (!currentFolder || currentFolder === 'root') {
        currentFolder = sharedDrives ? 'all' : `root`;
      }
      docPath = `provider=${this.provider}/resource=${currentFolder}`;
      if (searchParams?.shared) {
        docPath = `provider=${this.provider}`;
      }
      if (sharedDrives) {
        docPath = `provider=${this.provider}/drive=${currentFolder}`;
      }
    }

    // Microsoft
    else if (this.provider === 'onedrive') {
      if (!currentFolder || currentFolder === 'root') {
        currentFolder = null;
      }
      const drive = 'user_email';
      docPath = `provider=onedrive/drive=${drive}`;
      if (currentFolder) {
        docPath = `${docPath}/resource=${currentFolder}`;
      }
    } else {
      throw Error('Provider unknow');
    }

    let params: DocumentListParams = {
      cursor: this.currentCursor,
      docPath: docPath,
      maxResults: 30,
      searchTypes: this.onlyFolders ? ['FOLDER'] : ['IMAGE', 'FOLDER', 'OTHER'],
    };

    if (searchText) {
      params.searchText = searchText;
    }

    if (searchParams) {
      params.searchParameters = searchParams;
    }

    const res = await this.api.post(`/document/list`, params);

    if (!res || ![201, 200].includes(res.status)) {
      return null;
    }

    const { data } = res;
    if (!data || !data.items) {
      return null;
    }

    const files = this.hydrateFiles(data.items);
    return files;
  };

  getMyFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false
  ) => this.getFiles(folderId, nextPage, null);

  getSharedWithMeFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false
  ) => this.getFiles(folderId, nextPage, null, { sharedWithMe: true });

  getStarredFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false
  ) => this.getFiles(folderId, nextPage, null, { starred: true });

  searchFilesByName = async (
    searchText: string,
    folderId: string | null = null
  ) => this.getFiles(folderId, false, searchText);
}

export default lumappsDriveService;
