import { IDrivePickerAction, DrivePickerActionType } from './actions';

export interface IDrivePickerState {
  selectedPickerSubCategory: string | undefined;
  selectedPickerMainCateg: string | undefined;
  selectPickerCategInfos: any;
  files: Array<any> | undefined;
  query: string | undefined;
}

export const INITIAL_STATE: IDrivePickerState = {
  selectedPickerMainCateg: undefined,
  selectedPickerSubCategory: undefined,
  selectPickerCategInfos: undefined,
  files: undefined,
  query: undefined,
};

export default (
  state: IDrivePickerState = INITIAL_STATE,
  action: IDrivePickerAction
) => {
  switch (action.type) {
    case DrivePickerActionType.setSelectedPickerMainCategory:
      return {
        ...state,
        selectedPickerMainCateg: action.payload,
      };
    case DrivePickerActionType.setSelectedPickerSubCategory:
      return {
        ...state,
        selectedPickerSubCategory: action.payload,
      };
    case DrivePickerActionType.setSelectPickerCategInfos:
      return {
        ...state,
        selectPickerCategInfos: action.payload,
      };
    case DrivePickerActionType.setFiles:
      return {
        ...state,
        files: action.payload,
      };
    case DrivePickerActionType.setQuery:
      return {
        ...state,
        query: action.payload,
      };

    default:
      return state;
  }
};
