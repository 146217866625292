/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useMemo } from 'react';

import {
  mdiCheck, mdiClose, mdiCloudDownload, mdiDelete,
} from '@lumx/icons';
import {
  Chip,
  Emphasis,
  Icon,
  IconButton,
  Progress,
  Size,
  ThScope,
} from '@lumx/react';

import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import dayjs from 'dayjs';

import { DataTable } from 'components/DataTable';
import LineWithIcon from 'components/LineWithIcon';
import { useStoreActions, useStoreState } from 'hooks';

import { useTheme } from 'Theme';
import { AddButton } from './Workflow';

const TransactionTable = () => {
  const { theme } = useTheme();
  const intl = useIntl();

  /* Transaction actions */
  const {
    fetchTransactions,
    getTransactionsReport,
    deleteTransactions,
  } = useStoreActions((actions) => actions.transaction);

  const isLoading = useStoreState((state) => state.transaction.isLoading);
  const transactions: any = useStoreState(
    (state) => state.transaction.transactions,
  );

  const tableData = useMemo(
    () => (transactions || []).map((transaction: any) => {
      const dateFields = ['startDate', 'lastUpdateDate'];
      dateFields.forEach((field: string) => {
        const fieldVal = transaction[field];
        if (fieldVal) {
          transaction[field] = new Date(fieldVal);
        }
      });
      return transaction;
    }),
    [transactions],
  );

  const category = useStoreState((state) => state.dashboard.category);

  const setPlanifKey = useStoreActions(
    (actions) => actions.planification.setSelectedPlanifKey,
  );
  const planifKey = useStoreState(
    (state) => state.planification.selectedPlanifKey,
  );
  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const handleDownloadReport = async (selectedRows: Array<string>) => {
    const reportUrl = await getTransactionsReport(selectedRows);
    window.open(reportUrl);
  };

  /*
   * Toolbars
   */
  const actionToolbar = (
    selectedRows: Array<any>,
    _displayData: Array<any>,
    setSelectedRows: any,
  ): any => {
    const transacs = _displayData.filter((tr: any) => selectedRows.includes(tr.id));
    const transactionsThatCanBeDownloaded = transacs.filter(
      (el: any) => (el.type === 'export' && el.status === 'done') || el.type === 'import',
    );
    const canDownloadSelected = transactionsThatCanBeDownloaded.length === selectedRows.length;
    return (
      <div>
        {canDownloadSelected && (
          <IconButton
            title={intl.formatMessage({ id: 'DOWNLOAD' })}
            emphasis={Emphasis.low}
            icon={mdiCloudDownload}
            theme={theme.theme}
            onClick={() => handleDownloadReport(selectedRows)}
          />
        )}
        <IconButton
          title={intl.formatMessage({ id: 'DELETE' })}
          emphasis={Emphasis.low}
          icon={mdiDelete}
          theme={theme.theme}
          onClick={() => {
            deleteTransactions(selectedRows);
            setSelectedRows([]);
          }}
        />
      </div>
    );
  };

  const options = {
    defaultSortHeaderName: 'startDate',
    actionToolbar,
    paginated: true,
    sort: true,
    search: true,
    filter: true,
    viewColumns: true,
    translatedPaginationText: {
      rowsPerPage: 'Lignes par page',
      of: 'de',
    },
    noDataFiller: (
      <div style={{ marginTop: '10px' }}>
        <FormattedMessage id="NO_DATA" />
      </div>
    ),
    buttonTooltips: {
      viewColumnsButtonTooltipLabel: intl.formatMessage({ id: 'VIEW_COLUMNS' }),
      filterTableTooltipLabel: intl.formatMessage({
        id: 'FILTER_TABLE',
        defaultMessage: 'Filter Table',
      }),
    },
  };

  const title: any = useMemo(
    () => (planifKey ? (
      <>
        <AddButton
          containerClasses="lum-toolbox-add-button"
          buttonContent={(<FormattedMessage id="CREATE" />) as any}
        />
        {' '}
        <Chip
          onClick={() => {
            setPlanifKey(undefined);
            fetchTransactions();
          }}
          after={<Icon icon={mdiClose} size={Size.xs} />}
        >
          {planifKey}
        </Chip>
      </>
    ) : (
      <AddButton buttonContent={(<FormattedMessage id="CREATE" />) as any} />
    )),
    [planifKey],
  );

  const tableHeaders: any = useMemo(
    () => [
      {
        isSortable: true,
        label: <FormattedMessage id="TYPE" />,
        name: 'type',
        scope: ThScope.col,
        searchable: true,
      },
      {
        isSortable: true,
        label: <FormattedMessage id="EXPORT_FILE_NAME" />,
        name: 'refFile',
        scope: ThScope.col,
        searchable: true,
        download: true,
        customBodyRender: (_a: any, _b: any, _c: any, _row: any) => {
          const filename = _row.type.toLowerCase() === 'export' ? _row.refFile : _row.title;
          const hasDownloadUrl = _row?.params?.exportToInfos?.exportedFileUrl
            || _row?.params?.exportToInfos?.destination === 'csv';
          return (
            <>
              {hasDownloadUrl ? (
                <a
                  href={hasDownloadUrl ? '#' : undefined}
                  onClick={() => handleDownloadReport([_row.id])}
                >
                  <span>{filename}</span>
                </a>
              ) : (
                <span>{filename}</span>
              )}
            </>
          );
        },
      },
      {
        isSortable: true,
        label: <FormattedMessage id="SITE_NAME" />,
        name: 'instanceName',
        scope: ThScope.col,
        searchable: true,
        download: true,
      },
      {
        isSortable: true,
        label: <FormattedMessage id="EXPORT_START_DATE" />,
        name: 'startDate',
        scope: ThScope.col,
        searchable: true,
        download: true,
        customBodyRender: (cellValue: string) => (
          <b>
            <FormattedDate
              value={cellValue}
              year="numeric"
              month="numeric"
              day="numeric"
              hour="numeric"
              minute="numeric"
            />
          </b>
        ),
      },
      {
        isSortable: true,
        label: <FormattedMessage id="EXPORT_ELEMENTS_COUNT" />,
        name: 'elem_count',
        scope: ThScope.col,
        searchable: true,
        download: true,
        customBodyRender: (
          _cellValue: string,
          _idx: number,
          _hidx: number,
          row: any,
        ) => (
          <b>
            {row.successElements}
            /
            {row.processedElements}
            /
            {row.totalElements}
          </b>
        ),
      },
      {
        isSortable: true,
        label: <FormattedMessage id="ELAPSED_TIME" />,
        name: 'elpased_time',
        scope: ThScope.col,
        searchable: true,
        download: true,
        customBodyRender: (
          _cellValue: string,
          _idx: number,
          _hidx: number,
          row: any,
        ) => {
          const timeTaken = dayjs
            .duration(dayjs(row.lastUpdateDate).diff(dayjs(row.startDate)))
            .humanize(false);
          return <b>{timeTaken}</b>;
        },
      },
      {
        isSortable: true,
        label: <FormattedMessage id="STATUS" />,
        name: 'status',
        scope: ThScope.col,
        searchable: true,
        download: true,
        customBodyRender: (cellValue: string, a: any, b: any, row: any) => {
          switch (cellValue) {
            case 'done':
              return (
                <LineWithIcon
                  color="green"
                  icon={mdiCheck}
                  content={<FormattedMessage id="DONE_STATUS" />}
                />
              );

            case 'running':
              return (
                <LineWithIcon
                  color="blue"
                  icon="progress"
                  content={<FormattedMessage id="RUNNING_STATUS" />}
                />
              );

            case 'pending':
              return (
                <LineWithIcon
                  color="blue"
                  icon="progress"
                  content={<FormattedMessage id="PENDING_STATUS" />}
                />
              );

            case 'error':
              return (
                <>
                  <LineWithIcon
                    color="red"
                    icon={mdiClose}
                    content={<FormattedMessage id="ERROR_STATUS" />}
                    withHelp
                    helpContent={row.error}
                  />
                </>
              );

            default:
              return 'UNKNOWN';
          }
        },
      },
    ],
    [handleDownloadReport],
  );

  return (
    <>
      {isLoading && (
        <div
          style={{
            minHeight: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Progress theme={theme.theme} />
        </div>
      )}
      {!isLoading && transactions && (
        <DataTable
          title={title}
          tableHeaders={tableHeaders}
          tableData={tableData}
          options={options}
        />
      )}
    </>
  );
};

export default TransactionTable;
