import {
  List,
  ListItem,
  ListSubheader,
  RadioButton,
  RadioGroup,
} from '@lumx/react';
import {
  Divider,
  SimpleSelect,
  SubRadioButtonContentWrapper,
} from 'components/LumxWrappers';
import LumappsLangs from 'containers/LumappsLangs';
import { useStoreActions, useStoreState } from 'hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ContentSiteParams = () => {
  const {
    selectedSiteName,
    exportAllContentTypes,
    exportContentType,
    selectedContentTypeName,
    loadingContentType,
  } = useStoreState((state) => state.workflow.export.content);

  const {
    selectSite,
    setExportSiteContentsChoice,
    selectContentType,
  } = useStoreActions((actions) => actions.workflow.export.content);

  const userSites = useStoreState((state) =>
    state.user.userLumappsSites?.map((el: any) => ({
      value: el.id,
      displayName: el.name,
      name: el.name,
    }))
  );

  const siteContentTypes = useStoreState((state) =>
    state.workflow.export.content.siteContentTypes?.map((el: any) => ({
      value: el.id,
      displayName: el.name,
      name: el.name,
    }))
  );
  return (
    <>
      <List>
        <ListItem>
          <SimpleSelect
            choices={userSites}
            label={<FormattedMessage id="SELECT_SITE" />}
            value={selectedSiteName}
            setValue={selectSite}
          />
        </ListItem>
        <ListItem>
          <RadioGroup>
            <RadioButton
              checked={exportAllContentTypes}
              value="exportAllContentTypes"
              name="exportAllContentTypes"
              onChange={setExportSiteContentsChoice}
              label={<FormattedMessage id="CONTENT_EXPORT_ALL_CONTENT_TYPES" />}
            />
            <RadioButton
              checked={exportContentType}
              value="exportContentType"
              name="exportContentType"
              onChange={setExportSiteContentsChoice}
              label={<FormattedMessage id="CONTENT_EXPORT_CONTENT_TYPE" />}
            />
            {exportContentType && (
              <SubRadioButtonContentWrapper>
                <SimpleSelect
                  choices={siteContentTypes}
                  label={<FormattedMessage id="SELECT_CCT" />}
                  value={selectedContentTypeName}
                  setValue={selectContentType}
                  isLoading={loadingContentType}
                />
              </SubRadioButtonContentWrapper>
            )}
          </RadioGroup>
        </ListItem>
      </List>
    </>
  );
};

const ContentParams = () => {
  const { selectedLang, exportAllContents, exportSiteContents } = useStoreState(
    (state) => state.workflow.export.content
  );

  const { selectLang, setContentExportChoice } = useStoreActions(
    (actions) => actions.workflow.export.content
  );

  const langs: Array<any> = [...LumappsLangs];
  return (
    <>
      <List>
        <ListItem>
          <SimpleSelect
            choices={langs}
            value={selectedLang}
            setValue={selectLang}
            label={<FormattedMessage id="SELECT_EXPORT_LANG" />}
            helper={<FormattedMessage id="SELECT_EXPORT_LANG_HELPER" />}
          />
          <Divider />
        </ListItem>

        <ListSubheader>
          <FormattedMessage id="EXPORT_CHOICE" />
        </ListSubheader>
        <ListItem>
          <RadioGroup>
            <RadioButton
              checked={exportAllContents}
              value="exportAllContents"
              name="exportAllContents"
              onChange={setContentExportChoice}
              label={<FormattedMessage id="CONTENT_EXPORT_ALL_CONTENTS" />}
            />
            <RadioButton
              checked={exportSiteContents}
              value="exportSiteContents"
              name="exportSiteContents"
              onChange={setContentExportChoice}
              label={<FormattedMessage id="CONTENT_EXPORT_SITE_CONTENTS" />}
            />
            {exportSiteContents && (
              <SubRadioButtonContentWrapper>
                <ContentSiteParams />
              </SubRadioButtonContentWrapper>
            )}
          </RadioGroup>
        </ListItem>
      </List>
    </>
  );
};

export default ContentParams;
