/* eslint-disable radix */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiGoogleCirclesExtended,
  mdiApps,
  mdiDns,
  mdiCheckboxMultipleBlank,
  mdiFileDocumentBox,
  mdiChevronLeft,
  mdiChevronRight,
} from '@lumx/icons';

import {
  Divider,
  Emphasis,
  SideNavigation,
  SideNavigationItem,
  IconButton,
} from '@lumx/react';

import { useTheme } from 'Theme';
import { FormattedMessage } from 'react-intl';
import { useStoreState, useStoreActions } from 'hooks';
import { DashboardCategory } from 'models/dashboard';

const categories = [
  {
    label: 'Title Section 1',
    childrens: [
      {
        id: DashboardCategory.user,
        label: 'User',
        icon: mdiAccount,
      },
      {
        id: DashboardCategory.group,
        label: 'Group',
        icon: mdiAccountMultiple,
      },
      {
        id: DashboardCategory.community,
        label: 'Community',
        icon: mdiGoogleCirclesExtended,
      },
      {
        id: DashboardCategory.content,
        label: 'Content',
        icon: mdiFileDocumentBox,
      },
      {
        id: DashboardCategory.directory,
        label: 'Directory',
        icon: mdiApps,
      },
      {
        id: DashboardCategory.metadata,
        label: 'Metadata',
        icon: mdiDns,
      },
      {
        id: DashboardCategory.site,
        label: 'Site',
        icon: mdiCheckboxMultipleBlank,
      },
    ],
  },
];

const DashboardSideNavigation: React.FC = (): any => {
  const category = useStoreState((state) => state.dashboard.category);

  const setCategory = useStoreActions(
    (actions) => actions.dashboard.setCategory,
  );

  const { theme } = useTheme();

  const [isFullyOpen, setIsFullyOpen] = useState(true);

  useEffect(() => {
    const root = document.documentElement;
    if (isFullyOpen) {
      root.style.setProperty('--sideNavTranslateWidth', '0px');
      root.style.setProperty('--sideNavWidth', '240px');
    } else {
      root.style.setProperty('--sideNavTranslateWidth', '0px');
      root.style.setProperty('--sideNavWidth', '40px');
    }
  }, [isFullyOpen]);

  const toggleSideNav = () => {
    const root = document.documentElement;
    setIsFullyOpen(
      parseInt(
        getComputedStyle(root)
          .getPropertyValue('--sideNavWidth')
          .replace('px', ''),
      ) < 50,
    );
  };

  return (
    <div className="lum-toolbox-side-nav">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          emphasis={Emphasis.low}
          icon={isFullyOpen ? mdiChevronLeft : mdiChevronRight}
          onClick={toggleSideNav}
        />
      </div>

      <Divider theme={theme.theme} />

      <SideNavigation theme={theme.theme}>
        {categories.map((mainCategory: any) => mainCategory.childrens.map((subCategory: any) => (
          <SideNavigationItem
            label={
                  (
                    <FormattedMessage id={subCategory.label.toUpperCase()} />
                  ) as any
                }
            icon={subCategory.icon}
            emphasis={Emphasis.low}
            isSelected={category === subCategory.id}
            onClick={() => setCategory(subCategory.id)}
          />
        )))}
      </SideNavigation>
    </div>
  );
};

export default DashboardSideNavigation;
