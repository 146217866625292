/* eslint-disable no-undef */
import axios, { AxiosInstance, AxiosResponse } from 'axios';

export interface ILumappsService {
  getCurrentUserFromLumapps: (
    lumappsPlatformBaseUrl: string
  ) => Promise<any | undefined>;
  getUserLumappsSites: (
    platformID: string,
    lumappsPlatformBaseUrl: string
  ) => Promise<AxiosResponse>;
  getSiteDirectories: (
    siteId: string | number,
    platformID: string,
    lumappsPlatformBaseUrl: string
  ) => Promise<AxiosResponse>;
  getSiteUserDirectories: (
    siteId: string | number,
    platformID: string,
    lumappsPlatformBaseUrl: string
  ) => Promise<AxiosResponse>;
  getSiteGroups: (
    siteId: string | number,
    platformID: string,
    lumappsPlatformBaseUrl: string
  ) => Promise<AxiosResponse>;
  getSiteCCTs: (
    siteId: string | number,
    platformID: string,
    lumappsPlatformBaseUrl: string
  ) => Promise<AxiosResponse>;
  getSiteCommunities: (
    siteId: string | number,
    platformID: string,
    lumappsPlatformBaseUrl: string,
    search?: string
  ) => Promise<AxiosResponse>;
}

export const lumappsServicefactory = (
  client: AxiosInstance
): ILumappsService => {
  const lumappsService: ILumappsService = {
    getCurrentUserFromLumapps: async (lumappsPlatformBaseUrl: string) => {
      if (process.env.NODE_ENV === 'development') {
        return Promise.resolve({
          email: 'test@test.com',
          customer: '4664706704080896',
        });
      }
      const urlParams = new URLSearchParams(document.location.search);
      const token = urlParams.get('token');
      const url = `${lumappsPlatformBaseUrl}/_ah/api/lumsites/v1/user/get`;
      try {
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return res?.data;
      } catch (err) {
        return null;
      }
    },

    getUserLumappsSites: async (
      platformID: string,
      lumappsPlatformBaseUrl: string
    ) =>
      client.get(`/api/lumapps/sites`, {
        params: { platformID, lumappsPlatformBaseUrl },
      }),
    getSiteUserDirectories: async (
      siteId: string | number,
      platformID: string,
      lumappsPlatformBaseUrl: string
    ) =>
      client.get(`/api/lumapps/${siteId}/userDirectories`, {
        params: { platformID, lumappsPlatformBaseUrl },
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      }),
    getSiteDirectories: async (
      siteId: string | number,
      platformID: string,
      lumappsPlatformBaseUrl: string
    ) =>
      client.get(`/api/lumapps/${siteId}/directories`, {
        params: { platformID, lumappsPlatformBaseUrl },
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      }),

    getSiteGroups: async (
      siteId: string | number,
      platformID: string,
      lumappsPlatformBaseUrl: string
    ) =>
      client.get(`/api/lumapps/${siteId}/groups`, {
        params: { platformID, lumappsPlatformBaseUrl },
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      }),
    getSiteCCTs: async (
      siteId: string | number,
      platformID: string,
      lumappsPlatformBaseUrl: string
    ) =>
      client.get(`/api/lumapps/${siteId}/ccts`, {
        params: { platformID, lumappsPlatformBaseUrl },
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      }),
    getSiteCommunities: async (
      siteId: string | number,
      platformID: string,
      lumappsPlatformBaseUrl: string,
      search?: string
    ) => {
      let params: any = { platformID, lumappsPlatformBaseUrl };
      if (search) {
        params = { ...params, search: search };
      }
      return client.get(`/api/lumapps/${siteId}/communities`, {
        params,
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      });
    },
  };
  return lumappsService;
};
