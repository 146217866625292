import { AxiosInstance, AxiosResponse } from 'axios';
import {
  UserExportDTO,
  DirectoryExportDTO,
  GroupExportDTO,
  MetadataExportDTO,
  ContentExportDTO,
  CommunityExportDTO,
  SiteExportDTO,
} from 'models/dtos';

export interface IExportService {
  startUserExport: (exportData: UserExportDTO) => Promise<AxiosResponse>;
  startDirectoryExport: (
    exportData: DirectoryExportDTO
  ) => Promise<AxiosResponse>;
  startGroupExport: (exportData: GroupExportDTO) => Promise<AxiosResponse>;
  startMetadataExport: (
    exportData: MetadataExportDTO
  ) => Promise<AxiosResponse>;
  startContentExport: (exportData: ContentExportDTO) => Promise<AxiosResponse>;
  startCommunityExport: (
    exportData: CommunityExportDTO
  ) => Promise<AxiosResponse>;
  startSiteExport: (exportData: SiteExportDTO) => Promise<AxiosResponse>
}

export const exportServiceFactory = (client: AxiosInstance): IExportService => {
  const baseUrl = '/api/export';
  const exportService: IExportService = {
    startUserExport: async (exportData: UserExportDTO) => {
      return client.post(`${baseUrl}/user`, exportData);
    },
    startDirectoryExport: async (exportData: DirectoryExportDTO) => {
      return client.post(`${baseUrl}/directory`, exportData);
    },
    startGroupExport: async (exportData: GroupExportDTO) => {
      return client.post(`${baseUrl}/group`, exportData);
    },
    startMetadataExport: async (exportData: MetadataExportDTO) => {
      return client.post(`${baseUrl}/metadata`, exportData);
    },
    startContentExport: async (exportData: ContentExportDTO) => {
      return client.post(`${baseUrl}/content`, exportData);
    },
    startCommunityExport: async (exportData: CommunityExportDTO) => {
      return client.post(`${baseUrl}/community`, exportData);
    },
    startSiteExport: async (exportData: SiteExportDTO) => {
      return client.post(`${baseUrl}/site`, exportData)
    }
  };
  return exportService;
};
