import React from 'react';

const Divider = () => (
  <hr
    style={{
      height: '1px',
      border: 'none',
      margin: '12px 0px 12px 0px',
      backgroundColor: 'black',
    }}
  />
);

export default Divider;
