import { RadioButton, RadioGroup } from '@lumx/react';
import {
  SimpleSelect,
  SubRadioButtonContentWrapper,
} from 'components/LumxWrappers';
import { useStoreActions, useStoreState } from 'hooks';
import { MetadataExportType } from 'models/dtos';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const MetadataParams: React.FC = () => {
  const {
    exportType,
    selectedSiteName,
  } = useStoreState((state) => state.workflow.export.metadata);

  const { setMetadataExportType, selectSite } = useStoreActions(
    (state) => state.workflow.export.metadata,
  );
  const userSites = useStoreState((state) => state.user.userLumappsSites?.map((el: any) => ({
    value: el.id,
    displayName: el.name,
    name: el.name,
  })));

  return (
    <>
      <RadioGroup>
        <RadioButton
          checked={exportType === MetadataExportType.exportAllMetadata}
          value={MetadataExportType.exportAllMetadata as any}
          name={MetadataExportType.exportAllMetadata as any}
          onChange={setMetadataExportType}
          label={
            <FormattedMessage id="METADATA_EXPORT_PARAMS_EXPORT_ALL_METADATA" />
          }
        />
        <RadioButton
          checked={exportType === MetadataExportType.exportPlatformMetadata}
          value={MetadataExportType.exportPlatformMetadata as any}
          name={MetadataExportType.exportPlatformMetadata as any}
          onChange={setMetadataExportType}
          label={
            <FormattedMessage id="METADATA_EXPORT_PARAMS_EXPORT_PLATFORM_METADATA" />
          }
        />
        <RadioButton
          checked={exportType === MetadataExportType.exportSiteMetadata}
          value={MetadataExportType.exportSiteMetadata as any}
          name={MetadataExportType.exportSiteMetadata as any}
          onChange={setMetadataExportType}
          label={
            <FormattedMessage id="METADATA_EXPORT_PARAMS_EXPORT_SITE_METADATA" />
          }
        />
        {exportType === MetadataExportType.exportSiteMetadata && (
          <SubRadioButtonContentWrapper>
            <SimpleSelect
              choices={userSites}
              label={<FormattedMessage id="SELECT_SITE" />}
              value={selectedSiteName}
              setValue={selectSite}
            />
          </SubRadioButtonContentWrapper>
        )}
      </RadioGroup>
    </>
  );
};

export default MetadataParams;
