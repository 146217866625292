/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {
  Thunk, thunk, Action, action,
} from 'easy-peasy';
import { IInjections, IStore } from 'store';
import { TransactionDTO } from 'models/dtos';
import { NotificationsErrors, NotificationsSuccessMessages, TransactionStatus } from 'models';

export interface TransactionStore {
  transactions: Array<TransactionDTO> | undefined;
  setTransactions: Action<TransactionStore, Array<TransactionDTO>>;
  fetchTransactions: Thunk<
  TransactionStore,
  boolean | void,
  IInjections,
  IStore
  >;
  isLoading: boolean;
  setIsLoading: Action<TransactionStore, boolean>;

  _timeout: any;
  setThisTimeout: Action<TransactionStore, any>;
  clearTimeout: Action<TransactionStore, void>;

  deleteTransactions: Thunk<
  TransactionStore,
  Array<string>,
  IInjections,
  IStore
  >;
  getTransactionsReport: Thunk<
  TransactionStore,
  Array<string>,
  IInjections,
  IStore
  >;
}

const transactionStore: TransactionStore = {
  transactions: undefined,
  setTransactions: action((state, transactions) => {
    state.transactions = [...transactions];
  }),

  fetchTransactions: thunk(
    async (
      actions,
      withLoader = true,
      { injections, getStoreActions, getStoreState },
    ) => {
      if (withLoader) {
        actions.setTransactions([]);
        actions.setIsLoading(true);
      }
      const { transactionService } = injections;

      const storeState = getStoreState();
      const { category } = storeState.dashboard;
      const planifKey = storeState.planification.selectedPlanifKey;
      const plaformID = storeState.user.currentLumappsPlatformID;

      const res = await transactionService.listTransactions(
        category,
        plaformID,
        planifKey,
      );
      if (withLoader) {
        actions.setIsLoading(false);
      }
      if (!res || res.status !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        return;
      }
      const { data } = res;
      actions.setTransactions(data);

      const hasPendingOrRunningTransaction = data.find(
        (e: any) => e.status === TransactionStatus.running || e.status === TransactionStatus.pending,
      );
      if (hasPendingOrRunningTransaction) {
        actions.clearTimeout();
        const g = setTimeout(() => actions.fetchTransactions(false), 7000);
        actions.setThisTimeout(g);
      }
      if (planifKey && !hasPendingOrRunningTransaction) {
        getStoreActions().planification.setSelectedPlanifKey(undefined);
      }
    },
  ),
  isLoading: false,
  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading;
  }),

  _timeout: undefined,
  setThisTimeout: action((state, val) => {
    state._timeout = val;
  }),
  clearTimeout: action((state) => {
    clearTimeout(state._timeout);
    state._timeout = undefined;
  }),

  deleteTransactions: thunk(
    async (actions, transactionIds, { injections, getStoreActions }) => {
      const { transactionService } = injections;

      const { data, status } = await transactionService.deleteTransactions(
        transactionIds,
      );
      if (status !== 200 && status !== 204) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.DELETE_ERROR,
          severity: 'error',
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.TRANSACTION_DELETED,
        severity: 'success',
      });
      actions.fetchTransactions(false);
      return data;
    },
  ),

  getTransactionsReport: thunk(
    async (actions, transactionIds, { injections, getStoreActions }) => {
      const { transactionService } = injections;

      const { data, status } = await transactionService.getReports(
        transactionIds,
      );
      if (status !== 200 && status !== 204) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.DOWNLOAD_REPORT_ERROR,
          severity: 'error',
        });
        return;
      }
      // getStoreActions().notifications.notify({
      //     message: NotificationsSuccessMessages.TRANSACTION_DELETED,
      //     severity: 'success',
      // })
      return data;
    },
  ),
};

export default transactionStore;
