/* eslint-disable no-undef */
// eslint-disable-next-line import/prefer-default-export
export const getParentOrigin = (): string => {
  let parentOrigin: any =
    new URLSearchParams(document.location.search).get(
      'lumappsPlatformBaseUrl'
    ) || new URLSearchParams(document.location.search).get('endpoint');
  if (!parentOrigin) {
    // Iframe Parent url
    const parentUrl = new URLSearchParams(document.location.search).get(
      'parentUrl'
    );
    const parentOriginBackoff = document.location.ancestorOrigins
      ? document.location.ancestorOrigins[0]
      : null;
    parentOrigin = parentUrl ? new URL(parentUrl).origin : parentOriginBackoff;
  }
  else {
    parentOrigin = new URL(parentOrigin).origin
  }

  return parentOrigin;
};
