/* eslint-disable no-param-reassign */
import { action, Action, ThunkOn, thunkOn } from 'easy-peasy';
import { DashboardCategory } from 'models/dashboard';

const initialDashboardModel = {
  category: DashboardCategory.user,
  tab: 0,
};

export interface IDashboardModel {
  category: DashboardCategory;
  tab: number;

  setTab: Action<IDashboardModel, number>;
  setCategory: Action<IDashboardModel, DashboardCategory>;
}

export const dashboardModel: IDashboardModel = {
  ...initialDashboardModel,

  // Actions
  setTab: action((state, tab) => {
    state.tab = tab;
  }),
  setCategory: action((state, category) => {
    state.category = category;
  }),
};
