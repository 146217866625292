import { RadioButton, RadioGroup } from '@lumx/react';
import {
  SimpleSelect,
  SubRadioButtonContentWrapper,
} from 'components/LumxWrappers';
import { useStoreActions, useStoreState } from 'hooks';
import { GroupExportType } from 'models/dtos';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const GroupParams: React.FC = () => {
  const {
    exportType,
    selectedSiteName,
  } = useStoreState((state) => state.workflow.export.group);

  const { setGroupExportType, selectSite } = useStoreActions(
    (state) => state.workflow.export.group,
  );
  const userSites = useStoreState((state) => state.user.userLumappsSites?.map((el: any) => ({
    value: el.id,
    displayName: el.name,
    name: el.name,
  })));

  return (
    <>
      <RadioGroup>
        <RadioButton
          checked={exportType === GroupExportType.exportAllGroups}
          value={GroupExportType.exportAllGroups}
          name={GroupExportType.exportAllGroups}
          onChange={setGroupExportType}
          label={
            <FormattedMessage id="GROUP_EXPORT_PARAMS_EXPORT_ALL_GROUPS" />
          }
        />
        <RadioButton
          checked={exportType === GroupExportType.exportPlatformGroups}
          value={GroupExportType.exportPlatformGroups}
          name={GroupExportType.exportPlatformGroups}
          onChange={setGroupExportType}
          label={
            <FormattedMessage id="GROUP_EXPORT_PARAMS_EXPORT_PLATFORM_GROUPS" />
          }
        />
        <RadioButton
          checked={exportType === GroupExportType.exportSiteGroups}
          value={GroupExportType.exportSiteGroups}
          name={GroupExportType.exportSiteGroups}
          onChange={setGroupExportType}
          label={
            <FormattedMessage id="GROUP_EXPORT_PARAMS_EXPORT_SITE_GROUPS" />
          }
        />
        {exportType === GroupExportType.exportSiteGroups && (
          <SubRadioButtonContentWrapper>
            <SimpleSelect
              choices={userSites}
              label={<FormattedMessage id="SELECT_SITE" />}
              value={selectedSiteName}
              setValue={selectSite}
            />
          </SubRadioButtonContentWrapper>
        )}
      </RadioGroup>
    </>
  );
};

export default GroupParams;
