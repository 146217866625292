import userImportStore, { UserImportStore } from './userStore';
import commonImportStore, { CommonImportStore } from './commonStore';
import groupImportStore, { GroupImportStore } from './groupStore';
import directoryImportStore, { DirectoryImportStore } from './directoryStore';
import metadataStore, { MetadataImportStore } from './metadataStore';
import contentStore, { ContentImportStore } from './contentStore';

export interface ImportStore {
  common: CommonImportStore;
  user: UserImportStore;
  directory: DirectoryImportStore;
  group: GroupImportStore;
  metadata: MetadataImportStore;
  content: ContentImportStore;
}

const importStore: ImportStore = {
  common: commonImportStore,
  user: userImportStore,
  directory: directoryImportStore,
  group: groupImportStore,
  metadata: metadataStore,
  content: contentStore,
};

export default importStore;
