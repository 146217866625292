import { createTypedHooks } from 'easy-peasy';
import { IStore } from 'store';

export { default as useBooleanState } from './useBooleanState';
export { default as useScript } from './useScript';
export { default as useStyles } from './useStyles';
export { default as useResizeObserver } from './useResizeObserver';
export { default as useScroll } from './useScroll';
export { default as useKeyPress } from './useKeyPress';
export { default as useWindowSize } from './useWindowSize';

const typedHooks = createTypedHooks<IStore>();

export const { useStoreActions } = typedHooks;
export const { useStoreDispatch } = typedHooks;
export const { useStoreState } = typedHooks;
