/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { action, Action, actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';

import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import {
  GroupExportDTO,
  GroupExportType,
  IExportFileInfos,
  PlanifDTO,
} from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

interface ISelectChoice {
  value: any;
  name: string;
  displayName: string;
}

export interface GroupExportStore extends BaseExportStore {
  exportType: GroupExportType;
  setGroupExportType: Action<GroupExportStore, GroupExportType | string>;

  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  selectSite: Action<GroupExportStore, ISelectChoice>;

  startExport: Thunk<GroupExportStore, void, IInjections, IStore>;
  validateStep: Thunk<GroupExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<GroupExportStore, IStore>;
}

const groupExportStore: GroupExportStore = {
  exportType: GroupExportType.exportAllGroups,
  setGroupExportType: action((state, exportType) => {
    state.exportType = exportType as GroupExportType;
    switch (exportType) {
      case GroupExportType.exportAllGroups:
        state.selectedSiteName = '[ALL_SITES]';
      case GroupExportType.exportPlatformGroups:
        state.selectedSiteName = '[PLATFORM]';
      default:
        state.selectedSiteName = undefined
        break;
    }
  }),

  selectedSiteId: undefined,
  selectedSiteName: undefined,
  selectSite: action((state, site) => {
    state.selectedSiteId = site?.value;
    state.selectedSiteName = site?.displayName;
  }),

  startExport: thunk(
    async (
      _actions,
      _,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();

      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: GroupExportDTO = {
        exportToInfos,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        limit: undefined,
        lang: undefined,
        title: undefined,

        exportType: state.exportType,
        instanceId: state.selectedSiteId || '',
        instanceName: state.selectedSiteName || '',

        ...planifDTO,
      };

      const { data, status } = await exportService.startGroupExport(exportData);
      const notifyAction = getStoreActions().notifications;
      if (![200, 204].includes(status)) {
        notifyAction.notify({
          severity: 'error',
          message: NotificationsErrors.UNEXPECTED_ERROR,
        });
        return;
      }
      notifyAction.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
      return data;
    }
  ),
  validateStep: thunk((_actions, stepNumber, { getStoreActions, getState }) => {
    const state = getState();
    let success = true;
    switch (stepNumber) {
      // Parameters step
      case 1:
        if (
          state.exportType === GroupExportType.exportSiteGroups &&
          !state.selectedSiteName
        ) {
          getStoreActions().notifications.notify({
            message: NotificationsErrors.NO_SITE_SELECTED_ERROR,
            severity: 'error',
          });
          success = false;
        }
        return success;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (_actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.exportType = GroupExportType.exportAllGroups;
      state.selectedSiteId = undefined;
      state.selectedSiteName = undefined;
    }
  ),
};

export default groupExportStore;
