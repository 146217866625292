import { userModel, IUserModel } from './userModel';
import { dashboardModel, IDashboardModel } from './dashboardModel';

export interface IMainStore {
  dashboard: IDashboardModel;
  user: IUserModel;
}
export const store = {
  dashboard: dashboardModel,
  user: userModel,
};
