// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Choice } from 'components/LumxWrappers/SimpleSelect';
import { baseLumappsLangs } from 'models/dtos';

const LumappsLangs: Array<Choice> = baseLumappsLangs.map((e: string) => ({
  displayName: e,
  name: e,
  value: e,
}));
export default LumappsLangs;
