// eslint-disable-next-line import/prefer-default-export
export enum FileProvider {
  csv = 'csv',
  google = 'google',
  microsoft = 'microsoft',
}

export const fileProviderToDisplayName = {
  csv: 'Csv',
  google: 'Google Drive',
  microsoft: 'Microsoft Drive'
}
