import React from 'react';

import { useTheme } from 'Theme';
import { useStyles, useResizeObserver, useStoreState } from 'hooks';

import DashboardTopBar from './DashboardTopBar';
import DashboardSideNavigation from './DashboardSideNavigation';
import DashboardMainContent from './DashboardMainContent';

import './Dashboard.css';

const Dashboard = () => {
  const { theme } = useTheme();

  const { setWidth } = useStyles();
  const { ref } = useResizeObserver({
    onResize: ({ width }: any) => {
      setWidth(width);
    },
  });

  const user = useStoreState((state) => state.user);

  return (
    <div ref={ref} className={theme.backgroundClass}>
      <DashboardTopBar />
      <div style={{ display: 'flex', height: '100%' }}>
        <DashboardSideNavigation />
        <DashboardMainContent />
      </div>
    </div>
  );
};

export default Dashboard;
