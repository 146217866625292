/* eslint-disable radix */
import React from 'react';

import { FormattedDate, FormattedMessage } from 'react-intl';

import {
  Icon, List, ListItem, ListSubheader,
} from '@lumx/react';
import { mdiClock } from '@lumx/icons';

import { useStoreState } from 'hooks';

import { Divider } from 'components/LumxWrappers';

const PlanificationSummary = () => {
  const { nextExecutionDate, maxExecutionsNb, interval } = useStoreState(
    (state) => state.workflow.export.planification.planificationParams,
  );

  return (
    <>
      <List>
        {/* Interval */}
        <ListSubheader>
          <FormattedMessage id="EXPORT_PLANIF_PARAMS_INTERVAL" />
        </ListSubheader>
        <ListItem>
          <FormattedMessage id="EVERY" />
          {' '}
          {interval}
          {' '}
          <FormattedMessage id="DAY" />
          {parseInt(interval) > 1 ? 's' : ''}
        </ListItem>

        {/* Next exec date */}
        <ListSubheader>
          <FormattedMessage id="EXPORT_PLANIF_PARAMS_FIRST_EXEC_DATE" />
        </ListSubheader>
        <ListItem before={<Icon icon={mdiClock} />}>
          <FormattedMessage id="STARTS_AT" />
          {' '}
          <FormattedDate value={nextExecutionDate} />
        </ListItem>

        {/* Executions nb limit */}
        <ListSubheader>
          <FormattedMessage id="EXPORT_PLANIF_PARAMS_LIMIT_EXECUTIONS_NB" />
        </ListSubheader>
        <ListItem>
          {parseInt(maxExecutionsNb) === 0 && (
            <FormattedMessage id="UNLIMITED" />
          )}
          {parseInt(maxExecutionsNb) !== 0 && (
            <>
              <FormattedMessage id="LIMITED_TO" />
              {' '}
              {maxExecutionsNb}
              {' '}
              <FormattedMessage id="TIMES" />
            </>
          )}
        </ListItem>
      </List>
      <Divider />
    </>
  );
};

export default PlanificationSummary;
