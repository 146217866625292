/* eslint-disable no-param-reassign */
import { action, Action, actionOn, ActionOn, Thunk, thunk } from 'easy-peasy';
import { IStore, IInjections } from 'store';
import BaseExportStore from './baseExportStore';
import { IExportFileInfos } from 'models/dtos';
import { FileProvider } from 'models/provider';

interface IExportToInfos {
  destination: FileProvider;
  name?: string;
  folderID?: string;
  driveId?: string;
  iconUrl?: string;
  embedUrl?: string;
}

export interface CommonExportStore extends BaseExportStore {
  exportToInfos: IExportToInfos;
  setExportToInfos: Action<CommonExportStore, IExportToInfos>;

  startPlanifiedExportWorkflow: Thunk<CommonExportStore, void, any, IStore>;
  startNormalExportWorkflow: Thunk<CommonExportStore, void, any, IStore>;

  prepareExportToInfos: Thunk<
    CommonExportStore,
    void,
    IInjections,
    IStore,
    IExportFileInfos
  >;

  endWorkflow: ActionOn<CommonExportStore, IStore>;
}

const commonExportStore: CommonExportStore = {
  exportToInfos: {
    destination: FileProvider.csv,
  },
  setExportToInfos: action((state, infos) => {
    state.exportToInfos = infos;
  }),

  startPlanifiedExportWorkflow: thunk((a, p, { getStoreActions }) => {
    getStoreActions().workflow.export.planification.setIsPlanified(true);
    getStoreActions().workflow.modal.nextStep();
  }),
  startNormalExportWorkflow: thunk((a, p, { getStoreActions }) => {
    getStoreActions().workflow.export.planification.setIsPlanified(false);
    getStoreActions().workflow.modal.nextStep();
  }),

  prepareExportToInfos: thunk((a, p, { getState }) => {
    const state = getState();
    const fileInfos: IExportFileInfos = {
      destination: state.exportToInfos.destination,
      folderID: state.exportToInfos?.folderID || '',
      driveID: state.exportToInfos?.driveId,
    };
    return fileInfos;
  }),

  validateStep: thunk(() => {
    return true;
  }),
  endWorkflow: actionOn(
    (_, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.exportToInfos = {
        destination: FileProvider.csv,
      };
    }
  ),
};

export default commonExportStore;
