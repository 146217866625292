import React, { FC, useState, useEffect, useCallback } from 'react'

import { mdiClose, mdiDownload } from '@lumx/icons'

import {
	Chip,
	Checkbox,
	Icon,
	Size,
	Table,
	TableCell,
	TableCellVariant,
	TableHeader,
	TableRow,
	ThOrder,
	ThScope,
	Theme,
	Toolbar,
	Emphasis,
	IconButton
} from '@lumx/react'

import DataTableBody from './DataTableBody'
import TablePagination, { ITransalatedPaginationText } from './TablePagination'
import ViewColumns from './ViewColumns'
import Filter from './Filter'
import SearchBar from './SearchBar'

// For the Download to csv
//@ts-ignore
import { buildURI } from './toCsv'
import DataTableSmall from './DataTableSmall'
import useResizeObserver from '../../hooks/useResizeObserver'

export enum SelectableRowsOptions {
	multiple = 'multiple',
	single = 'single',
	none = 'none'
}
export interface ITableHeader {
	label: string
	name: string
	searchable?: boolean
	isSortable?: boolean
	scope?: ThScope
	download?: boolean
	customBodyRender?: (
		cellValue: any,
		rowIndex: number,
		headerIndex: number,
		row: any
	) => React.ReactNode | string
}
interface DataTableOptions {
	// Whether you want pagination or not
	paginated?: boolean
	// Not used at the moment
	serverSide?: boolean

	count?: number

	// Number of rows to display for one page
	rowsPerPage?: number

	// Choices available to change dynamicaly
	// the number of row per page
	rowsPerPageOptions?: Array<number>
	
	// Whether to display the tooltip allowing
	// the user to display or not some columns 
	// in the base toolbar
	viewColumns?: boolean

	// Whether to display the tooltip allowing
	// the user to filter table
	filter?: boolean

	// Whether to display the search bar
	search?: boolean

	// Whether to allow sorting of table columns
	sort?: boolean

	// Whether to display the download to csv button
	download?: boolean

	// 
	selectableRows?: SelectableRowsOptions

	// A custom action toolbar you provide. This is the one displayed
	// when one or several lines are selected
	actionToolbar?: (
		selectedRows: Array<any>,
		displayData: Array<any>,
		setSelectedRows: (selectedRows: Array<any>) => void,
		theme: Theme
	) => React.Component

	// A custom footer toolbar you provide. This is the one
	// displayed at the bottom right corner (pagination)
	footerToolbar?: (
		count: number,
		page: number,
		rowsPerPage: number,
		changeRowsPerPage: (rowsPerPage: number) => void,
		changePage: (page: number) => void,
		theme: Theme
	) => React.Component

	// A custom toolbar you provide. This is the one displayed
	// when nothing is selected
	customToolbar?: (
		tableData: Array<any>,
		tableHeaders: Array<ITableHeader>,
		displayedHeaders: Array<ITableHeader>,
		setDisplayedHeaders: (headers: Array<ITableHeader>) => void
	) => React.Component

	// more iconbuttons to add to the existing toolbar
	toolbarAdditionalElements?: React.ReactNode[]

	customRowRender?: (data: any, index: number) => React.ReactNode

	// A callback to allow action on row selection
	onRowSelected?: (selectedRows: Array<any>) => void

	// A callback to allow action on sort change
	onColumnSortChange?: (changedColumn: string, direction: string) => void

	// A filler you provide that will be displayed when there is no data in the table.
	noDataFiller?: React.ReactNode | string

	topTableBorder?: Boolean
	bottomTableBorder?: Boolean
		
	translatedPaginationText?: ITransalatedPaginationText
	filterButtonTitle?: any

	mandatoryFilters?: Array<any> | undefined
}

export interface DataTableProps {
	theme?: Theme
	title?: React.ReactNode | string
	options?: DataTableOptions
	tableData: Array<any>
	tableHeaders: Array<ITableHeader>
	onSearchCallBack?: (searchText: string, folderId?: string | null) => Promise<void>
}

export const DataTable: FC<DataTableProps> = ({
	theme = Theme.light,
	title = undefined,
	options: {
		onRowSelected,
		actionToolbar,
		footerToolbar,
		paginated,
		rowsPerPage: rpp,
		rowsPerPageOptions,
		viewColumns,
		filter,
		search,
		sort,
		download,
		serverSide,
		onColumnSortChange,
		customRowRender,
		selectableRows,
		noDataFiller,
		topTableBorder,
		bottomTableBorder,
		customToolbar,
		toolbarAdditionalElements,
		translatedPaginationText,
		filterButtonTitle,
		mandatoryFilters,
	} = {
		onRowSelected: undefined,
		actionToolbar: undefined,
		footerToolbar: undefined,
		paginated: false,
		rpp: null,
		rowsPerPageOptions: [10, 25, 50],
		viewColumns: false,
		filter: false,
		search: false,
		sort: false,
		download: false,
		serverSide: false,
		onColumnSortChange: undefined,
		customRowRender: undefined,
		selectableRows: SelectableRowsOptions.multiple,
		noDataFiller: undefined,
		topTableBorder: false,
		bottomTableBorder: false,
		customToolbar: undefined,
		toolbarAdditionalElements: [],
		translatedPaginationText: undefined,
		filterButtonTitle: 'Filter Table',
		mandatoryFilters: undefined,
	},
	tableData,
	tableHeaders,
	onSearchCallBack
}) => {
	selectableRows = selectableRows || SelectableRowsOptions.multiple
	toolbarAdditionalElements = toolbarAdditionalElements || toolbarAdditionalElements
	// if row per page has been passed, we cannot use the interface to change it.
	const canChangeRpp = !rpp
	rpp = rpp || 10
	rowsPerPageOptions = rowsPerPageOptions || [10, 25, 50]
	theme = theme || Theme.light

	const [displayedHeaders, setDisplayedHeaders] = useState<Array<ITableHeader>>(
		tableHeaders
	)
	const [displayData, setDisplayData] = useState<Array<any>>([...tableData])
	const [filterData, setFilterData] = useState<Array<any>>([])
	const [selectedRows, setSelectedRows] = useState<Array<any>>([])
	const [width, setWidth] = useState<number>()

	const [filters, setFilters] = useState<any>([])

	const [page, setPage] = useState<number>(1)
	const [rowsPerPage, setRowsPerPage] = useState<number>(rpp)

	const [count, setCount] = useState(tableData.length)

	const { ref } = useResizeObserver({
		onResize: ({ width }: any) => {
			setWidth(width);
		},
	});

	/*
	 * Handle setting correctly the table data
	 * when filtering/searching
	 */
	const setCorrectData = useCallback(
		(resetPage: boolean) => {
			let sourceData = [...tableData]

			if (filterData && filterData.length > 0) {
				sourceData = [...filterData]
				if (resetPage && !serverSide) {
					setPage(1)
				}
			}

			if (mandatoryFilters && mandatoryFilters.length > 0) {
				let filteredResults = [...sourceData]
				mandatoryFilters.forEach((filter: any) => {
					filteredResults = [
						...filteredResults.filter((el: any) =>
							el[filter.headerName] === filter.value
						)
					]
				})
				sourceData = [...filteredResults]
				if (resetPage && !serverSide) {
					setPage(1)
				}
			}

			setDisplayData(
				sourceData.slice((page - 1) * rowsPerPage, page * rowsPerPage)
			)
			setCount(sourceData.length)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			filterData,
			tableData,
			page,
			rowsPerPage,
			setCount,
			setDisplayData,
			setPage,
			serverSide
		]
	)

	/*
	 * Handle what happend when the left side checkbox is clicked
	 */
	const handleClickCheckbox = (checked: boolean, row: any) => {
		var index = selectedRows.indexOf(row)
		if (selectableRows === SelectableRowsOptions.multiple) {
			if (checked) {
				setSelectedRows([...selectedRows, row])
			} else {
				const array = [...selectedRows]
				if (index !== -1) {
					array.splice(index, 1)
					setSelectedRows(array)
				}
			}
		} else if (selectableRows === SelectableRowsOptions.single) {
			if (checked) {
				setSelectedRows([row])
			} else {
				setSelectedRows([])
			}
		}
	}

	/*
	 * If a user give a onRowSelected function
	 * call it each time selectedRows is updated
	 */
	useEffect(() => {
		if (onRowSelected) {
			onRowSelected(selectedRows)
		}
	}, [selectedRows, onRowSelected])

	/*
	 * Pagination handling
	 */

	const changeRowsPerPage = (_rowsPerPage: number) => {
		if (_rowsPerPage < 0) {
			return
		}
		setRowsPerPage(_rowsPerPage)
	}

	const changePage = (_page: number) => {
		if (_page <= 0 || _page > Math.ceil(count / rowsPerPage)) {
			return
		}
		setPage(_page)
	}

	useEffect(() => {
		setCorrectData(false)
	}, [page, rowsPerPage, setCorrectData])

	useEffect(() => {
		if (rpp) {
			setRowsPerPage(rpp);
		}
	}, [rpp])

	/*
	 * Select all
	 */
	const [allRowsSelected, setAllRowsSelected] = useState(false)
	const handleAllRowsSelected = () => {
		const newVal = !allRowsSelected
		setAllRowsSelected(newVal)
		if (newVal) {
			const newData = [...displayData]
				.slice((page - 1) * rowsPerPage, page * rowsPerPage)
			setSelectedRows(newData)
		} else {
			setSelectedRows([])
		}
	}

	/*
	 * Search
	 */
	const onSearch = (searchText: string) => {
		if (onSearchCallBack) {
			onSearchCallBack(searchText)
		}
		if (searchText && searchText.length) {
			const searchResults = tableData.filter((elem: any) => {
				for (let field of tableHeaders) {
					if (
						field.searchable &&
						elem[field.name] &&
						elem[field.name].toString().includes(searchText)
					) {
						return true
					}
				}
				return false
			})
			setFilterData(searchResults)
		} else {
			setFilterData([])
		}
		setCorrectData(true)
	}

	/*
	 * Filter
	 */
	const onFilter = (filters: any) => {
		setFilters(filters)

		if (filters && filters.length > 0) {
			let filterResults = [...tableData]
			filters.forEach((filter: any) => {
				filterResults = [
					...filterResults.filter((el: any) =>
						filter.filter === 'ALL'
							? true
							: el[filter.headerName].toString() === filter.filter
					)
				]
			})
			setFilterData([...filterResults])
		} else {
			setFilterData([])
		}
		setCorrectData(true)
	}

	const dismissFilter = (filter: any) => {
		onFilter([...filters.filter((el: any) => el !== filter)])
	}

	/*
	 * Sort
	 */

	/*
	 * Function to sort alphabetically an array of objects by some specific key.
	 *
	 * @param {String} property Key of the object to sort.
	 */
	const dynamicSort = (property: string, sortOrder: string) => {
		return (a: any, b: any) => {
			if (sortOrder === 'asc') {
				return b[property] < a[property] ? 1 : -1
			} else {
				return a[property] > b[property] ? -1 : 1
			}
		}
	}

	const handleSort = useCallback(
		(header) => {
			const sortOrder =
				header.sortOrder === ThOrder.asc ? ThOrder.desc : ThOrder.asc
			setDisplayedHeaders(
				displayedHeaders.map((h: any) => ({
					...h,
					sortOrder: h.name === header.name ? sortOrder : null
				}))
			)
			setDisplayData([...displayData.sort(dynamicSort(header.name, sortOrder))])
			if (onColumnSortChange) {
				onColumnSortChange(header.name, sortOrder)
			}
		},
		[displayedHeaders, displayData, onColumnSortChange]
	)

	/*
	 * Download Csv
	 */

	const handleDownload = () => {
		let csvData: any = new Set()
		tableData.forEach((row: any) => {
			tableHeaders.forEach((header: any) => {
				if (header.download && row[header.name]) {
					csvData.add(row)
				}
			})
		})
		csvData = [...csvData]
		const csvHeaders = tableHeaders
			.map((header: any) => (header.download ? header.name : false))
			.filter(Boolean)
		const csvUrl = buildURI(csvData, false, csvHeaders, ',', '')
		const a = document.createElement('a')
		a.download = 'file'
		a.href = csvUrl || ''
		a.click()
	}

	const baseActionBarDisplayed = !selectedRows || selectedRows.length === 0 || !actionToolbar
	const isSelectable =
		selectableRows === SelectableRowsOptions.multiple ||
		selectableRows === SelectableRowsOptions.single
	const hasActiveToolbar = search || download || filter || toolbarAdditionalElements

	const activeToolbar = (
		<>
			{search && <SearchBar theme={theme} onSearch={onSearch} />}
			{download && (
				<IconButton
					theme={theme}
					title='Download CSV'
					icon={mdiDownload}
					onClick={handleDownload}
					emphasis={Emphasis.low}
				/>
			)}
			{!customRowRender && viewColumns && (
				<ViewColumns
					theme={theme}
					tableHeaders={tableHeaders}
					setDisplayedHeaders={setDisplayedHeaders}
					displayedHeaders={displayedHeaders}
				/>
			)}
			{filter && (
				<Filter
					theme={theme}
					tableHeaders={tableHeaders}
					tableData={tableData}
					onFilter={onFilter}
					filters={filters}
					filterButtonTitle={filterButtonTitle}
				/>
			)}
			{toolbarAdditionalElements && toolbarAdditionalElements.map((e: React.ReactNode) => {
				return e
			})}
		</>
	)
	const topAndBottomBorders = theme === Theme.light ? '1px solid rgba(0, 0, 0, 0.12)' : '1px solid rgba(255, 255, 255, 0.2)';
	return (
		<>
			{/* Table Actions */}
			<div ref={ref}>
				{/* Action Bar when rows are selected */}
				{isSelectable && selectedRows.length > 0 && actionToolbar && (
					<div
						style={{
							boxShadow:
								'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
							borderRadius: '4px'
						}}
					>
						<Toolbar
							before={`${selectedRows.length} row(s) selected`}
							after={actionToolbar(selectedRows, tableData, setSelectedRows, theme)}
						/>
					</div>
				)}

				{/* Base action bar (no row selected) */}
				{baseActionBarDisplayed && (
					<>
						{customToolbar ? (
							<>
								{customToolbar(
									tableData,
									tableHeaders,
									displayedHeaders,
									setDisplayedHeaders
								)}
							</>
						) : (
							hasActiveToolbar &&
							<Toolbar
								before={title}
								after={
									<div
										style={{
											display: 'flex',
											alignContent: 'center',
											alignItems: 'center'
										}}
									>
										{activeToolbar}
									</div>
								}
								style={{alignItems: "center"}}
							/>
						)}
					</>
				)}
			</div>

			<div style={{ display: 'flex', alignItems: 'center' }}>
				{filters.map((filter: any, idx: number) => (
					<Chip
						key={idx}
						theme={theme}
						size={Size.s}
						after={<Icon icon={mdiClose} size={Size.xxs} />}
						onClick={() => {
							dismissFilter(filter)
						}}
					>
						{filter.filter}
					</Chip>
				))}
			</div>

			{/* Table */}
			{(customRowRender || (width && width > 950)) && (
				<Table hasBefore={isSelectable} hasDividers theme={theme} style={{
					borderTop: topTableBorder ? topAndBottomBorders : "none",
					borderBottom: bottomTableBorder ? topAndBottomBorders : "none",
				}}>
					{displayedHeaders.length > 0 && (
					<TableHeader>
						<TableRow>
							{/* Checkbox Column */}
							{isSelectable && (
								<TableCell
									key={-1}
									isSortable={false}
									scope={ThScope.col}
									variant={TableCellVariant.head}
								>
									{selectableRows !== SelectableRowsOptions.single && (
										<Checkbox
											theme={theme}
											value={allRowsSelected}
											onChange={handleAllRowsSelected}
										/>
									)}
								</TableCell>
							)}

							{/* Other Columns */}
							{displayedHeaders.map((header: any, index: number) => (
								<TableCell
									key={index}
									icon={header.icon}
									scope={header.scope}
									variant={TableCellVariant.head}
									sortOrder={sort ? header.sortOrder : undefined}
									isSortable={sort ? header.isSortable : false}
									onHeaderClick={sort ? () => handleSort(header) : undefined}
								>
									{header.label}
								</TableCell>
							))}
						</TableRow>
					</TableHeader>)}

					{displayData && displayData.length > 0 && (
						<>
							<DataTableBody
								theme={theme}
								tableData={displayData}
								tableHeaders={displayedHeaders}
								selectedRows={selectedRows}
								handleClickCheckbox={handleClickCheckbox}
								customRowRender={customRowRender}
								selectableRows={selectableRows}
							/>
						</>
					)}
				</Table>
			)}

			{!customRowRender && width && width <= 950 && displayData && displayData.length > 0 && (
				<DataTableSmall
					theme={theme}
					tableData={displayData}
					tableHeaders={displayedHeaders}
					isSelectable={isSelectable}
					selectedRows={selectedRows}
					handleClickCheckbox={handleClickCheckbox}
				/>
			)}

			{displayData && displayData.length === 0 && noDataFiller }

			{/* Footer Bar */}
			{displayData && displayData.length > 0 && (
				<Toolbar
					after={
						paginated && !footerToolbar ? (
							<TablePagination
								theme={theme}
								count={count}
								page={page}
								rowsPerPage={rowsPerPage}
								changeRowsPerPage={changeRowsPerPage}
								changePage={changePage}
								rowsPerPageOptions={rowsPerPageOptions}
								translatedPaginationText={translatedPaginationText}
								canChangeRpp={canChangeRpp}
							/>
						) : footerToolbar ? (
							footerToolbar(
								count,
								page,
								rowsPerPage,
								changeRowsPerPage,
								changePage,
								theme
							)
						) : (
							<></>
						)
					}
				/>
			)}
		</>
	)
}

export default DataTable
