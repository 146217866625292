import React, { useEffect } from 'react';
import {
  Select,
  List,
  ListItem,
  Progress,
  ProgressVariant,
  Size,
  ListSubheader,
  TextField,
} from '@lumx/react';
import { useBooleanState } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { mdiMagnify } from '@lumx/icons';

import './SimpleSelectWrapper.css';
import useDebounce from 'hooks/useDebouce';

export interface Choice {
  displayName: React.ReactNode | string;
  value: any;
  name: any;
}

interface SimpleSelectProps {
  choices: Array<Choice> | undefined;
  isRequired?: boolean;
  value: any;
  setValue: (value: any) => void;
  label: React.ReactNode | string;
  isLoading?: boolean;
  withSearch?: boolean;
  searchFunc?: (value: any) => void
  helper?: React.ReactNode | string;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  choices,
  isRequired = false,
  value,
  setValue,
  label,
  isLoading = false,
  withSearch = true,
  helper = '',
  searchFunc = undefined,
}: SimpleSelectProps) => {
  const [isOpen, closeSelect, openSelect, toggleSelect] = useBooleanState(
    false,
  );

  const clearSelectedvalues = (event: any) => {
    event.stopPropagation();
    setValue(undefined);
  };

  const onItemSelectedHandler = (item: any) => {
    closeSelect();
    if (item) {
      setValue(item);
    }
  };

  const [filterValue, setFilterValue] = React.useState('');
  const handleSearch = () => {
    if(searchFunc) {
      searchFunc(filterValue)
      return
    }
    const filtered: any = choices?.filter((choice: Choice) => choice?.name
      .replace(' ', '')
      .toLowerCase()
      .includes(filterValue.replace(' ', '').toLowerCase()));
    setFilteredChoices(filtered || undefined);
  };

  useEffect(() => {
  if(!filteredChoices || searchFunc) {
      setFilteredChoices(choices)
    }
  }, [choices])

  const [,] = useDebounce(
		() => {
			handleSearch()
		},
		500,
		[filterValue]
	)
  const [filteredChoices, setFilteredChoices] = React.useState<
  Array<any> | undefined
  >(undefined);


  return (
    <Select
      label={label as any}
      value={value}
      isOpen={isOpen}
      onClear={clearSelectedvalues}
      isRequired={isRequired}
      onInputClick={toggleSelect}
      onDropdownClose={closeSelect}
      helper={helper as any}
    >
      <List isClickable={isOpen}>
        {withSearch && (
          <ListSubheader className="toolbox-select-wrapper-search-list-subheader">
            <TextField
              style={{ width: '100%', padding: 0 }}
              value={filterValue}
              onChange={setFilterValue}
              icon={mdiMagnify}
              size={Size.tiny}
            />
          </ListSubheader>
        )}
        {!isLoading
          && filteredChoices
          && filteredChoices.length > 0
          && filteredChoices.map((choice: Choice, index: number) => (
            <ListItem
              isSelected={choice.value === value}
              onItemSelected={() => onItemSelectedHandler(choice)}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {choice.displayName}
            </ListItem>
          ))}
      </List>
      {!isLoading && (!choices || choices.length === 0) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormattedMessage id="NO_DATA" />
        </div>
      )}
      {isLoading && <Progress variant={ProgressVariant.linear} />}
    </Select>
  );
};

export default SimpleSelect;
