/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';

import { useIntl } from 'react-intl';

import { mdiClose, mdiDownload } from '@lumx/icons';

import {
  Chip,
  Checkbox,
  Icon,
  Size,
  Table,
  TableCell,
  TableCellVariant,
  TableHeader,
  TableRow,
  ThOrder,
  ThScope,
  Theme,
  Toolbar,
  Emphasis,
  IconButton,
} from '@lumx/react';

import orderBy from 'lodash/orderBy';

import DataTableBody from './DataTableBody';
import TablePagination from './TablePagination';
import ViewColumns from './ViewColumns';
import Filter from './Filter';
import SearchBar from './SearchBar';

// For the Download to csv
// @ts-ignore
import { buildURI } from './toCsv';
import DataTableSmall from './DataTableSmall';
import useWindowSize from '../../hooks/useWindowSize';
import {
  DataTableHeader,
  DataTableOptions,
  SelectableRowsOptions,
} from './models';

export interface DataTableProps {
  theme?: Theme;
  title?: string;
  options?: DataTableOptions;
  tableData: Array<any>;
  tableHeaders: Array<DataTableHeader>;
}

export const DataTable = ({
  theme = Theme.light,
  title = undefined,
  options: {
    onRowSelected,
    baseToolbar,
    actionToolbar,
    footerToolbar,
    paginated,
    rowsPerPage: rpp,
    rowsPerPageOptions,
    viewColumns,
    filter,
    search,
    sort,
    download,
    onColumnSortChange,
    customRowRender,
    selectableRows,
    noDataFiller,
    customToolbar,
    translatedPaginationText,
    filterButtonTitle,
    defaultSortHeaderName,
    buttonTooltips,
  } = {
    onRowSelected: undefined,
    baseToolbar: undefined,
    actionToolbar: undefined,
    footerToolbar: undefined,
    paginated: false,
    rpp: 10,
    rowsPerPageOptions: [10, 25, 50],
    viewColumns: true,
    filter: true,
    search: true,
    sort: true,
    download: false,
    onColumnSortChange: undefined,
    customRowRender: undefined,
    selectableRows: SelectableRowsOptions.multiple,
    noDataFiller: undefined,
    customToolbar: undefined,
    translatedPaginationText: undefined,
    defaultSortHeaderName: undefined,
    buttonTooltips: undefined,
  },
  tableData: originalData,
  tableHeaders,
}: DataTableProps) => {
  selectableRows = selectableRows || SelectableRowsOptions.multiple;
  rpp = rpp || 10;
  rowsPerPageOptions = rowsPerPageOptions || [10, 25, 50];
  theme = theme || Theme.light;

  const [displayedHeaders, setDisplayedHeaders] = useState<
    Array<DataTableHeader>
  >(tableHeaders);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [displayData, setDisplayData] = useState<Array<any>>([]);
  const [filterData, setFilterData] = useState<Array<any>>([]);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const [filters, setFilters] = useState<any>([]);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rpp);

  const [count, setCount] = useState(tableData.length);

  const { width } = useWindowSize();

  const [currentSortingHeader, setCurrentSortingHeader] = useState(
    defaultSortHeaderName || tableHeaders[0].name
  );
  const [currentSortingOrder, setCurrentSortingOrder] = useState(ThOrder.desc);
  
  const intl = useIntl();

  /*
   * Handle setting correctly the table data
   * when filtering/searching
   */
  const setCorrectData = useCallback(
    (resetPage: boolean) => {
      let sourceData: any = [...tableData];

      // Filter
      if (filterData && filterData.length > 0) {
        sourceData = [...filterData];
        if (resetPage) {
          setPage(1);
        }
      }

      // Correct page
      const displayData = sourceData.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );

      setDisplayData(displayData);
      setCount(sourceData.length);
    },
    [
      filterData,
      tableData,
      page,
      rowsPerPage,
      setCount,
      setDisplayData,
      setPage,
    ]
  );

  useEffect(() => {
    setCorrectData(false);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setTableData(
      orderBy(originalData, currentSortingHeader, currentSortingOrder)
    );
  }, [originalData]);

  useEffect(() => {
    setCorrectData(false);
  }, [tableData, filterData]);

  /*
   * Handle what happend when the left side checkbox is clicked
   */
  const handleClickCheckbox = (checked: boolean, row: any) => {
    const index = selectedRows.indexOf(row.id);
    if (selectableRows === SelectableRowsOptions.multiple) {
      if (checked) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        const array = [...selectedRows];
        if (index !== -1) {
          array.splice(index, 1);
          setSelectedRows(array);
        }
      }
    } else if (selectableRows === SelectableRowsOptions.single) {
      if (selectedRows.length === 0) {
        if (checked) {
          setSelectedRows([row.id]);
        }
      } else if (!checked && selectedRows[0] === row.id) {
        setSelectedRows([]);
      }
    }
  };

  /*
   * If a user give a onRowSelected function
   * call it each time selectedRows is updated
   */
  useEffect(() => {
    if (onRowSelected) {
      onRowSelected(selectedRows);
    }
  }, [selectedRows, onRowSelected]);

  /*
   * Pagination handling
   */

  const changeRowsPerPage = (_rowsPerPage: number) => {
    if (_rowsPerPage < 0) {
      return;
    }
    setRowsPerPage(_rowsPerPage);
  };

  const changePage = (_page: number) => {
    if (_page <= 0 || _page > Math.ceil(count / rowsPerPage)) {
      return;
    }
    setPage(_page);
  };

  /*
   * Select all
   */
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const handleAllRowsSelected = () => {
    const newVal = !allRowsSelected;
    setAllRowsSelected(newVal);
    if (newVal) {
      const newData = [...displayData].map((el: any) => el.id);
      setSelectedRows(newData);
    } else {
      setSelectedRows([]);
    }
  };

  /*
   * Search
   */
  const onSearch = (searchText: string) => {
    if (searchText && searchText.length) {
      const searchResults = tableData.filter((elem: any) => {
        for (const field of tableHeaders) {
          if (
            field.searchable &&
            elem[field.name] &&
            elem[field.name].toString().includes(searchText)
          ) {
            return true;
          }
        }
        return false;
      });
      setFilterData(searchResults);
    } else {
      setFilterData([]);
    }
  };

  /*
   * Filter
   */
  const onFilter = (filters: any) => {
    setFilters(filters);

    if (filters && filters.length > 0) {
      let filterResults = [...tableData];
      filters.forEach((filter: any) => {
        filterResults = [
          ...filterResults.filter((el: any) =>
            filter.filter === 'ALL'
              ? true
              : el[filter.headerName].toString() === filter.filter
          ),
        ];
      });
      setFilterData([...filterResults]);
    } else {
      setFilterData([]);
    }
  };

  const dismissFilter = (filter: any) => {
    onFilter([...filters.filter((el: any) => el !== filter)]);
  };

  /*
   * Sort
   */

  const handleSort = useCallback(
    (header) => {
      const sortHeader = header.name;
      setCurrentSortingHeader(sortHeader);

      const sortOrder =
        header.sortOrder === ThOrder.asc ? ThOrder.desc : ThOrder.asc;
      setCurrentSortingOrder(sortOrder);

      setDisplayedHeaders(
        displayedHeaders.map((h: any) => ({
          ...h,
          sortOrder: h.name === header.name ? sortOrder : null,
        }))
      );

      const sortedData = orderBy(tableData, sortHeader, sortOrder);
      setTableData(sortedData);

      // Custom external hook
      if (onColumnSortChange) {
        onColumnSortChange(header.name, sortOrder);
      }
    },
    [displayedHeaders, displayData, onColumnSortChange]
  );

  /*
   * Download Csv
   */

  const handleDownload = () => {
    let csvData: any = new Set();
    tableData.forEach((row: any) => {
      tableHeaders.forEach((header: any) => {
        if (header.download && row[header.name]) {
          csvData.add(row);
        }
      });
    });
    csvData = [...csvData];
    const csvHeaders = tableHeaders
      .map((header: any) => (header.download ? header.name : false))
      .filter(Boolean);
    const csvUrl = buildURI(csvData, false, csvHeaders, ',', '');
    const a = document.createElement('a');
    a.download = 'file';
    a.href = csvUrl || '';
    a.click();
  };

  const baseActionBarDisplayed = !selectedRows || selectedRows.length === 0;
  const isSelectable =
    selectableRows === SelectableRowsOptions.multiple ||
    selectableRows === SelectableRowsOptions.single;

  const activeToolbar = (
    <>
      <Toolbar
        after={
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            {search && <SearchBar theme={theme} onSearch={onSearch} />}
            {download && (
              <IconButton
                theme={theme}
                title="Download CSV"
                icon={mdiDownload}
                onClick={handleDownload}
                emphasis={Emphasis.low}
              />
            )}
            {!customRowRender && viewColumns && (
              <ViewColumns
                theme={theme}
                tableHeaders={tableHeaders}
                setDisplayedHeaders={setDisplayedHeaders}
                displayedHeaders={displayedHeaders}
                viewColumnsButtonTooltipLabel={
                  buttonTooltips?.viewColumnsButtonTooltipLabel
                }
              />
            )}
            {filter && (
              <Filter
                theme={theme}
                tableHeaders={tableHeaders}
                tableData={tableData}
                onFilter={onFilter}
                filters={filters}
                filterTableTooltipLabel={
                  buttonTooltips?.filterTableTooltipLabel
                }
              />
            )}
          </div>
        }
      />
    </>
  );
  return (
    <>
      {/* Table Actions */}
      <div>
        {/* Action Bar when rows are selected */}
        {selectedRows.length > 0 && (
          <div
            style={{
              boxShadow:
                '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
              borderRadius: '4px',
            }}
          >
            <Toolbar
              before={`${selectedRows.length} ${intl.formatMessage({
                id: 'ROWS_SELECTED',
              })}`}
              after={
                actionToolbar ? (
                  actionToolbar(selectedRows, tableData, setSelectedRows, theme)
                ) : baseToolbar ? (
                  baseToolbar(theme)
                ) : (
                  <>{activeToolbar}</>
                )
              }
            />
          </div>
        )}

        {/* Base action bar (no row selected) */}
        {baseActionBarDisplayed && (
          <>
            {customToolbar ? (
              <>
                {customToolbar(
                  tableData,
                  tableHeaders,
                  displayedHeaders,
                  setDisplayedHeaders
                )}
              </>
            ) : (
              <Toolbar
                before={title}
                after={
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {activeToolbar}
                  </div>
                }
              />
            )}
          </>
        )}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {filters.map((filter: any, idx: number) => (
          <Chip
            key={idx}
            theme={theme}
            size={Size.s}
            after={<Icon icon={mdiClose} size={Size.xxs} />}
            onClick={() => {
              dismissFilter(filter);
            }}
          >
            {filter.filter}
          </Chip>
        ))}
      </div>

      {/* Table */}
      {width && width > 950 && (
        <Table hasBefore={isSelectable} hasDividers theme={theme}>
          <TableHeader>
            <TableRow>
              {/* Checkbox Column */}
              {isSelectable && (
                <TableCell
                  key={-1}
                  isSortable={false}
                  scope={ThScope.col}
                  variant={TableCellVariant.head}
                >
                  <Checkbox
                    theme={theme}
                    disabled={selectableRows === SelectableRowsOptions.single}
                    value={allRowsSelected}
                    onChange={handleAllRowsSelected}
                  />
                </TableCell>
              )}

              {/* Other Columns */}
              {displayedHeaders.map((header: any, index: number) => (
                <TableCell
                  key={index}
                  icon={header.icon}
                  scope={header.scope}
                  variant={TableCellVariant.head}
                  sortOrder={sort ? header.sortOrder : undefined}
                  isSortable={sort ? header.isSortable : false}
                  onHeaderClick={sort ? () => handleSort(header) : undefined}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>

          {displayData && displayData.length > 0 && (
            <>
              <DataTableBody
                theme={theme}
                tableData={displayData}
                tableHeaders={displayedHeaders}
                selectedRows={selectedRows}
                handleClickCheckbox={handleClickCheckbox}
                customRowRender={customRowRender}
                selectableRows={selectableRows}
              />
            </>
          )}
        </Table>
      )}

      {width && width <= 950 && displayData && displayData.length > 0 && (
        <DataTableSmall
          theme={theme}
          tableData={displayData}
          tableHeaders={displayedHeaders}
          isSelectable={isSelectable}
          selectedRows={selectedRows}
          handleClickCheckbox={handleClickCheckbox}
        />
      )}

      {noDataFiller && displayData && displayData.length === 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {noDataFiller}
        </div>
      )}

      {/* Footer Bar */}
      {displayData && displayData.length > 0 && (
        <Toolbar
          after={
            paginated && !footerToolbar ? (
              <TablePagination
                theme={theme}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                changeRowsPerPage={changeRowsPerPage}
                changePage={changePage}
                rowsPerPageOptions={rowsPerPageOptions}
                translatedPaginationText={translatedPaginationText}
              />
            ) : footerToolbar ? (
              footerToolbar(
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                theme
              )
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
};

export default DataTable;
