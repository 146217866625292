import {
  List,
  ListItem,
  ListSubheader,
  RadioButton,
  RadioGroup,
} from '@lumx/react';
import {
  Divider,
  SimpleSelect,
  SubRadioButtonContentWrapper,
} from 'components/LumxWrappers';
import LumappsLangs from 'containers/LumappsLangs';
import { useStoreActions, useStoreState } from 'hooks';
import { DirectoryEntriesExportType, DirectoryExportType } from 'models/dtos';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DirectoriesExportSubparams = () => {
  const { directoryExportType, selectedSiteName } = useStoreState(
    (state) => state.workflow.export.directory
  );

  const {
    selectExportAllDirectoriesOrExportAllSiteDirectories,
    selectSite,
  } = useStoreActions((actions) => actions.workflow.export.directory);

  const userSites = useStoreState((state) =>
    state.user.userLumappsSites?.map((el: any) => ({
      value: el.id,
      displayName: el.name,
      name: el.name,
    }))
  );
  return (
    <RadioGroup>
      <RadioButton
        checked={
          directoryExportType === DirectoryExportType.exportAllDirectories
        }
        value={DirectoryExportType.exportAllDirectories}
        name={DirectoryExportType.exportAllDirectories}
        onChange={selectExportAllDirectoriesOrExportAllSiteDirectories}
        label={<FormattedMessage id="DIRECTORY_EXPORT_DIRECTORIES_ALL_SITES" />}
      />

      <RadioButton
        checked={
          directoryExportType === DirectoryExportType.exportSiteDirectories
        }
        value={DirectoryExportType.exportSiteDirectories}
        name={DirectoryExportType.exportSiteDirectories}
        onChange={selectExportAllDirectoriesOrExportAllSiteDirectories}
        label={<FormattedMessage id="DIRECTORY_EXPORT_SITE_DIRECTORIES" />}
      />
      {directoryExportType === DirectoryExportType.exportSiteDirectories && (
        <SubRadioButtonContentWrapper>
          <SimpleSelect
            choices={userSites}
            label={<FormattedMessage id="SELECT_SITE" />}
            value={selectedSiteName}
            setValue={selectSite}
          />
        </SubRadioButtonContentWrapper>
      )}
    </RadioGroup>
  );
};

const DirectoriesEntriesSubparams = () => {
  const {
    directoryEntriesExportType,
    selectedSiteName,
    selectedDirectoryName,
    loadingSiteDirectories,
  } = useStoreState((state) => state.workflow.export.directory);

  const {
    selectSite,
    selectDirectory,
    selectDirectoriesEntriesSubparams,
  } = useStoreActions((actions) => actions.workflow.export.directory);

  const siteDirectories = useStoreState((state) =>
    state.workflow.export.directory.siteDirectories?.map((dir: any) => ({
      value: dir.id,
      displayName: dir.name,
      name: dir.name,
    }))
  );
  const userSites = useStoreState((state) =>
    state.user.userLumappsSites?.map((el: any) => ({
      value: el.id,
      displayName: el.name,
      name: el.name,
    }))
  );
  return (
    <RadioGroup>
      {/* exportAllDirectoriesEntries */}
      <RadioButton
        checked={
          directoryEntriesExportType ===
          DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries
        }
        value={DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries}
        name={DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries}
        onChange={selectDirectoriesEntriesSubparams}
        label={
          <FormattedMessage id="DIRECTORY_EXPORT_ALL_DIRECTORIES_ENTRIES" />
        }
      />

      {/* exportAllSiteDirectoriesEntries */}
      <RadioButton
        checked={
          directoryEntriesExportType ===
          DirectoryEntriesExportType.exportAllSiteDirectoriesEntries
        }
        value={DirectoryEntriesExportType.exportAllSiteDirectoriesEntries}
        name={DirectoryEntriesExportType.exportAllSiteDirectoriesEntries}
        onChange={selectDirectoriesEntriesSubparams}
        label={
          <FormattedMessage id="DIRECTORY_EXPORT_ALL_SITE_DIRECTORIES_ENTRIES" />
        }
      />
      {directoryEntriesExportType ===
        DirectoryEntriesExportType.exportAllSiteDirectoriesEntries && (
        <SubRadioButtonContentWrapper>
          <SimpleSelect
            choices={userSites}
            label={<FormattedMessage id="SELECT_SITE" />}
            value={selectedSiteName}
            setValue={selectSite}
          />
        </SubRadioButtonContentWrapper>
      )}

      {/* exportAllSiteDirectoryEntries */}
      <RadioButton
        checked={
          directoryEntriesExportType ===
          DirectoryEntriesExportType.exportDirectoryEntries
        }
        value={DirectoryEntriesExportType.exportDirectoryEntries}
        name={DirectoryEntriesExportType.exportDirectoryEntries}
        onChange={selectDirectoriesEntriesSubparams}
        label={
          <FormattedMessage id="DIRECTORY_EXPORT_ALL_SITE_DIRECTORY_ENTRIES" />
        }
      />
      {directoryEntriesExportType ===
        DirectoryEntriesExportType.exportDirectoryEntries && (
        <SubRadioButtonContentWrapper>
          {/* Site select */}
          <SimpleSelect
            choices={userSites}
            label={<FormattedMessage id="SELECT_SITE" />}
            value={selectedSiteName}
            setValue={selectSite}
          />

          {/* Directory select */}
          {selectedSiteName && (
            <SimpleSelect
              choices={siteDirectories}
              label={<FormattedMessage id="SELECT_SITE_DIRECTORY" />}
              value={selectedDirectoryName}
              setValue={selectDirectory}
              isLoading={loadingSiteDirectories}
            />
          )}
        </SubRadioButtonContentWrapper>
      )}
    </RadioGroup>
  );
};

const DirectoryParams = () => {
  const { selectedLang, whatToExport } = useStoreState(
    (state) => state.workflow.export.directory
  );

  const {
    selectLang,
    selectExportDirectoriesOrExportDirectoriesEntries,
  } = useStoreActions((actions) => actions.workflow.export.directory);

  const langs: Array<any> = [...LumappsLangs];
  return (
    <>
      <List>
        <ListItem>
          <SimpleSelect
            choices={langs}
            value={selectedLang}
            setValue={selectLang}
            label={<FormattedMessage id="SELECT_EXPORT_LANG" />}
            helper={<FormattedMessage id="SELECT_EXPORT_LANG_HELPER" />}
          />
          <Divider />
        </ListItem>
        <ListSubheader>
          <FormattedMessage id="EXPORT_CHOICE" />
        </ListSubheader>
        <ListItem>
          <RadioGroup>
            <RadioButton
              checked={whatToExport === 'directories'}
              value="directories"
              name="exportDirectories"
              onChange={selectExportDirectoriesOrExportDirectoriesEntries}
              label={<FormattedMessage id="DIRECTORY_EXPORT_DIRECTORIES" />}
            />
            {whatToExport === 'directories' && (
              <SubRadioButtonContentWrapper>
                <DirectoriesExportSubparams />
              </SubRadioButtonContentWrapper>
            )}
            <RadioButton
              checked={whatToExport === 'directoryEntries'}
              value="directoryEntries"
              name="exportDirectoriesEntries"
              onChange={selectExportDirectoriesOrExportDirectoriesEntries}
              label={
                <FormattedMessage id="DIRECTORY_EXPORT_DIRECTORIES_ENTRIES" />
              }
            />
            {whatToExport === 'directoryEntries' && (
              <SubRadioButtonContentWrapper>
                <DirectoriesEntriesSubparams />
              </SubRadioButtonContentWrapper>
            )}
          </RadioGroup>
        </ListItem>
      </List>
    </>
  );
};

export default DirectoryParams;
