import modalStore, { ModalStore } from './modalStore';
import exportStore, { ExportStore } from './export';

import importStore, { ImportStore } from './import';

export interface WorkflowStore {
  modal: ModalStore;
  export: ExportStore;
  import: ImportStore;
}
const workflowStore: WorkflowStore = {
  modal: modalStore,
  export: exportStore,
  import: importStore,
};

export default workflowStore;
