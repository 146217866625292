import { AxiosInstance, AxiosResponse } from 'axios';

export interface IPlanificationService {
  deletePlanifications: (planifIds: Array<string>) => Promise<AxiosResponse>;
  listPlanifications: (
    category: string,
    platformID: string
  ) => Promise<AxiosResponse>;
}

export const planificationServiceFactory = (
  client: AxiosInstance,
): IPlanificationService => {
  const planificationService: IPlanificationService = {
    deletePlanifications: async (planifIds: Array<string>) => {
      const params: any = { planificationsIds: planifIds.join(',') };
      return client.delete('/api/planifications', { params });
    },
    listPlanifications: async (category: string, platformID: string) => {
      const params: any = { category, platformID };
      return client.get('/api/planifications', { params });
    },
  };
  return planificationService;
};
