/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

interface MicrosoftChooserProps {
  children: React.ReactNode;
  clientId: string;
  accessToken: string;
  onFilesPicked: (pickedFiles: Array<any>) => void;
  onCancel?: () => void;
  onError?: (error: any) => void;
  viewType?: string;
  filter?: string;
  action?: string;
  multiSelect?: boolean;
}
const MicrosoftChooser: React.FC<MicrosoftChooserProps> = ({
  children,
  clientId,
  accessToken,
  onFilesPicked,
  onCancel: _onCancel,
  onError: _onError,
  viewType = 'all',
  filter = undefined,
  action = 'query',
  multiSelect = false,
}: MicrosoftChooserProps) => {
  const [isOpen, SetIsOpen] = useState(false);

  const onSuccess = (pickedFiles: Array<any>) => {
    onFilesPicked(pickedFiles);
    SetIsOpen(false);
  };

  const onCancel = () => {
    if (_onCancel) {
      _onCancel();
    }
    SetIsOpen(false);
  };

  const onError = (error: any) => {
    if (_onError) {
      _onError(error);
    }
  };
  // https://docs.microsoft.com/en-us/onedrive/developer/controls/file-pickers/js-v72/open-file?view=odsp-graph-online#advanced-options
  const odOptions = {
    clientId,
    action,
    multiSelect,
    viewType,
    advanced: {
      accessToken,
      filter,
      endpointHint: 'api.onedrive.com',
    },
    success: onSuccess,
    cancel: onCancel,
    error: onError,
  };

  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      OneDrive.open(odOptions);
    }
  }, [isOpen]);
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <div onClick={() => SetIsOpen(true)}>{children}</div>;
};

export default MicrosoftChooser;
