/* eslint-disable no-param-reassign */
import { ActionOn, actionOn, Thunk, thunk } from 'easy-peasy';
import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import { IInjections, IStore } from 'store';
import { MetadataImportDTO } from 'models/dtos';
import BaseImportStore from './baseImportStore';

export interface MetadataImportStore extends BaseImportStore {
  startImport: Thunk<MetadataImportStore, void, IInjections, IStore>;

  validateStep: Thunk<
    MetadataImportStore,
    number,
    IInjections,
    IStore,
    boolean
  >;
  endWorkflow: ActionOn<MetadataImportStore, IStore>;
}

const metadataImportStore: MetadataImportStore = {
  startImport: thunk(
    async (actions, _, { injections, getStoreActions, getStoreState }) => {
      const storeState = getStoreState();
      const storeActions = getStoreActions();
      const { importService } = injections;

      const fileInfos = storeActions.workflow.import.common.prepareImportFileInfos();
      const metadataImportDTO: MetadataImportDTO = {
        platformID: storeState.user.currentLumappsPlatformID,
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        fileInfos,
        instanceId: '0',
        instanceName: '[PLATFORM]',
      };
      const { status } = await importService.startImportMetadata(
        metadataImportDTO
      );
      if (status !== 200) {
        storeActions.notifications.notify({
          message: NotificationsErrors.METADATA_IMPORT_ERROR,
          severity: 'error',
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.IMPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
    }
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions }) => {
    // Validate or redirect validating depending on the current step we are in
    switch (stepNumber) {
      case 0:
        return getStoreActions().workflow.import.common.validateStep(
          stepNumber
        );
      default:
        return true;
    }
  }),
  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {}
  ),
};

export default metadataImportStore;
