import { FileProvider } from './provider';

export enum ExportLang {
  all = 'ALL',
  fr = 'fr',
  en = 'en',
  ja = 'ja',
  it = 'it',
  de = 'de',
  az = 'az',
  bg = 'bg',
  ar = 'ar',
  zh = 'zh',
  zh_tw = 'zh_tw',
  ko = 'ko',
  hr = 'hr',
  es = 'es',
  hi = 'hi',
  hu = 'hu',
  el = 'el',
  no = 'no',
  ku = 'ku',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  pt_br = 'pt_br',
  ru = 'ru',
  ro = 'ro',
  sr = 'sr',
  sk = 'sk',
  sl = 'sl',
  sv = 'sv',
  cs = 'cs',
  uk = 'uk',
  bi = 'vi',
  th = 'th',
  tr = 'tr',
}
export const baseLumappsLangs: Array<string> = Object.values(ExportLang);

export interface TransactionDTO {
  element_kind: string;
  type: string;
  user: string;
  status: string;
  startDate: string;
  processedElements: number;
  successElements: number;
  totalElements: number;
  refFile: string;
  title: string;
  customer: string;
  planificationKey: string;
  error: string;
  instanceName: string;
  instanceId: string;
}

export interface PlanifDTO {
  recurrent: boolean;
  nextExecutionDate: string | undefined;
  dateTimezone: string | undefined;
  interval: string | undefined;
  maxExecutionsNb: string | undefined;
  currentExecutionsNb: string | undefined;
}

export interface IExportFileInfos {
  destination: FileProvider;
  folderID: string;
  driveID?: string;
}
interface BaseExportDTO extends PlanifDTO {
  lumappsPlatformBaseUrl: string;
  platformID: string;
  instanceName: string;
  instanceId: string;
  limit?: string;
  lang: ExportLang | undefined;
  title?: string;
  exportToInfos: IExportFileInfos;
}

export interface UserExportDTO extends BaseExportDTO {
  platformGroupId: string | undefined;

  siteGroupId: string | undefined;

  onlyActiveUsers: boolean;
  exportUserSettings: boolean;
  exportUserDirectoryFields: boolean;

  directorySiteID?: string;
  directoryID?: string;
}
export enum DirectoryExportType {
  exportAllDirectories = 'exportAllDirectories',
  exportSiteDirectories = 'exportSiteDirectories',
}
export enum DirectoryEntriesExportType {
  exportAllPlatformDirectoriesEntries = 'exportAllPlatformDirectoriesEntries',
  exportAllSiteDirectoriesEntries = 'exportAllSiteDirectoriesEntries',
  exportDirectoryEntries = 'exportDirectoryEntries',
}
export interface DirectoryExportDTO extends BaseExportDTO {
  directoryIds: Array<string>;
  exportDirectoryEntries: boolean;
  directoryExportType: DirectoryExportType;
  directoryEntriesExportType: DirectoryEntriesExportType;
}

export enum GroupExportType {
  exportAllGroups = 'exportAllGroups',
  exportPlatformGroups = 'exportPlatformGroups',
  exportSiteGroups = 'exportSiteGroups',
}
export interface GroupExportDTO extends BaseExportDTO {
  exportType: GroupExportType;
}

export enum MetadataExportType {
  exportAllMetadata = 'exportAllMetadata',
  exportPlatformMetadata = 'exportPlatformMetadata',
  exportSiteMetadata = 'exportSiteMetadata',
}
export interface MetadataExportDTO extends BaseExportDTO {
  exportType: MetadataExportType;
}

export interface ContentExportDTO extends BaseExportDTO {
  customContentType?: string;
}

export interface CommunityExportDTO extends BaseExportDTO {
  communityPosts: boolean | undefined;
  communityIds: Array<string> | undefined;
}

export interface SiteExportDTO extends BaseExportDTO {}

// Import
export interface IImportFileInfos {
  source: FileProvider;
  fileID: string;
  driveID?: string;
  filename: string;
}
interface BaseImportDTO {
  lumappsPlatformBaseUrl: string;
  platformID: string;
  fileInfos: IImportFileInfos;
  instanceId: string;
  instanceName: string;
}

export interface UserImportDTO extends BaseImportDTO {
  DirectorySiteID: string | undefined;
  DirectoryID: string | undefined;
}

export interface DirectoryImportDTO extends BaseImportDTO {}

export interface GroupImportDTO extends BaseImportDTO {}

export interface MetadataImportDTO extends BaseImportDTO {}

export interface ContentImportDTO extends BaseImportDTO {}
