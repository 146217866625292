import React, { useState, useEffect } from 'react';
import './IconFilesList.css';
import { Icon, Size, Progress, Emphasis, Checkbox, Theme } from '@lumx/react';
import { mdiFolder } from '@lumx/icons';
import { DriveFile } from './services/interface';

interface IconFilesListProps {
  theme?: Theme,
  files: Array<any>;
  setChoosenFile: any;
  loadingMore: boolean;
  loadFolderFiles: any;
  options?: any;
}
const IconFilesList: React.FC<IconFilesListProps> = ({
  theme = Theme.light,
  files,
  setChoosenFile,
  loadingMore,
  loadFolderFiles,
  options,
}: IconFilesListProps) => {
  const [selected, setSelected] = useState<string | undefined>();
  const [localFiles, setLocalFiles] = useState<Array<any>>([]);

  useEffect(() => {
    setLocalFiles([...files]);
  }, [files]);

  return (
    <>
      <div className="icon-files-list-container">
        {localFiles &&
          localFiles.length > 0 &&
          localFiles.map((file: DriveFile, index: number) => {
            return (
              <>
                {file.isFolder ? (
                  <div key={index} className="icon-fiels-list-file">
                    <Icon
                      theme={theme}
                      onClick={() => loadFolderFiles(file)}
                      icon={mdiFolder}
                      size={Size.xl}
                      emphasis={Emphasis.low}
                    />
                    <div className="icons-files-list-file-text">
                      <Checkbox
                        theme={theme}
                        value={selected === file.id}
                        onChange={() => {
                          setSelected(
                            selected === file.id ? undefined : file.id
                          );
                          setChoosenFile(file);
                        }}
                      />

                      {file.name}
                    </div>
                  </div>
                ) : (
                  <>
                    {options.onlyFolders ? null : (
                      <div key={index} className="icon-fiels-list-file">
                        <div>
                          <div
                            className="icons-files-list-file-icon"
                            style={{
                              backgroundImage: `url(${file.imageLink}), url(http://placehold.jp/128x128.png)`,
                            }}
                          />
                        </div>
                        <div className="icons-files-list-file-text">
                          {!options.onlySelectFolder && (
                            <Checkbox
                              theme={theme}
                              value={selected === file.id}
                              onChange={() => {
                                setSelected(
                                  selected === file.id ? undefined : file.id
                                );
                                setChoosenFile(file);
                              }}
                            />
                          )}
                          {file.name}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            );
          })}
      </div>

      {(!localFiles || localFiles.length === 0) && (
        <div className="centered-div">No data ...</div>
      )}
      {localFiles && localFiles.length > 0 && loadingMore && (
        <div className="centered-div">
          <Progress theme={theme} />
        </div>
      )}
    </>
  );
};

export default IconFilesList;
