import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  Size,
  RadioButton,
  RadioGroup,
  FlexBox,
  Alignment,
  Orientation,
  Chip,
  Icon,
} from '@lumx/react';
import { mdiClose, mdiFile, mdiOnedrive } from '@lumx/icons';

import { useStoreActions, useStoreState } from 'hooks';

import { FileProvider } from 'models/provider';

import NotConnectedToMicroOrGoogle from 'containers/NotConnectedToMicroOrGoogle';

import {
  PickedFile,
  ProviderName,
} from 'components/lab-filepicker/DrivePicker/models';
import DrivePicker from 'components/lab-filepicker/DrivePicker';
import { SelectableRowsOptions } from 'components/DataTable';
import MicrosoftChooser from 'components/MicrosoftDrivePicker';

const ExportDestinationChoice: React.FC = () => {
  const [radioButtonSelected, setRadioButtonSelected] = useState('csv');

  const user = useStoreState((state) => state.user);
  const exportDestInfos = useStoreState(
    (state) => state.workflow.export.common.exportToInfos
  );

  const getProviderToken = useStoreActions(
    (actions) => actions.user.getProviderToken
  );
  const { setExportToInfos } = useStoreActions(
    (actions) => actions.workflow.export.common
  );

  const intl = useIntl();

  const isGoogleConnected =
    user.isAuthenticatedWith && user.isAuthenticatedWith.google === true;
  const googleToken = user.tokens?.google?.token;
  const isMicrosoftConnected =
    user.isAuthenticatedWith && user.isAuthenticatedWith.microsoft === true;
  const microsoftToken = user.tokens?.microsoft?.token;

  useEffect(() => {
    if (isGoogleConnected) {
      getProviderToken('google');
    }
    if (isMicrosoftConnected) {
      getProviderToken('microsoft');
    }
  }, []);

  /**
   * Handle action after a user choosed a folder in the gdrive picker
   *  {
   *      "id": "15yeHHUwEHTkMiPYQRMpg9W68xdemfws8",
   *      "serviceId": "docs",
   *      "mimeType": "application/vnd.google-apps.folder",
   *      "name": "coucou",
   *      "description": "_lumwork_SPACE_ID:580D60A5-07DF-451D-A868-E3C4F09067DF\n_lumwork_DOCUMENT_ID:C4EA94BD-EDF0-486E-8386-28142AD7CBB6",
   *      "type": "folder",
   *      "lastEditedUtc": 1599493624760,
   *      "iconUrl": "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.folder+shared",
   *      "url": "https://drive.google.com/drive/folders/15yeHHUwEHTkMiPYQRMpg9W68xdemfws8",
   *      "embedUrl": "https://drive.google.com/embeddedfolderview?id=15yeHHUwEHTkMiPYQRMpg9W68xdemfws8",
   *      "sizeBytes": 0,
   *      "isShared": true
   *  }
   * @param data The data return by the pciker
   */
  const handleGoogleFolderPicked = (data: any) => {
    if (data.action === 'picked') {
      const picked = data.docs[0];

      setExportToInfos({
        name: picked?.name,
        destination: FileProvider.google,
        folderID: picked?.id,
      });
    }
  };

  /**
   *  {
   *      "webUrl": null,
   *      "value": [
   *          {
   *          "id": "6A85C6C21E282F93!104",
   *          "parentReference": {
   *              "driveId": "6A85C6C21E282F93"
   *          },
   *          "folder": {},
   *          "endpoint": "https://api.onedrive.com/v1.0"
   *          }
   *      ]
   *  }
   * @param pickedFiles The selected files
   */
  const handleMicrosoftFolderPicked = async (pickedFiles: any) => {
    const file = pickedFiles?.value[0];

    let filename = file?.name;
    const driveId = file?.parentReference?.driveId;
    const itemId = file?.id;
    if (!filename) {
      const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}`;
      const headers = { Authorization: microsoftToken };
      const { data } = await axios.get(url, { headers });
      filename = data?.name;
    }

    setExportToInfos({
      name: filename,
      destination: FileProvider.microsoft,
      folderID: itemId,
      driveId,
    });
  };

  const [isDrivePickerOpen, setIsDrivePickerOpen] = useState(false);

  const handleDrivePickerFile = (
    pickedFile: PickedFile,
    provider: FileProvider
  ) => {
    setExportToInfos({
      name: pickedFile.item.name,
      destination: provider,
      folderID: pickedFile.item.id,
    });
  };

  const handleExportDestChange = (value: FileProvider) => {
    setRadioButtonSelected(value);
    setExportToInfos({ destination: value });
  };

  const unselectedFile = () => {
    setExportToInfos({
      ...exportDestInfos,
      folderID: undefined,
      name: undefined,
      driveId: undefined,
    });
  };

  const styles = {
    radioButtonSubContent: {
      marginLeft: '20px',
    },
  };

  return (
    <div className="lumx-spacing-padding-huge">
      <RadioGroup>
        <RadioButton
          checked={radioButtonSelected === FileProvider.csv}
          label={<FormattedMessage id="EXPORT_TO_CSV" />}
          name={FileProvider.csv}
          value={FileProvider.csv}
          onChange={handleExportDestChange}
        />

        <RadioButton
          checked={radioButtonSelected === FileProvider.google}
          label={<FormattedMessage id="EXPORT_TO_GDRIVE_FOLDER" />}
          name={FileProvider.google}
          value={FileProvider.google}
          onChange={handleExportDestChange}
          disabled={!isGoogleConnected && !googleToken}
        />
        {/* Not connected, infos panel */}
        {!isGoogleConnected && !googleToken && (
          <div style={styles.radioButtonSubContent}>
            <NotConnectedToMicroOrGoogle />
          </div>
        )}
        {exportDestInfos.destination === FileProvider.google &&
          isGoogleConnected &&
          googleToken && (
            <div style={styles.radioButtonSubContent}>
              <FlexBox
                className="lumx-spacing-margin-big"
                orientation={Orientation.horizontal}
                vAlign={Alignment.center}
              >
                <DrivePicker
                  isOpen={isDrivePickerOpen}
                  selectableRows={SelectableRowsOptions.single}
                  providers={[
                    {
                      name: ProviderName.Google,
                      token: googleToken,
                    },
                  ]}
                  onPick={(pickedFile: PickedFile) =>
                    handleDrivePickerFile(pickedFile, FileProvider.google)
                  }
                  onlyFolders
                  onlyCanAddChildren
                  onCloseModal={() => setIsDrivePickerOpen(false)}
                  lang={user.lang}
                  withGallery={false}
                />
                <Button
                  leftIcon={mdiOnedrive}
                  title={intl.formatMessage({id: 'CHOOSE_GDRIVE_FOLDER'})}
                  onClick={() => setIsDrivePickerOpen(true)}
                >
                  <FormattedMessage id="CHOOSE_GDRIVE_FOLDER" />
                </Button>

                {exportDestInfos.folderID && (
                  <Chip
                    before={<Icon icon={mdiFile} size={Size.xs} />}
                    after={<Icon icon={mdiClose} size={Size.xs} />}
                    onClick={unselectedFile}
                  >
                    {exportDestInfos?.name}
                  </Chip>
                )}
              </FlexBox>
            </div>
          )}

        <RadioButton
          checked={radioButtonSelected === FileProvider.microsoft}
          label={<FormattedMessage id="EXPORT_TO_ONEDRIVE_FOLDER" />}
          name={FileProvider.microsoft}
          value={FileProvider.microsoft}
          onChange={handleExportDestChange}
          disabled={!isMicrosoftConnected && !microsoftToken}
        />
        {!isMicrosoftConnected && !microsoftToken && (
          <div style={styles.radioButtonSubContent}>
            <NotConnectedToMicroOrGoogle microsoft={true} />
          </div>
        )}
        {exportDestInfos.destination === FileProvider.microsoft &&
          isMicrosoftConnected &&
          microsoftToken && (
            <>
              <FlexBox
                className="lumx-spacing-margin-big"
                orientation={Orientation.horizontal}
                vAlign={Alignment.center}
              >
                <MicrosoftChooser
                  onFilesPicked={handleMicrosoftFolderPicked}
                  accessToken={microsoftToken}
                  clientId="69175d32-190f-421b-98de-81cca332f1f6"
                  viewType="folders"
                  multiSelect={false}
                >
                  <Button
                    leftIcon={mdiOnedrive}
                    title={<FormattedMessage id="CHOOSE_ONEDRIVE_FOLDER" />}
                  >
                    <FormattedMessage id="CHOOSE_ONEDRIVE_FOLDER" />
                  </Button>
                </MicrosoftChooser>
                {exportDestInfos.folderID && (
                  <Chip
                    before={<Icon icon={mdiFile} size={Size.xs} />}
                    after={<Icon icon={mdiClose} size={Size.xs} />}
                    onClick={unselectedFile}
                  >
                    {exportDestInfos?.name}
                  </Chip>
                )}
              </FlexBox>
            </>
          )}
      </RadioGroup>
    </div>
  );
};

export default ExportDestinationChoice;
