import React from 'react';

import { List, ListItem, ListSubheader } from '@lumx/react';

import { useStoreState } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { fileProviderToDisplayName } from 'models/provider';

const ExportDestinationSummary = () => {
  const { exportToInfos } = useStoreState(
    (state) => state.workflow.export.common
  );
  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="BASE_EXPORT_DIALOG_STEP_2_TITLE" />
        </ListSubheader>
        <ListItem>
          <div>
            <span>
              {fileProviderToDisplayName[exportToInfos.destination] ||
                'Unknown provider'}
            </span>
          </div>
          {exportToInfos.name && (
            <div>
              <span className="lumx-color-font-dark-L2">
                {exportToInfos.name}
              </span>
            </div>
          )}
        </ListItem>
      </List>
    </div>
  );
};

export default ExportDestinationSummary;
