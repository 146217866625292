/* eslint-disable consistent-return */
import axios, { AxiosInstance, AxiosError } from 'axios';
import { ProviderName } from '../models';

import { DriveFile, DriveServiceOptions } from './interface';

class microsoftDriveService {
  token: string;

  onlyFolders: boolean;

  currentCursor: string | null;

  api: AxiosInstance;

  constructor(
    token: string,
    lumappsBaseUrl: string | undefined,
    serverRequestUrl: string | undefined = undefined,
    provider?: ProviderName,
    options?: DriveServiceOptions
  ) {
    this.token = token;
    this.onlyFolders = options?.onlyFolders || false;
    this.currentCursor = null;
    this.api = this.createApiInstance(token);
  }

  createApiInstance = (token: string) => {
    const api = axios.create({
      baseURL: 'https://graph.microsoft.com/v1.0/',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    api.interceptors.response.use(
      (response: any) => {
        this.currentCursor = response?.data['@odata.nextLink'] || null;
        return response;
      },
      (error: AxiosError) => error.response
    );
    return api;
  };

  hydrateFiles = (files: any, sharedWithMe: boolean = false) =>
    files
      ? files
          .map((el: any) =>
            sharedWithMe
              ? this.fileItemTransform(el?.remoteItem)
              : this.fileItemTransform(el)
          )
          .filter(Boolean)
          .sort(
            (a: DriveFile, b: DriveFile) =>
              -(Number(a.isFolder) - Number(b.isFolder))
          )
      : null;

  // eslint-disable-next-line class-methods-use-this
  fileItemTransform(fileItem: any): DriveFile | null {
    if (!fileItem) {
      return null;
    }
    const parentId = fileItem?.parentReference?.id;
    const imageLink =
      fileItem.thumbnails && fileItem.thumbnails.length > 0
        ? fileItem.thumbnails[0]?.large?.url || ''
        : '';
    return {
      id: fileItem.id,
      parentId,
      name: fileItem.name,
      isFolder: !!fileItem.folder,
      imageLink,
      createdAt: fileItem.createdDateTime,
      createdBy: fileItem?.createdBy?.user?.displayName,
      updatedAt: '', //TODO
      updatedBy: '', //TODO
      size: 0, //TODO
      isWebLink: false, //TODO
      details: fileItem,
    };
  }

  getNextPage = async () => {
    if (!this.currentCursor) {
      return null;
    }

    try {
      const { data, status } = await axios.get(this.currentCursor, {
        headers: { Authorization: this.token },
      });
      if (status === 200 && data.value) {
        return this.hydrateFiles(data.value);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  getFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false,
    additionalParams: any = undefined,
    searchText: string | null = null,
    sharedWithMe: boolean = false
  ) => {
    if (nextPage && !this.currentCursor) {
      return;
    }

    if (nextPage && this.currentCursor) {
      return this.getNextPage();
    }

    let params = {
      $top: 20,
      $expand: 'thumbnails',
    };
    if (additionalParams) {
      params = {
        ...params,
        ...additionalParams,
      };
    }

    if (folderId) {
      const { data, status } = await this.api.get(
        `/me/drive/items/${folderId}/children`,
        {
          params,
        }
      );
      if (status === 200 && data.value) {
        return this.hydrateFiles(data.value);
      }
      return null;
    }

    if (searchText) {
      const { data, status } = await this.api.get(
        `/me/drive/search(q='${searchText}')`,
        {
          params,
        }
      );
      if (status === 200 && data.value) {
        return this.hydrateFiles(data.value);
      }
      return null;
    }

    if (sharedWithMe) {
      const { data, status } = await this.api.get('/me/drive/sharedWithMe', {
        params,
      });
      if (status === 200 && data.value) {
        return this.hydrateFiles(data.value, true);
      }
      return null;
    }

    const { data, status } = await this.api.get('/me/drive/root/children', {
      params,
    });
    if (status === 200 && data.value) {
      return this.hydrateFiles(data.value);
    }
    return null;
  };

  getMyFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false,
    additionalParams: any = undefined
  ) => this.getFiles(folderId, nextPage, additionalParams);

  getSharedWithMeFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false
  ) => this.getFiles(folderId, nextPage, null, null, true);

  searchFilesByName = async (searchText: string) =>
    this.getFiles(null, false, null, searchText);
}

export default microsoftDriveService;
