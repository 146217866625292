import ApiClient from 'services/client';
import {
  userServiceFactory,
  IUserService,
  ILumappsService,
  lumappsServicefactory,
  ITransactionService,
  transactionServiceFactory,
  IImportService,
  importServiceFactory,
  IPlanificationService,
  planificationServiceFactory,
  IExportService,
  exportServiceFactory,
} from 'services';
import workflowStore, { WorkflowStore } from './workflow';
import { store as mainStore, IMainStore } from './main/store';

import transactionStore, { TransactionStore } from './transaction';
import planificationStore, { PlanificationStore } from './planification';
import notificationsStore, { INotificationsStore } from './notifications';

export interface IStore extends IMainStore {
  planification: PlanificationStore;
  transaction: TransactionStore;
  workflow: WorkflowStore;
  notifications: INotificationsStore;
}
const store: IStore = {
  planification: planificationStore,
  transaction: transactionStore,
  workflow: workflowStore,
  notifications: notificationsStore,
  ...mainStore,
};

export interface IInjections {
  userService: IUserService;
  transactionService: ITransactionService;
  planificationService: IPlanificationService;
  lumappsService: ILumappsService;
  importService: IImportService;
  exportService: IExportService;
  api: ApiClient;
}
export interface IStoreConfig {
  injections: IInjections;
}

const api = new ApiClient();
const storeConfig: IStoreConfig = {
  injections: {
    lumappsService: lumappsServicefactory(api.client),
    userService: userServiceFactory(api.client),
    transactionService: transactionServiceFactory(api.client),
    planificationService: planificationServiceFactory(api.client),
    importService: importServiceFactory(api.client),
    exportService: exportServiceFactory(api.client),
    api,
  },
};

export { store, storeConfig };
