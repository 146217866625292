export interface IBaseExport {
  kind: string | undefined;
  instanceName: string | undefined;
  instanceId: number | string | undefined;
  title: string | undefined;
}

export const baseExport = {
  kind: undefined,
  instanceId: undefined,
  instanceName: undefined,
  title: undefined,
};

export interface IUserExport extends IBaseExport {
  platform?: boolean;
  platformGroupId?: string | number;
  platformGroupName?: string;
  siteGroupId?: string | number;
  siteGroupName?: string;
  onlyActiveUsers?: boolean;
  exportUserDirectoryFields?: boolean;
  exportUserSettings?: boolean;
}
