import React from 'react';

import { ProgressTracker, ProgressTrackerStep } from '@lumx/react';
import { useStoreState } from 'hooks';
import workflowSteps from './WorkflowStepsHeaders';

const WorkflowStepper = () => {
  const modalStore = useStoreState((state) => state.workflow.modal);

  const steps =
    modalStore.type && modalStore.category
      ? workflowSteps[modalStore.type][modalStore.category]
      : [];
  return (
    <ProgressTracker activeStep={modalStore.currentStep}>
      {steps.map((step: any, index: number) => {
        return (
          <ProgressTrackerStep
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            isActive={modalStore.currentStep === index}
            label={step.label}
            helper={step.helper}
            isComplete={
              modalStore.currentStep >= 0 && modalStore.currentStep > index
            }
            hasError={
              modalStore.currentStep >= 0 &&
              modalStore.currentStep === index &&
              modalStore.currentStepHasErrors
            }
          />
        );
      })}
    </ProgressTracker>
  );
};

export default WorkflowStepper;
