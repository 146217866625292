/* eslint-disable no-param-reassign */
import { action, Action, actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';
import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import { IExportFileInfos, PlanifDTO, UserExportDTO } from 'models/dtos';
import { baseExport, IUserExport } from 'models/export';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

interface ISelectChoice {
  value: any;
  name: string;
  displayName: string;
}

export interface UserExportStore extends BaseExportStore {
  exportData: IUserExport;

  // Choices
  exportChoice: 'platform' | 'platformGroup' | 'siteGroup';
  setExportChoice: Action<
    UserExportStore,
    'platform' | 'platformGroup' | 'siteGroup'
  >;

  // Platform groups
  platformGroups: Array<any> | undefined;
  setPlatformGroups: Action<UserExportStore, Array<any>>;
  fetchPlatformGroups: Thunk<UserExportStore, void, IInjections, IStore>;
  loadingPlatformGroups: boolean;
  setLoadingPlatformGroups: Action<UserExportStore, boolean>;
  selectPlatformGroup: Action<UserExportStore, ISelectChoice>;

  // Site
  setSite: Action<UserExportStore, any>;
  selectSite: Thunk<UserExportStore, ISelectChoice, IInjections, IStore>;

  // Site groups
  siteGroups: Array<any> | undefined;
  setSiteGroups: Action<UserExportStore, Array<any>>;
  fetchSiteGroups: Thunk<UserExportStore, any, IInjections, IStore>;
  loadingSiteGroups: boolean;
  setLoadingSiteGroups: Action<UserExportStore, boolean>;
  selectSiteGroup: Action<UserExportStore, ISelectChoice>;

  // Options
  setExportOnlyActiveUsers: Action<UserExportStore, boolean>;

  // More infos to export
  setExportUserDirectoryFields: Action<UserExportStore, boolean>;

  directorySiteID?: string;
  directoryID?: string;
  setDirectorySiteID: Action<UserExportStore, string>;
  setDirectoryID: Action<UserExportStore, string>;

  setExportUserSettings: Action<UserExportStore, boolean>;

  startExport: Thunk<UserExportStore, void, IInjections, IStore>;
  validateStep: Thunk<UserExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<UserExportStore, IStore>;
}

const userExportStore: UserExportStore = {
  exportData: baseExport,

  /*
   * User export type choice
   */
  exportChoice: 'platform',
  setExportChoice: action((state, choice) => {
    switch (choice) {
      // Export all users on my platform
      case 'platform':
        state.exportData.platform = true;
        break;
      case 'platformGroup':
        state.exportData.platform = true;
        break;

      default:
        state.exportData.platform = false;
    }
    state.exportChoice = choice;
  }),

  /*
   * Plaform groups choice
   */
  platformGroups: undefined,
  setPlatformGroups: action((state, groups) => {
    state.platformGroups = groups.map((el: any) => ({
      value: el.id,
      name: el.name,
      displayName: el.name,
    }));
  }),
  fetchPlatformGroups: thunk(
    async (actions, _, { injections, getStoreActions, getStoreState }) => {
      actions.setLoadingPlatformGroups(true);
      const { lumappsService } = injections;

      const storeState = getStoreState();

      const { data, status } = await lumappsService.getSiteGroups(
        0,
        storeState.user.currentLumappsPlatformID,
        storeState.user.currentLumappsPlatformBaseUrl
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        actions.setLoadingPlatformGroups(false);
        return;
      }
      actions.setPlatformGroups(data);
      actions.setLoadingPlatformGroups(false);
    }
  ),
  loadingPlatformGroups: false,
  setLoadingPlatformGroups: action((state, value) => {
    state.loadingPlatformGroups = value;
  }),
  selectPlatformGroup: action((state, choice) => {
    state.exportData.platformGroupId = choice?.value;
    state.exportData.platformGroupName = choice?.displayName;
  }),

  /*
   * Site choice
   */
  setSite: action((state, site) => {
    state.exportData.instanceId = site?.value;
    state.exportData.instanceName = site?.displayName;
  }),
  selectSite: thunk((actions, site) => {
    actions.setSite(site);
    if (site) {
      actions.fetchSiteGroups(site.value);
    }
  }),

  /*
   * Site group choice
   */
  siteGroups: undefined,
  setSiteGroups: action((state, payload) => {
    state.siteGroups = payload;
  }),
  fetchSiteGroups: thunk(
    async (actions, siteId, { injections, getStoreActions, getStoreState }) => {
      actions.setLoadingSiteGroups(true);
      const { lumappsService } = injections;
      const storeState = getStoreState();

      const { data: groups, status } = await lumappsService.getSiteGroups(
        siteId,
        storeState.user.currentLumappsPlatformID,
        storeState.user.currentLumappsPlatformBaseUrl
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        actions.setLoadingSiteGroups(false);
        return;
      }
      actions.setSiteGroups(
        groups.map((el: any) => ({
          value: el.id,
          displayName: el.name,
          name: el.name,
        }))
      );
      actions.setLoadingSiteGroups(false);
    }
  ),
  loadingSiteGroups: false,
  setLoadingSiteGroups: action((state, val) => {
    state.loadingSiteGroups = val;
  }),
  selectSiteGroup: action((state, choice) => {
    state.exportData.siteGroupId = choice?.value;
    state.exportData.siteGroupName = choice?.displayName;
  }),

  setExportOnlyActiveUsers: action((state, value) => {
    state.exportData.onlyActiveUsers = value;
  }),
  setExportUserDirectoryFields: action((state, value) => {
    state.exportData.exportUserDirectoryFields = value;
  }),
  setExportUserSettings: action((state, value) => {
    state.exportData.exportUserSettings = value;
  }),

  directoryID: undefined,
  directorySiteID: undefined,
  setDirectoryID: action((state, value) => {
    state.directoryID = value;
  }),
  setDirectorySiteID: action((state, value) => {
    state.directorySiteID = value;
  }),

  startExport: thunk(
    async (
      actions,
      _,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();
      const formData = state.exportData;

      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: UserExportDTO = {
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        limit: undefined,
        lang: undefined,
        title: undefined,
        exportToInfos,

        instanceName:
          state.exportChoice === 'siteGroup' && formData.instanceName
            ? formData.instanceName
            : '[PLATFORM]',
        instanceId:
          state.exportChoice === 'siteGroup' && formData.instanceId
            ? formData.instanceId.toString()
            : '0',
        platformGroupId: formData.platformGroupId?.toString(),
        siteGroupId: formData.siteGroupId?.toString(),

        onlyActiveUsers: Boolean(formData.onlyActiveUsers),
        exportUserSettings: Boolean(formData.exportUserSettings),
        exportUserDirectoryFields: Boolean(formData.exportUserDirectoryFields),

        directorySiteID: state.directorySiteID,
        directoryID: state.directoryID,

        ...planifDTO,
      };

      const { data, status } = await exportService.startUserExport(exportData);
      if (status !== 200 && status !== 201) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.USER_EXPORT_CREATE_ERROR,
          severity: 'error',
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
      return data;
    }
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions, getState }) => {
    const state = getState();
    let success = true;
    switch (stepNumber) {
      case 0:
        //
        return success;
      // Parameters step
      case 1:
        switch (state.exportChoice) {
          case 'platformGroup':
            if (!state.exportData.platformGroupId) {
              getStoreActions().notifications.notify({
                message:
                  NotificationsErrors.USER_EXPORT_NO_PLATFORM_GROUP_SELECTED,
                severity: 'error',
              });
              success = false;
            }
            break;
          case 'siteGroup':
            if (!state.exportData.instanceId) {
              getStoreActions().notifications.notify({
                message: NotificationsErrors.USER_EXPORT_NO_SITE_SELECTED,
                severity: 'error',
              });
              success = false;
            }
            if (!state.exportData.siteGroupId) {
              getStoreActions().notifications.notify({
                message: NotificationsErrors.USER_EXPORT_NO_GROUP_SITE_SELECTED,
                severity: 'error',
              });
              success = false;
            }
            break;
          default:
            break;
        }
        return success;
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.exportData = baseExport;
      state.exportChoice = 'platform';
      state.platformGroups = undefined;
      state.siteGroups = undefined;
      state.loadingSiteGroups = false;
      state.loadingPlatformGroups = false;
    }
  ),

  // launchExport => 3 cas (platform - platform group - site group) + options
};

export default userExportStore;
