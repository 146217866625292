/*
 * Those enums values are used as transalations keys in formatted message
 * To change the notification message you can simply change the key message
 * on localize
 */
export enum NotificationsErrors {
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  NO_SITE_DIRECTORY_SELECTED_ERROR = 'NO_SITE_DIRECTORY_SELECTED_ERROR',
  NO_SITE_SELECTED_ERROR = 'NO_SITE_SELECTED_ERROR',
  NO_LANG_SELECTED_ERROR = 'NO_LANG_SELECTED_ERROR',
  USER_EXPORT_CREATE_ERROR = 'USER_EXPORT_CREATE_ERROR',
  USER_EXPORT_NO_PLATFORM_GROUP_SELECTED = 'USER_EXPORT_NO_PLATFORM_GROUP_SELECTED',
  USER_EXPORT_NO_SITE_SELECTED = 'USER_EXPORT_NO_SITE_SELECTED',
  USER_EXPORT_NO_GROUP_SITE_SELECTED = 'USER_EXPORT_NO_GROUP_SITE_SELECTED',
  UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR',
  IMPORT_NO_FILE_SELECTED = 'IMPORT_NO_FILE_SELECTED',
  USER_IMPORT_NO_SITE_SELECTED = 'USER_IMPORT_NO_SITE_SELECTED',
  USER_IMPORT_NO_DIRECTORY_SELECTED = 'USER_IMPORT_NO_DIRECTORY_SELECTED',
  DELETE_ERROR = 'UNEXPECTED_ERROR',
  CONTENT_EXPORT_CREATE_ERROR = 'UNEXPECTED_ERROR',
  NO_CONTENT_TYPE_SELECTED = 'NO_CONTENT_TYPE_SELECTED',
  DOWNLOAD_REPORT_ERROR = 'UNEXPECTED_ERROR',
  USER_IMPORT_ERROR = 'UNEXPECTED_ERROR',
  DIRECTORY_IMPORT_ERROR = 'UNEXPECTED_ERROR',
  GROUP_IMPORT_ERROR = 'UNEXPECTED_ERROR',
  METADATA_IMPORT_ERROR = 'UNEXPECTED_ERROR',
  NO_COMMUNITY_SELECTED_ERROR = 'NO_COMMUNITY_SELECTED_ERROR',
  COMMUNITY_EXPORT_CREATE_ERROR = 'UNEXPECTED_ERROR',
}

export enum NotificationsSuccessMessages {
  EXPORT_CREATED = 'EXPORT_CREATED',
  TRANSACTION_DELETED = 'TRANSACTION_DELETED',
  PLANIFICATION_DELETED = 'PLANIFICATION_DELETED',
  IMPORT_CREATED = 'IMPORT_CREATED',
}
