/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosPromise,
} from 'axios';
import { getParentOrigin } from 'utils';


class ApiClient {
  client: AxiosInstance;
  retryNb: number

  constructor() {
    this.client = axios.create();

    this.retryNb = 0

    this.client.interceptors.response.use(
      (response: AxiosResponse) => response,
      (err: AxiosError) => {
        const { response, config: originalRequest }: any = err;

        if (response?.status === 401 && !originalRequest._retry && this.retryNb < 2) {
          return ApiClient.init()
            .then((res: AxiosResponse) => {
              if (res && res.data) {
                const { token } = res.data;
                this.client.defaults.headers.authorization = token;
              }
              originalRequest._retry = true;
              this.retryNb += 1
              return this.client(originalRequest);
            })
            .catch(() => err.response);
        }
        return response;
      },
    );
  }

  getNewAxiosInstance() {
    return axios.create()
  }
  
  static init(): AxiosPromise {
    const parentOrigin = getParentOrigin();
    const urlParams = new URLSearchParams(document.location.search);
    const initialInfos = {
      token: urlParams.get('token') || 'fake',
      lumappsPlatformBaseUrl: parentOrigin || 'https://sites.lumapps.com',
      personnifyAs: urlParams.get('personnifyAs'),
      personnifyWithPlatformId: urlParams.get('personnifyWithPlatformId'),
      personnifyWithPlatformVerifyUrl: urlParams.get('personnifyWithPlatformVerifyUrl') 
    };
    return axios.post('/api/init', initialInfos, { withCredentials: true });
  }
}
export default ApiClient;
