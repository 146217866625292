import { actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';

import { NotificationsErrors, NotificationsSuccessMessages } from 'models';
import { IExportFileInfos, PlanifDTO, SiteExportDTO } from 'models/dtos';
import { IInjections, IStore } from 'store';
import BaseExportStore from './baseExportStore';

export interface SiteExportStore extends BaseExportStore {
  startExport: Thunk<SiteExportStore, void, IInjections, IStore>;
  validateStep: Thunk<SiteExportStore, number, IInjections, IStore>;
  endWorkflow: ActionOn<SiteExportStore, IStore>;
}

const siteExportStore: SiteExportStore = {
  startExport: thunk(
    async (
      actions,
      _,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const { exportService } = injections;
      const state = getState();
      const storeState = getStoreState();
      const planifDTO: PlanifDTO = getStoreActions().workflow.export.planification.preparePlanifDTO();
      const exportToInfos: IExportFileInfos = getStoreActions().workflow.export.common.prepareExportToInfos();
      const exportData: SiteExportDTO = {
        lumappsPlatformBaseUrl: storeState.user.currentLumappsPlatformBaseUrl,
        platformID: storeState.user.currentLumappsPlatformID,
        limit: undefined,
        lang: undefined,
        title: undefined,
        instanceId: '0',
        instanceName: '[PLATFORM]',
        ...planifDTO,
        exportToInfos,
      };
      const { data, status } = await exportService.startSiteExport(exportData);
      if (status !== 200) {
        getStoreActions().notifications.notify({
          severity: 'error',
          message: NotificationsErrors.UNEXPECTED_ERROR,
        });
        return;
      }
      getStoreActions().notifications.notify({
        message: NotificationsSuccessMessages.EXPORT_CREATED,
        severity: 'success',
      });
      getStoreActions().workflow.modal.endWorkflow();
    }
  ),
  validateStep: thunk((actions, stepNumber) => {
    switch (stepNumber) {
      // Parameters step
      default:
        return true;
    }
  }),

  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {}
  ),
};

export default siteExportStore;
