import { useStoreState } from 'hooks'
import { DashboardCategory } from 'models/dashboard'
import React from 'react'
import PickedFileSummary from './pickedFileSummary'
import UserImportSummary from './UserImportSummary'

const Step3 = () => {
    const category = useStoreState(state => state.workflow.modal.category)
    return (
        <div className="lumx-spacing-padding-horizontal-huge">
            <PickedFileSummary />
            {category === DashboardCategory.user && <UserImportSummary />}
        </div>
    )
}

export default Step3
