/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import {
  Thunk, thunk, Action, action, ThunkOn,
} from 'easy-peasy';
import { IInjections, IStore } from 'store';
import { NotificationsErrors } from 'models';

export interface PlanificationStore {
  planifications: Array<any> | undefined;
  setPlanifications: Action<PlanificationStore, Array<any>>;
  fetchPlanifications: Thunk<
  PlanificationStore,
  boolean | void,
  IInjections,
  IStore
  >;
  isLoading: boolean;
  setIsLoading: Action<PlanificationStore, boolean>;

  _timeout: any;
  setThisTimeout: Action<PlanificationStore, any>;
  clearTimeout: Action<PlanificationStore, void>;

  deletePlanifications: Thunk<
  PlanificationStore,
  Array<string>,
  IInjections,
  IStore
  >;

  selectedPlanifKey: string | undefined;
  setSelectedPlanifKey: Action<PlanificationStore, string | undefined>;
}

const planificationStore: PlanificationStore = {
  planifications: undefined,
  setPlanifications: action((state, planifications) => {
    state.planifications = [...planifications];
  }),

  fetchPlanifications: thunk(
    async (
      actions,
      withLoader = true,
      { injections, getStoreActions, getStoreState },
    ) => {
      if (withLoader) {
        actions.setPlanifications([]);
        actions.setIsLoading(true);
      }
      const { planificationService } = injections;
      const storeState = getStoreState();
      const { category } = storeState.dashboard;
      const platformID = storeState.user.currentLumappsPlatformID;

      const { data, status } = await planificationService.listPlanifications(
        category,
        platformID,
      );
      if (status !== 200) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.UNEXPECTED_ERROR,
          severity: 'error',
        });
        return;
      }

      actions.setPlanifications(data);
      if (withLoader) {
        actions.setIsLoading(false);
      }
      const hasPendingOrRunningPlanif = data.find(
        (e: any) => e.status === 'pending' || e.status === 'running',
      );
      if (hasPendingOrRunningPlanif) {
        actions.clearTimeout();
        const g = setTimeout(() => actions.fetchPlanifications(false), 7000);
        actions.setThisTimeout(g);
      }
    },
  ),
  isLoading: false,
  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading;
  }),

  _timeout: undefined,
  setThisTimeout: action((state, val) => {
    state._timeout = val;
  }),
  clearTimeout: action((state) => {
    clearTimeout(state._timeout);
    state._timeout = undefined;
  }),

  deletePlanifications: thunk(
    async (actions, planificationIds, { injections, getStoreActions }) => {
      const { planificationService } = injections;

      const { data, status } = await planificationService.deletePlanifications(
        planificationIds,
      );
      if (status > 400) {
        getStoreActions().notifications.notify({
          message: NotificationsErrors.DELETE_ERROR,
          severity: 'error',
        });
        return;
      }
      actions.fetchPlanifications();
      return data;
    },
  ),

  selectedPlanifKey: undefined,
  setSelectedPlanifKey: action((state, planifKey) => {
    state.selectedPlanifKey = planifKey;
  }),
};

export default planificationStore;
