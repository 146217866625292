import { getThemeRelatedTextClass } from '@lumapps-extensions-playground/common';
import { useIntl } from '@lumapps-extensions-playground/translations';
import {
  mdiChevronLeft,
  mdiFolder,
  mdiOpenInNew,
  mdiViewGrid,
} from '@lumx/icons';
import {
  Emphasis,
  Icon,
  IconButton,
  Progress,
  Size,
  Theme,
  Tooltip,
} from '@lumx/react';
import React, { useEffect, useState } from 'react';
import {
  DataTable,
  SelectableRowsOptions,
} from '../lumx-react-extended/src/components/datatable';
import EmptyState from './EmptyState';
import IconFilesList from './IconFilesList';
import { PickedFile, Provider } from './models';
import { createTableHeaders, getBodyStructure } from './pickerStructure';
import { useFiles, usePicker } from './state/hooks';
import useDebounce from './useDebounce';
import { useInfiniteScroll } from './useInfiniteScroll';

export interface BaseDriveTableProps {
  provider: Provider;
  parentWin: any;
  onlyFolders?: boolean;
  onlyCanAddChildren?: boolean;
  editUser?: string;
  withGallery?: boolean;
  rootFolder?: any;
  mainCategory?: string;
  subCategory?: string;
  rowsPerPage?: number;
  onPick?: (choosenFile: PickedFile) => void;
  setChoosenFile?: (file: any) => void;
  selectableRows?: SelectableRowsOptions;
  theme?: Theme;
  lang?: string;
}

const BaseDriveTable = ({
  provider,
  parentWin,
  onlyFolders = false,
  onlyCanAddChildren = false,
  withGallery = true,
  rootFolder = undefined,
  mainCategory = undefined,
  subCategory = undefined,
  rowsPerPage = 10,
  onPick,
  setChoosenFile,
  selectableRows = SelectableRowsOptions.multiple,
  theme = Theme.light,
  lang = 'en',
}: BaseDriveTableProps) => {
  const [searchText] = useState<string | undefined>('');
  const debouncedSearchText = useDebounce(searchText, 500);
  const [pickerDisplayModeTable, setPickerDisplayModeTable] = useState<boolean>(
    true
  );

  /* {
       "Local": "",
       "Google": "yo29.kjdksjdksdjksdjksdjskdjksdjskjd",
       "Microsoft": "ABCDEmicrosfottokensdkjdsljdksjdksdj"
    } */
  const {
    state: statePicker,
    setPickerMainCategory,
    setPickerSubCategory,
  } = usePicker();

  const themeRelatedTextClass = getThemeRelatedTextClass(theme);
  const intl = useIntl();

  const {
    state: filesState,
    isLoading: loadingFiles,
    isLoadingMore: loadingMore,
    canLoadMore: canLoadMoreFiles,
    currentFolder,
    search,
    addFolder,
    clearSearch,
    goToParentFolder,
    goToFolder,
    goToFolderId,
    getFiles,
    folderInfo,
  } = useFiles(provider, onlyFolders, onlyCanAddChildren);

  const handleSelectedRows = (rows: Array<any>) => {
    if (!onPick) {
      return;
    }
    if (rows.length) {
      onPick({ provider: provider.name, item: rows[0] });
    } else if (currentFolder && currentFolder.id) {
      onPick({ provider: provider.name, item: currentFolder });
    } else {
      onPick({ provider: provider.name, item: folderInfo('root') });
    }
  };

  useEffect(() => {
    if (mainCategory) {
      setPickerMainCategory(mainCategory);
    }

    if (subCategory) {
      setPickerSubCategory(subCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedSearchText) {
      search(debouncedSearchText, currentFolder.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  useEffect(() => {
    if (rootFolder && filesState.selectedPickerSubCategory) {
      goToFolderId(rootFolder);
      getFiles();
    }
    if (!rootFolder && filesState.selectedPickerSubCategory) {
      getFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootFolder, statePicker.selectedPickerSubCategory]);

  useInfiniteScroll(parentWin, () => {
    if (
      !loadingFiles &&
      !loadingMore &&
      filesState.files &&
      filesState.files.length > 0
    ) {
      if (canLoadMoreFiles) {
        // Get next page
        getFiles(null, true);
      }
    }
  });

  if (!provider) {
    return <></>;
  }

  // If we have a rootFolder, we should not be able to go to parents folders
  const hasParent =
    currentFolder && currentFolder.parentId && currentFolder.id !== rootFolder;

  let title, backBtnAction;
  if (filesState?.query) {
    title = intl.formatMessage(
      { id: 'drivepicker.search.results' },
      { query: filesState?.query }
    );
    backBtnAction = () => clearSearch(currentFolder);
  } else {
    title = (
      <>
        {currentFolder && currentFolder.name && currentFolder.name}
        {(!currentFolder || !currentFolder.name) &&
          filesState.selectPickerCategInfos?.label}
        {currentFolder && currentFolder.url && (
          <Tooltip
            label={intl.formatMessage({ id: 'drivepicker.button.open' })}
          >
            <IconButton
              theme={theme}
              emphasis={Emphasis.low}
              icon={mdiOpenInNew}
              onClick={() => window.open(currentFolder.url)}
            />
          </Tooltip>
        )}
      </>
    );
    backBtnAction = () => goToParentFolder();
  }

  const tableTitleNav = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {hasParent || filesState?.query ? (
        <IconButton
          icon={mdiChevronLeft}
          emphasis={Emphasis.low}
          onClick={backBtnAction}
          theme={theme}
        />
      ) : (
        <Icon
          icon={mdiFolder}
          size={Size.s}
          className="lumx-spacing-margin-left-regular"
          theme={theme}
        />
      )}
      <span className={`drive-picker-title-span ${themeRelatedTextClass}`}>
        {title}
      </span>
    </div>
  );
  // we disable the create button if we are in a search result state or an unauthorized state
  const toolbarAdditionalElements = [
    // <NewFolder
    //   key="toolbar_elem_newfolder"
    //   theme={theme}
    //   callback={handleAddFolder}
    //   isDisabled={isCreateDisabled}
    // />,
  ];
  if (withGallery) {
    toolbarAdditionalElements.push(
      <IconButton
        key="toolbar_elem_switchview"
        theme={theme}
        emphasis={Emphasis.low}
        icon={mdiViewGrid}
        onClick={() => setPickerDisplayModeTable(!pickerDisplayModeTable)}
      />
    );
  }

  return (
    <>
      <div className="drive-picker-main">
        {filesState.selectedPickerMainCateg !== 'local' &&
          filesState.selectedPickerSubCategory && (
            <>
              {loadingFiles && (
                <div className="centered-div">
                  <Progress theme={theme} />
                </div>
              )}
              {!loadingFiles && (
                <>
                  {pickerDisplayModeTable && (
                    <>
                      <DataTable
                        theme={theme}
                        tableData={filesState?.files || []}
                        tableHeaders={createTableHeaders(
                          goToFolder,
                          provider.name
                        )}
                        title={tableTitleNav}
                        options={{
                          paginated: true,
                          search: true,
                          mandatoryFilters: onlyFolders
                            ? [{ value: true, headerName: 'isFolder' }]
                            : [],
                          serverSide: true,
                          rowsPerPage: rowsPerPage,
                          toolbarAdditionalElements,
                          selectableRows: selectableRows,
                          onRowSelected: handleSelectedRows,
                          customRowRender: (data: any) =>
                            getBodyStructure(
                              data,
                              provider.name,
                              goToFolder,
                              theme
                            ),
                          noDataFiller: <EmptyState theme={theme} />,
                          topTableBorder: true,
                          bottomTableBorder: true,
                        }}
                        onSearchCallBack={search}
                      />
                    </>
                  )}
                  {!pickerDisplayModeTable && (
                    <IconFilesList
                      files={filesState?.files || []}
                      setChoosenFile={setChoosenFile}
                      loadFolderFiles={goToFolder}
                      loadingMore={loadingMore}
                      options={{
                        onlyFolders,
                      }}
                      theme={theme}
                    />
                  )}
                </>
              )}
            </>
          )}
      </div>
    </>
  );
};

export default BaseDriveTable;
