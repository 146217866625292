/* eslint-disable global-require */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-undef */
/* eslint-disable block-scoped-var */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import '../node_modules/@lumx/core/lumx-theme-lumapps.css';
import { StoreProvider, createStore } from 'easy-peasy';

import { Switch } from '@lumx/react';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

import { store, storeConfig } from './store';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './ErrorBoundary';
import App from './App';

import 'dayjs/locale/fr';
import 'dayjs/locale/es';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const supportedLangs = ['en', 'es', 'fr'];
// eslint-disable-next-line no-nested-ternary
let defaultLocale = (window as any).CONNECTED_USER
  ? (window as any).CONNECTED_USER.lang
  : navigator.language
  ? navigator.language.split('-')[0]
  : 'en';
defaultLocale = supportedLangs.includes(defaultLocale) ? defaultLocale : 'en';
dayjs.locale(defaultLocale);

if (process.env.NODE_ENV === 'development') {
  // T avoid including it in the production build
  var { makeServer } = require('mockServer/server');
  var MOCK_SERVER: any = null;
}

let mainStore: any = createStore(store, storeConfig);
const MainApp = () => (
  <StoreProvider store={mainStore}>
    <App />
  </StoreProvider>
);

const DevApp = () => {
  const [mockServer, setMockServer] = useState<any>(
    process.env.NODE_ENV === 'development'
  );

  const setupEnv = () => {
    if (mockServer && MOCK_SERVER) {
      MOCK_SERVER.shutdown();
      MOCK_SERVER = null;
      mainStore = createStore(store, storeConfig);
      setMockServer(false);
    } else {
      MOCK_SERVER = makeServer();
      mainStore = createStore(store, storeConfig);
      setMockServer(true);
    }
  };

  useEffect(() => {
    setupEnv();
  }, []);

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <div style={{ zIndex: 40000, position: 'fixed', top: 0 }}>
          <Switch
            checked={mockServer}
            onToggle={() => {
              setupEnv();
            }}
          >
            Dev Mode
          </Switch>
        </div>
      )}
      <MainApp />
    </>
  );
};
if (process.env.NODE_ENV === 'development') {
  ReactDOM.render(
    <ErrorBoundary>
      <DevApp />
    </ErrorBoundary>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <ErrorBoundary>
      <MainApp />
    </ErrorBoundary>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
