import React, { useState } from 'react';

import { Message, MessageKind, Progress, ProgressVariant } from '@lumx/react';

import SimpleSelect from 'components/LumxWrappers/SimpleSelect';
import { useStoreActions, useStoreState } from 'hooks';
import './userImportParams.css';
import { FormattedMessage } from 'react-intl';

const UserImportParams = () => {
  const userSites = useStoreState((state) => state.user.userLumappsSites);

  const {
    setAvailableImportDirectories,
    setImportUserDirectorySite,
    fetchAvailableImportDirectories: fetchSiteDirectories,
    setImportUserDirectory,
  } = useStoreActions((actions) => actions.workflow.import.user);

  const {
    availableImportDirectories,
    loadingAvailableImportDirectories,
  } = useStoreState((actions) => actions.workflow.import.user);

  const [choosenSite, setChoosenSite] = useState();

  const [choosenDirectory, setChoosenDirectory] = useState();

  const getSiteDirectories = async (siteId: string) => {
    await fetchSiteDirectories(siteId);
  };

  const handleDirectorySiteChoice = (choice: any) => {
    if (choice) {
      setChoosenSite(choice.displayName);
      setImportUserDirectorySite({
        id: choice.value,
        name: choice.name,
      });
      getSiteDirectories(choice.value);
    } else {
      // Clear select
      setChoosenSite(undefined);
      setImportUserDirectorySite({
        id: undefined,
        name: undefined,
      });
      setAvailableImportDirectories(undefined);
    }
  };

  const handleDirectoryChoice = (choice: any) => {
    if (choice) {
      setChoosenDirectory(choice.displayName);
      setImportUserDirectory({ id: choice.value, name: choice.name });
    } else {
      // Clear select
      setAvailableImportDirectories(undefined);
    }
  };

  const directories = availableImportDirectories?.map((directory: any) => ({
    value: directory.id,
    displayName: directory.name,
    name: directory.name,
  }));
  const sites: any = userSites?.map((site: any) => ({
    value: site.id,
    displayName: site.name,
    name: site.name,
  }));
  return (
    <div className="tius2-main-container">
      <Message kind={MessageKind.info} hasBackground>
        <p>
          <FormattedMessage id="USER_IMPORT_DIRECTORY_CHOICE_INFO_MESSAGE" />
        </p>
      </Message>
      <SimpleSelect
        label="Site choice"
        choices={sites}
        value={choosenSite}
        setValue={handleDirectorySiteChoice}
      />
      {loadingAvailableImportDirectories && (
        <Progress variant={ProgressVariant.linear} />
      )}
      {!loadingAvailableImportDirectories && availableImportDirectories && (
        <div className="tius2-directory-select-container ">
          <SimpleSelect
            label="Directory choice"
            choices={directories}
            value={choosenDirectory}
            setValue={handleDirectoryChoice}
          />
        </div>
      )}
    </div>
  );
};

export default UserImportParams;
