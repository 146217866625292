import React from 'react';

const SubRadioButtonContentWrapper: React.FC = ({ children }: any) => {
  return (
    <div className="lumx-spacing-margin-bottom-big lumx-spacing-margin-left-big">
      {children}
    </div>
  );
};

export default SubRadioButtonContentWrapper;
