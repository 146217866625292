/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useMemo, useState } from 'react';
import { Provider, ProviderName } from '../models';
import { pickerStructure } from '../pickerStructure';
import {
  boxDriveService,
  gdriveService,
  microsoftDriveService,
} from '../services';
import { DriveServiceOptions } from '../services/interface';
import {
  setFiles,
  setQuery,
  setSelectedPickerMainCategory,
  setSelectedPickerSubCategory,
  setSelectPickerCategInfos,
} from './actions';
import { useStateValue } from './index';

const getService = (mainCateg: string) => {
  if (mainCateg === ProviderName.Google) {
    return gdriveService;
  }
  if (mainCateg === ProviderName.Microsoft) {
    return microsoftDriveService;
  }
  if (mainCateg === ProviderName.Box) {
    return boxDriveService;
  }
  return undefined;
};

const usePicker = () => {
  const [{ drive: state }, dispatch]: any = useStateValue();

  const setPickerMainCategory = (
    category: string | undefined,
    infos: any = undefined
  ) => {
    dispatch(setSelectedPickerMainCategory(category));
    // dispatch(setSelectPickerCategInfos(infos));
  };

  const setPickerSubCategory = (
    category: string | undefined,
    infos: any = undefined
  ) => {
    dispatch(setSelectedPickerSubCategory(category));
    dispatch(setSelectPickerCategInfos(infos));
  };

  return {
    state,
    setPickerMainCategory,
    setPickerSubCategory,
  };
};

const useFiles = (
  provider: Provider,
  onlySelectFolder: boolean = false,
  onlyCanAddChildren: boolean = false
) => {
  const [{ drive: state }, dispatch]: any = useStateValue();

  const [currentFolder, setCurrentFolder] = useState<any>(undefined);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const {
    selectedPickerMainCateg: mainCateg,
    selectedPickerSubCategory: subCateg,
  } = state;

  // @ts-ignore
  const allCurrentCategInfos: any = pickerStructure[mainCateg] || {};

  const service = useMemo(() => {
    let serv: any = getService(mainCateg);

    const options: DriveServiceOptions = {
      onlyFolders: onlySelectFolder,
      onlyCanAddChildren,
    };
    // eslint-disable-next-line new-cap
    serv = serv
      ? new serv(
          provider.token,
          provider.lumappsBaseUrl,
          provider.serverRequestUrl,
          provider.name,
          options
        )
      : undefined;
    return serv;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCateg]);

  // @ts-ignore
  let canLoadMore = Boolean(service && service.currentCursor);

  /*
   *  Get files hooks
   */
  const getFiles = async (
    folder: any | null = null,
    nextPage: boolean = false
  ) => {
    if ((nextPage && isLoadingMore) || (nextPage && !canLoadMore)) {
      return;
    }

    nextPage ? setIsLoadingMore(true) : setIsLoading(true);

    if (folder) {
      setCurrentFolder(folder);
    } else {
      setCurrentFolder({ id: 'root' });
    }

    const subCategInfos = (allCurrentCategInfos?.children || []).find(
      (el: any) => el.id === subCateg
    );

    if (!service || !subCategInfos) {
      nextPage ? setIsLoadingMore(false) : setIsLoading(false);
      return;
    }

    let files =
      (await service[subCategInfos.method](
        folder?.id,
        nextPage,
        provider.additionalParams
      )) || [];

    dispatch(setFiles(nextPage ? [...state.files, ...files] : files));

    canLoadMore = Boolean(service && service.currentCursor);
    nextPage ? setIsLoadingMore(false) : setIsLoading(false);
  };

  /*
   *  Search
   */
  const search = async (searchText: string, folderId: string | null = null) => {
    setIsLoading(true);

    if (!service) {
      setIsLoading(false);
      return;
    }
    const files = await service[allCurrentCategInfos.searchFilesByNameMethod](
      searchText,
      folderId
    );
    if (files) {
      dispatch(setFiles(files));
    }
    dispatch(setQuery(searchText));
    canLoadMore = Boolean(service && service.currentCursor);
    setIsLoading(false);
  };

  /*
   *  Add folder
   */
  const addFolder = async (
    folderName: string,
    folderId: string | null = null
  ) => {
    const subCategInfos = (allCurrentCategInfos?.children || []).find(
      (el: any) => el.id === subCateg
    );

    if (!service || !subCategInfos) {
      return;
    }
    const res = await service[subCategInfos.addFolder](folderName, folderId);

    if (res.success) {
      getFiles(currentFolder, false);
    }

    return res;
  };

  /*
   * Clear search
   */
  const clearSearch = (folder: any) => {
    if (!folder) {
      return;
    }
    dispatch(setQuery(undefined));
    getFiles(folder, false);
  };

  /*
   * Go to the specified folder
   */
  const goToFolder = (folder: any) => {
    if (!folder) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    folder.parent = currentFolder;

    getFiles(folder, false);
    setCurrentFolder(folder);
  };

  const goToFolderId = async (folderId: string | undefined) => {
    if (!folderId) {
      return;
    }

    const folder = await folderInfo(folderId);

    goToFolder(folder);
  };

  const goToParentFolder = () => {
    const folderParent = currentFolder.parent;
    if (!currentFolder || !folderParent) {
      console.warn('No current folder or folder parent', folderParent);
      return;
    }

    getFiles(currentFolder.parent);
  };

  const folderInfo = async (folderId: string | undefined) => {
    if (!folderId) {
      return undefined;
    }

    const subCategInfos = (allCurrentCategInfos?.children || []).find(
      (el: any) => el.id === subCateg
    );

    if (!service || !subCategInfos || !service[subCategInfos.folderInfo]) {
      return undefined;
    }
    const folderInfo = await service[subCategInfos.folderInfo](folderId);

    const folder = {
      id: folderId,
      name: folderInfo ? folderInfo.name : '',
      url: folderInfo && folderInfo.url ? folderInfo.url : '',
      updatedAt: folderInfo ? folderInfo.updatedAt : '',
    };
    return folder;
  };

  return {
    isLoading,
    getFiles,
    state,
    search,
    clearSearch,
    isLoadingMore,
    currentFolder,
    goToParentFolder,
    goToFolder,
    goToFolderId,
    canLoadMore,
    addFolder,
    folderInfo,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFiles, usePicker };
