import axios, { AxiosError, AxiosInstance } from 'axios';
import { ProviderName } from '../models';
import { DriveFile, DriveServiceOptions } from './interface';


class gdriveService {
  token: string;

  onlyFolders: boolean;

  currentCursor: string | null;

  api: AxiosInstance;

  onlyCanAddChildren: boolean;

  constructor(
    token: string,
    lumappsBaseUrl: string | undefined,
    serverRequestUrl: string | undefined = undefined,
    provider?: ProviderName,
    options?: DriveServiceOptions
  ) {
    this.token = token;
    this.onlyFolders = options?.onlyFolders || false;
    this.currentCursor = null;
    this.api = this.createApiInstance(token);
    this.onlyCanAddChildren = options?.onlyCanAddChildren || false;
  }

  createApiInstance = (token: string) => {
    const api = axios.create({
      baseURL: 'https://www.googleapis.com/drive/v3/',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    api.interceptors.response.use(
      (response: any) => {
        this.currentCursor = response?.data?.nextPageToken || null;
        return response;
      },
      (error: AxiosError) => error.response
    );
    return api;
  };

  hydrateFiles = (files: any) => {
    // https://developers.google.com/drive/api/v3/reference/files#resource
    const hydrated = files
      ? files
          .map((el: any) => this.fileItemTransform(el))
          .filter(Boolean)
          .sort((a: any, b: any) => -(Number(a.isFolder) - Number(b.isFolder)))
      : null;
    return hydrated;
  };

  // eslint-disable-next-line class-methods-use-this
  fileItemTransform(fileItem: any): DriveFile | null {
    if (!fileItem) {
      return null;
    }
    const parentId = fileItem?.parents ? fileItem?.parents[0] : null;
    const owner =
      fileItem.owners && fileItem.owners.length > 0
        ? fileItem.owners[0]?.emailAddress || 'Unknown'
        : 'Unknown';

    if (this.onlyFolders && !fileItem?.capabilities?.canAddChildren) {
      return null;
    }

    return {
      id: fileItem.id,
      parentId,
      name: fileItem.name,
      isFolder: fileItem.mimeType === 'application/vnd.google-apps.folder',
      imageLink: fileItem.thumbnailLink,
      createdAt: new Date(fileItem.createdTime).toDateString(),
      createdBy: owner,
      updatedAt: new Date(fileItem.modifiedTime).toDateString(), //TODO
      updatedBy: '',
      size: fileItem.size,
      isWebLink: false,
      details: fileItem,
    };
  }

  getFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false,
    additionalParams: string | null = null,
    useQAsMainQuery: boolean = false,
    otherParams: any = {}
  ) => {
    if (nextPage && !this.currentCursor) {
      return;
    }

    let baseQuery = folderId ? `'${folderId}' in parents` : "'root' in parents";
    baseQuery += ' and trashed = false';
    if (this.onlyFolders) {
      baseQuery += " and mimeType='application/vnd.google-apps.folder'";
    }

    const q = additionalParams
    // eslint-disable-next-line no-nested-ternary
    const query = useQAsMainQuery ? q : q ? `${baseQuery} and ${q}` : baseQuery;

    const params = {
      q: query,
      orderBy: 'name',
      spaces: 'drive',
      pageSize: 60,
      fields: '*',
      pageToken: nextPage ? this.currentCursor : null,
      ...otherParams,
    };

    const { data, status } = await this.api.get('files', { params });
    if (status === 200 && data.files) {
      // eslint-disable-next-line consistent-return
      return this.hydrateFiles(data.files);
    }
    // eslint-disable-next-line consistent-return
    return [];
  };

  getMyFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false,
    additionalParams: any = undefined
  ) => {
    return this.getFiles(folderId, nextPage, additionalParams);
  };

  getSharedWithMeFiles = async (
    folderId: string | null = null,
    nextPage: boolean = false
  ) => {
    const q = 'sharedWithMe';
    return this.getFiles(folderId, nextPage, q, true, {
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
    });
  };

  searchFilesByName = async (searchText: string) => {
    const q = `name contains '${searchText}'`;
    // Force to not filter on root to searhc accross all files
    // and not only in root
    return this.getFiles(null, false, q, true);
  };
}

export default gdriveService;
