import React from 'react'

import { FormattedMessage } from 'react-intl'

import { List, ListItem, ListSubheader } from '@lumx/react'

import { useStoreState } from 'hooks'

const PickedFileSummary: React.FC = () => {
    const { pickedFile, provider } = useStoreState(
        state => state.workflow.import.common
    )
    return (
        <List>
            <ListSubheader>
                <FormattedMessage id="CHOOSEN_FILE" />
            </ListSubheader>
            <ListItem>
                <div>{pickedFile?.displayName}</div>
            </ListItem>
            <ListSubheader>
                <FormattedMessage id="PROVIDER" />
            </ListSubheader>
            <ListItem>
                <div>{provider}</div>
            </ListItem>
        </List>
    )
}

export default PickedFileSummary
