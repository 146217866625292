import React from 'react';

import {
  Alignment,
  ColorPalette,
  Emphasis,
  FlexBox,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  Orientation,
  Size,
  TableCell,
  Theme,
  ThScope,
} from '@lumx/react';
import {
  mdiBox,
  mdiCached,
  mdiGoogle,
  mdiMicrosoft,
  mdiFolder,
  mdiOpenInNew,
  mdiLinkVariant,
  mdiFileImage,
  mdiFilePdfBox,
  mdiFileDocumentBox,
  mdiFilePresentationBox,
  mdiZipBox,
  mdiFileTable,
  mdiVolumeHigh,
  mdiFile,
  mdiMovie,
} from '@lumx/icons';

import { ProviderName } from './models';
import { getThemeRelatedTextClass } from '@lumapps-extensions-playground/common';
import { FormattedMessage } from '@lumapps-extensions-playground/translations';

export const pickerStructure = {
  [ProviderName.Local]: {
    label: 'Local',
    id: ProviderName.Local,
    icon: mdiCached,
    children: null,
  },
  [ProviderName.Google]: {
    label: 'Google',
    id: ProviderName.Google,
    icon: mdiGoogle,
    service: 'lumappsDriveService',
    searchFilesByNameMethod: 'searchFilesByName',
    children: [
      {
        label: <FormattedMessage id="drivepicker.section.my_files" />,
        id: 'googleMyFiles',
        method: 'getMyFiles',
      },
      {
        label: <FormattedMessage id="drivepicker.section.shared_with_me" />,
        id: 'googleSharedDrive',
        method: 'getSharedWithMeFiles',
      },
    ],
  },
  [ProviderName.Microsoft]: {
    label: 'Micro',
    id: ProviderName.Microsoft,
    icon: mdiMicrosoft,
    service: 'microsoftDriveService',
    searchFilesByNameMethod: 'searchFilesByName',
    children: [
      {
        label: 'One drive',
        id: 'microsoftOnedrive',
        method: 'getMyFiles',
        icon: mdiMicrosoft,
      },
      // {
      //   label: 'Shared with me',
      //   id: 'microsoftOnedriveShared',
      //   method: 'getSharedWithMeFiles',
      // },
    ],
  },
  [ProviderName.Box]: {
    label: 'Box',
    id: ProviderName.Box,
    icon: mdiBox,
    service: 'boxDriveService',
    searchFilesByNameMethod: 'searchFilesByName',
    children: [
      {
        label: 'All Files',
        id: 'boxAllFiles',
        method: 'getMyFiles',
        addFolder: 'addFolder',
        folderInfo: 'folderInfo',
        icon: mdiBox,
      },
    ],
  },
};

export const createTableHeaders = (
  goToFolder: any,
  provider: ProviderName
): Array<any> => {
  if (
    [ProviderName.Box, ProviderName.Google, ProviderName.Microsoft].includes(
      provider
    )
  ) {
    // no header for Box
    return [];
  } else {
    // TODO: missing label translations
    return [
      {
        isSortable: true,
        label: 'Name',
        name: 'name',
        scope: ThScope.col,
        searchable: true,
      },
      {
        isSortable: false,
        label: '',
        name: '',
        scope: ThScope.col,
        searchable: false,
      },
      {
        isSortable: true,
        label: 'Created by',
        name: 'createdBy',
        scope: ThScope.col,
        searchable: false,
      },
      {
        isSortable: true,
        label: 'Created at',
        name: 'createdAt',
        scope: ThScope.col,
        searchable: false,
      },
    ];
  }
};

const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

const getFileIcon = (extension: string) => {
  const ICON: any = {
    ARCHIVE: mdiZipBox,
    AUDIO: mdiVolumeHigh,
    IMAGE: mdiFileImage,
    PRESENTATION: mdiFilePresentationBox,
    TABLE: mdiFileTable,
    DOCUMENT: mdiFileDocumentBox,
    VIDEO: mdiMovie,
    PDF: mdiFilePdfBox,
    FILE: mdiFile,
  };

  const ARCHIVE = [
    '7z',
    'arj',
    'deb',
    'pkg',
    'rar',
    'rpm',
    'tar.gz',
    'z',
    'zip',
  ];
  const AUDIO = [
    'aif',
    'cda',
    'mid',
    'midi',
    'mp3',
    'mpa',
    'ogg',
    'wav',
    'wma',
    'wpl',
  ];
  const IMAGE = [
    'ai',
    'bmp',
    'gif',
    'ico',
    'jpeg',
    'jpg',
    'png',
    'ps',
    'psd',
    'svg',
    'tif',
    'tiff',
  ];
  const PRESENTATION = [
    'key',
    'odp',
    'pps',
    'ppt',
    'pptx',
    'application/vnd.google-apps.spreadsheet',
  ];
  const TABLE = ['ods', 'xls', 'xlsm', 'xlsx'];
  const DOCUMENT = [
    'doc',
    'docx',
    'odt',
    'pdf',
    'rtf',
    'tex',
    'txt',
    'wpd',
    'application/vnd.google-apps.document',
  ];
  const VIDEO = [
    '3g2',
    '3gp',
    'avi',
    'flv',
    'h264',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpg',
    'mpeg',
    'rm',
    'swf',
    'vob',
    'wmv',
  ];

  if (ARCHIVE.includes(extension)) {
    return ICON.ARCHIVE;
  } else if (AUDIO.includes(extension)) {
    return ICON.AUDIO;
  } else if (IMAGE.includes(extension)) {
    return ICON.IMAGE;
  } else if (PRESENTATION.includes(extension)) {
    return ICON.PRESENTATION;
  } else if (TABLE.includes(extension)) {
    return ICON.TABLE;
  } else if (DOCUMENT.includes(extension)) {
    return ICON.DOCUMENT;
  } else if (VIDEO.includes(extension)) {
    return ICON.VIDEO;
  } else if (extension === 'pdf') {
    return ICON.PDF;
  } else {
    return ICON.FILE;
  }
};

export const getBodyStructure = (
  data: any,
  provider: ProviderName,
  goToFolder: any,
  theme: Theme
) => {
  switch (provider) {
    case ProviderName.Box:
      return getBoxBody(data, goToFolder, theme);
    case ProviderName.Google:
      return getGoogleOrMicrosoftFileBody(data, goToFolder, theme);
    case ProviderName.Microsoft:
      return getGoogleOrMicrosoftFileBody(data, goToFolder, theme);
    default:
      return null;
  }
};

const getBoxBody = (data: any, goToFolder: any, theme: Theme) => {
  const {
    details: { name = '', size = 0, extension = '' } = {},
    updatedAt,
    isFolder,
    isWebLink,
    url,
  } = data;

  const themeRelatedTextClass = getThemeRelatedTextClass(theme);

  let title = <span>{name}</span>;
  let icon: any;
  let description;
  if (isFolder) {
    icon = mdiFolder;
    title = (
      <Link
        color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
        onClick={() => goToFolder(data)}
      >
        {name}
      </Link>
    );
    description = <span>{updatedAt}</span>;
  } else if (isWebLink) {
    icon = mdiLinkVariant;
  } else {
    icon = getFileIcon(extension);
    description = (
      <span>
        {humanFileSize(size)} &middot; {updatedAt}
      </span>
    );
  }

  const before = <Icon theme={theme} hasShape icon={icon} size={Size.m} />;

  return (
    <List style={{ padding: '0 14px 0 18px' }} theme={theme}>
      <ListItem
        size={Size.big}
        before={before}
        after={
          <IconButton
            theme={theme}
            emphasis={Emphasis.low}
            icon={mdiOpenInNew}
            onClick={() => window.open(url)}
          />
        }
      >
        <div className={themeRelatedTextClass}>{title}</div>
        <div className={themeRelatedTextClass}>{description}</div>
      </ListItem>
    </List>
  );
};

const getGoogleOrMicrosoftFileBody = (
  data: any,
  goToFolder: any,
  theme: Theme
) => {
  const {
    details: { size = 0 } = {},
    updatedAt,
    isFolder,
    isWebLink,
    extension,
    name,
    url,
  } = data;

  const themeRelatedTextClass = getThemeRelatedTextClass(theme);

  let title = <span>{name}</span>;
  let icon: any;
  let description;
  if (isFolder) {
    icon = mdiFolder;
    title = (
      <Link
        color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
        onClick={() => goToFolder(data)}
      >
        {name}
      </Link>
    );
    description = <span>{updatedAt}</span>;
  } else if (isWebLink) {
    icon = mdiLinkVariant;
  } else {
    icon = getFileIcon(extension);
    description = (
      <span>
        {humanFileSize(size)} &middot; {updatedAt}
      </span>
    );
  }
  const before = <Icon theme={theme} hasShape icon={icon} size={Size.m} />;

  return (
    <TableCell>
      <FlexBox
        style={{ padding: '0 14px 0 18px' }}
        gap={Size.big}
        hAlign={Alignment.center}
        orientation={Orientation.horizontal}
      >
        <div className="lumx-list-item__before">{before}</div>

        <div className="lumx-list-item__content">
          <div className={themeRelatedTextClass}>{title}</div>
          <div className={themeRelatedTextClass}>{description}</div>
        </div>

        <IconButton
          theme={theme}
          emphasis={Emphasis.low}
          icon={mdiOpenInNew}
          onClick={() => window.open(url)}
        />
      </FlexBox>
    </TableCell>
  );
};
