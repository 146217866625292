/* eslint-disable no-param-reassign */
import { action, Action, ActionOn, actionOn, Thunk, thunk } from 'easy-peasy';
import { PlanifDTO } from 'models/dtos';
import { IInjections, IStore } from 'store';

interface IPlanificationParams {
  nextExecutionDate: string;
  interval: string;
  maxExecutionsNb: string;
}

const basePlanifParams = {
  nextExecutionDate: '',
  interval: '1',
  maxExecutionsNb: '0',
};

export interface PlanificationStore {
  isPlanified: boolean | undefined;
  setIsPlanified: Action<PlanificationStore, boolean>;

  planificationParams: IPlanificationParams;
  setInterval: Action<PlanificationStore, string>;
  setMaxExecutionsNb: Action<PlanificationStore, string>;
  setNextExecutionDate: Action<PlanificationStore, string>;

  isIntervalValid: boolean;
  setIsIntervalValid: Action<PlanificationStore, boolean>;

  isMaxExecutionsNbValid: boolean;
  setIsMaxExecutionsNbValid: Action<PlanificationStore, boolean>;

  preparePlanifDTO: Thunk<PlanificationStore, void, IInjections, IStore>;
  validateStep: Thunk<PlanificationStore, any, IInjections, IStore>;
  endWorkflow: ActionOn<PlanificationStore, IStore>;
}

const planificationStore: PlanificationStore = {
  isPlanified: false,
  setIsPlanified: action((state, isPlanified) => {
    state.isPlanified = isPlanified;
  }),

  planificationParams: basePlanifParams,
  setInterval: action((state, interval) => {
    state.planificationParams.interval = interval;
  }),
  setMaxExecutionsNb: action((state, nb) => {
    state.planificationParams.maxExecutionsNb = nb;
  }),
  setNextExecutionDate: action((state, date) => {
    state.planificationParams.nextExecutionDate = date;
  }),

  isIntervalValid: true,
  setIsIntervalValid: action((state, isValid) => {
    state.isIntervalValid = isValid;
  }),

  isMaxExecutionsNbValid: true,
  setIsMaxExecutionsNbValid: action((state, isValid) => {
    state.isMaxExecutionsNbValid = isValid;
  }),

  preparePlanifDTO: thunk(
    (actions, _, { getState }): PlanifDTO => {
      const state = getState();
      const dto: PlanifDTO = {
        recurrent: Boolean(state.isPlanified),
        nextExecutionDate: state.planificationParams.nextExecutionDate,
        dateTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        interval: state.planificationParams.interval,
        maxExecutionsNb: state.planificationParams.maxExecutionsNb,
        currentExecutionsNb: '0',
      };
      return dto;
    }
  ),

  validateStep: thunk((actions, stepNumber, { getStoreActions, getState }) => {
    const state = getState();
    let success = true;
    switch (stepNumber) {
      case 1:
        if (!state.isMaxExecutionsNbValid || !state.isIntervalValid || !state.planificationParams.nextExecutionDate) {
          success = false;
        }
        return success;
      default:
        return success;
    }
  }),

  endWorkflow: actionOn(
    (actions, storeActions) => storeActions.workflow.modal.endWorkflow,
    (state) => {
      state.planificationParams = basePlanifParams;
      state.isPlanified = undefined;
      state.isIntervalValid = true;
      state.isMaxExecutionsNbValid = true;
    }
  ),
};

export default planificationStore;
