/* eslint-disable @typescript-eslint/no-unused-expressions */
import { mdiClose } from '@lumx/icons';
import {
  Button,
  Emphasis,
  Dialog,
  Progress,
  ProgressVariant,
  Toolbar,
  Size,
  IconButton,
} from '@lumx/react';
import { useKeyPress, useStoreActions, useStoreState } from 'hooks';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'Theme';
import ExportWorkflow from './export';
import ImportWorkflow from './import';
import WorkflowStepper from './WorkflowStepper';
import WorkflowTypeChoice from './WorkflowTypeChoice';

const WorkflowDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { theme } = useTheme();
  const workflowState = useStoreState((state) => state.workflow.modal);
  const { endWorkflow, previousStep, nextStep } = useStoreActions(
    (actions) => actions.workflow.modal,
  );

  useEffect(() => {
    (workflowState
      && workflowState.category
      && workflowState.currentStep >= 0
      && workflowState.totalSteps
      && workflowState.totalSteps > 0
      && workflowState.type)
    || workflowState.currentStep === -1
      ? setIsOpen(true)
      : setIsOpen(false);
  }, [workflowState]);

  const OnDialogClose = useCallback(() => {
    endWorkflow();
    setIsOpen(false);
  }, [endWorkflow]);

  useEffect(() => {
    if (isOpen === false) {
      OnDialogClose();
    }
  }, [isOpen, OnDialogClose]);

  const escapeKeyPressedIE = useKeyPress('Esc');
  const escapeKeyPressed = useKeyPress('Escape');

  useEffect(() => {
    if (isOpen) {
      OnDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escapeKeyPressed, escapeKeyPressedIE]);

  const dialogTopBar = useMemo(
    () => (
      <>
        {workflowState.currentStep === -1 ? null : (
          <Toolbar
            before={(
              <span
                className="lumx-typography-title"
              >
                <FormattedMessage id={`${workflowState?.type?.toUpperCase()}_OF_${workflowState?.category?.toUpperCase()}`} />
              </span>
            )}
            after={(
              <IconButton
                emphasis={Emphasis.low}
                onClick={() => setIsOpen(false)}
                icon={mdiClose}
              />
            )}
          />
        )}
      </>
    ),
    [workflowState],
  );

  /*
   * Handle switching to next step
   */

  const footerBtns = useMemo(() => {
    if (
      workflowState.currentStep === -1
    ) {
      // First step of export workflow, no toolbar.
      return null;
    }

    const isLastStep = workflowState.totalSteps
      && workflowState.currentStep === workflowState.totalSteps - 1;

    return (
      <Toolbar
        style={{ borderTop: '1px solid #eee' }}
        before={(
          <Button
            disabled={workflowState.currentStep === 0}
            theme={theme.theme}
            emphasis={Emphasis.low}
            onClick={() => previousStep()}
          >
            <FormattedMessage id="PREVIOUS" />
          </Button>
        )}
        after={(
          <>
            <Button
              theme={theme.theme}
              emphasis={Emphasis.low}
              onClick={() => setIsOpen(false)}
            >
              <FormattedMessage id="CANCEL" />
            </Button>

            <Button
              theme={theme.theme}
              emphasis={isLastStep ? Emphasis.high : Emphasis.low}
              className="lumx-spacing-margin-left-regular"
              onClick={() => nextStep()}
              disabled={workflowState.isLoading}
            >
              {isLastStep ? (
                <FormattedMessage id="SAVE" />
              ) : (
                <FormattedMessage id="NEXT" />
              )}
            </Button>
          </>
        )}
      />
    );
  }, [
    workflowState.currentStep,
    workflowState.totalSteps,
    workflowState.type,
    workflowState.currentStep,
    workflowState.isLoading,
    theme.theme,
    nextStep,
    previousStep,
  ]);

  return (
    <Dialog
      zIndex={1000}
      theme={theme}
      isOpen={isOpen}
      onClose={OnDialogClose}
      preventAutoClose
      header={dialogTopBar}
      footer={footerBtns}
      size={workflowState.currentStep === -1 ? Size.big : Size.big}
    >
      <>
        {workflowState.currentStep === -1 && (
          <WorkflowTypeChoice onClose={() => setIsOpen(false)} />
        )}
        {workflowState.currentStep >= 0 && (
          <>
            {/* Stepper */}
            <div
              className="lumx-spacing-padding-horizontal-huge"
              style={{ borderBottom: '1px solid #eee' }}
            >
              <WorkflowStepper />
            </div>

            {workflowState.isLoading && (
              <Progress variant={ProgressVariant.linear} />
            )}

            {/* Workflow Content */}
            {/* Export or import flow */}
            {workflowState.type === 'export' && <ExportWorkflow />}
            {workflowState.type === 'import' && <ImportWorkflow />}
          </>
        )}
      </>
    </Dialog>
  );
};

export default WorkflowDialog;
