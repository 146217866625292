import React from 'react';

import { FormattedMessage } from 'react-intl';

interface SubChoiceFormmatedNameProps {
  id: string;
}

const SubChoiceFormmatedName: React.FC<SubChoiceFormmatedNameProps> = ({
  id,
}: SubChoiceFormmatedNameProps) => (
  <>
    {' > '}
    <FormattedMessage id={id} />
  </>
);

export default SubChoiceFormmatedName;
