import React from 'react';

import { FormattedMessage } from 'react-intl';

import { List, ListItem, ListSubheader } from '@lumx/react';

import { useStoreState } from 'hooks';
import { DirectoryEntriesExportType, DirectoryExportType } from 'models/dtos';

import SubChoiceFormmatedName from 'components/SubChoiceFormmatedName';

const DirectoryExportSummary = () => {
  const {
    whatToExport,
    directoryExportType,
    directoryEntriesExportType,
    selectedSiteName,
    selectedLang,
    selectedDirectoryName,
  } = useStoreState((state) => state.workflow.export.directory);
  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="EXPORT_CHOICE" />
        </ListSubheader>
        <ListItem className="lumx-spacing-margin-left-regular">
          {whatToExport === 'directories' && (
            <>
              <FormattedMessage id="DIRECTORY_EXPORT_DIRECTORIES" />
              {directoryExportType
                === DirectoryExportType.exportAllDirectories && (
                <SubChoiceFormmatedName id="DIRECTORY_EXPORT_DIRECTORIES_ALL_SITES" />
              )}
              {directoryExportType
                === DirectoryExportType.exportSiteDirectories && (
                <SubChoiceFormmatedName id="DIRECTORY_EXPORT_SITE_DIRECTORIES" />
              )}
            </>
          )}
          {whatToExport === 'directoryEntries' && (
            <>
              <FormattedMessage id="DIRECTORY_EXPORT_DIRECTORIES_ENTRIES" />
              {directoryEntriesExportType
                === DirectoryEntriesExportType.exportAllPlatformDirectoriesEntries && (
                <SubChoiceFormmatedName id="DIRECTORY_EXPORT_ALL_DIRECTORIES_ENTRIES" />
              )}
              {directoryEntriesExportType
                === DirectoryEntriesExportType.exportAllSiteDirectoriesEntries && (
                <SubChoiceFormmatedName id="DIRECTORY_EXPORT_ALL_SITE_DIRECTORIES_ENTRIES" />
              )}
              {directoryEntriesExportType
                === DirectoryEntriesExportType.exportDirectoryEntries && (
                <SubChoiceFormmatedName id="DIRECTORY_EXPORT_ALL_SITE_DIRECTORY_ENTRIES" />
              )}
            </>
          )}
        </ListItem>
        {selectedLang && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_LANG" />
            </ListSubheader>
            <ListItem>
              <span>{selectedLang}</span>
            </ListItem>
          </>
        )}
        {selectedSiteName && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_SITE" />
            </ListSubheader>
            <ListItem>
              <span>{selectedSiteName}</span>
            </ListItem>
          </>
        )}
        {selectedDirectoryName && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_DIRECTORY" />
            </ListSubheader>
            <ListItem>
              <span>{selectedDirectoryName}</span>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export default DirectoryExportSummary;
