/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox, TableCell, TableRow, Theme,
} from '@lumx/react';
import React from 'react';
import { DataTableHeader, SelectableRowsOptions } from './models';

export interface DataTableRowProps {
  theme: Theme;
  row: any;
  index: number;
  tableHeaders: Array<DataTableHeader>;
  selectedRows: Array<any>;
  handleClickCheckbox: (checked: boolean, row: any) => void;
  customRowRender?: (data: Array<any>, index: number) => React.Component;
  selectableRows: SelectableRowsOptions;
}

const DataTableRow: React.FC<DataTableRowProps> = ({
  theme,
  row,
  index,
  tableHeaders,
  selectedRows,
  handleClickCheckbox,
  customRowRender,
  selectableRows,
}: DataTableRowProps) => (
  <TableRow key={index}>
    {selectableRows !== SelectableRowsOptions.none && (
      <TableCell key={-1 * (index + 1)}>
        <Checkbox
          value={selectedRows && selectedRows.indexOf(row.id) !== -1}
          theme={theme}
          onChange={(checked: boolean) => handleClickCheckbox(checked, row)}
        />
      </TableCell>
    )}
    {customRowRender && customRowRender(row, index)}
    {!customRowRender
      && tableHeaders.map((header: DataTableHeader, hidx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableCell key={hidx}>
          {header.customBodyRender
            ? header.customBodyRender(row[header.name], index, hidx, row)
            : row[header.name]}
        </TableCell>
      ))}
  </TableRow>
);

export default DataTableRow;
