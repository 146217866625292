
export enum TransactionStatus {
  running = 'running',
  pending = 'pending',
  done = 'done',
  error = 'error',
  planified = 'planified'
}
export interface ITransaction {
  id: number;
  elementKind: string;
  type: string;
  status: string | TransactionStatus;
  startDate: string;
  processedElements: number;
  successElements: number;
  totalElements: number;
  refFile: string;
  title: string;
  customer: string;
  planificationKey: string;
  error: string;
  instanceName: string;
  instanceId: string;
}

