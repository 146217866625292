/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-undef */
import React, { useRef, useEffect } from 'react';

import {
  Button,
  Dialog,
  UserBlock,
  Size,
  Orientation,
  Theme,
  Toolbar,
  List,
  ListItem,
  Divider,
  Icon,
  Emphasis,
} from '@lumx/react';

import { mdiMicrosoft, mdiGoogle, mdiWindowClose } from '@lumx/icons';
import { useStoreActions, useStoreState } from 'hooks';
import { FormattedMessage } from 'react-intl';

interface UserProfileProps {
  theme: Theme;
  size: Size.s | Size.m | Size.l | undefined;
}

let windowObjectReference: any = null;
const previousUrl: any = null;

const UserProfile: React.FC<UserProfileProps> = ({
  theme,
  size,
}: UserProfileProps) => {
  const triggerElement = useRef(null);

  const user = useStoreState((state) => state.user);
  const isDialogOpen = user.isUserProfileDialogOpen;
  const {
    refreshCurrentUser,
    openUserProfileDialog,
    closeUserProfileDialog,
    logoutUserFromProvider,
    loginUser: loginUserWithProvider,
  } = useStoreActions((actions) => actions.user);

  const handleSaveUserProfile = () => {
    closeUserProfileDialog();
  };

  useEffect(() => {
    if (isDialogOpen) {
      refreshCurrentUser();
    }
  }, [isDialogOpen]);

  const openSignInWindow = (url: string, name: string) => {
    // window features
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
       is not closed; therefore, we can bring it back on top of any other
       window with the focus() method. There would be no need to re-create
       the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    var timer = setInterval(() => {
      if (windowObjectReference && windowObjectReference.closed) {
        clearInterval(timer);
        refreshCurrentUser();
      }
    }, 100);
  };

  const handleProviderLogin = async (provider: string) => {
    const loginUrl = await loginUserWithProvider(provider);
    if (!loginUrl) {
      return;
    }
    openSignInWindow(loginUrl, 'login');
  };

  const handleProviderLogout = (provider: string) => {
    logoutUserFromProvider(provider);
  };

  return (
    <>
      <UserBlock
        name={user.fullname}
        avatar={{ image: user.profilePictureUrl || '' }}
        size={size}
        orientation={Orientation.horizontal}
        onClick={() => openUserProfileDialog()}
      />

      <Dialog
        theme={theme}
        parentElement={triggerElement}
        isOpen={isDialogOpen}
        onClose={() => closeUserProfileDialog()}
      //   footer={(
      //     <Toolbar
      //       after={(
      //         <>
      //           <Button
      //             theme={theme}
      //             emphasis={Emphasis.low}
      //             onClick={() => closeUserProfileDialog()}
      //           >
      //         <FormattedMessage id="CANCEL" />
      //           </Button>

      //           <Button
      //             theme={theme}
      //             emphasis={Emphasis.medium}
      //             className="lumx-spacing-margin-left-regular"
      //             onClick={handleSaveUserProfile}
      //           >
      //             Save
      //           </Button>
      //         </>
      //       )}
      //     />
      //   )}
      >
        <div className="lumx-spacing-padding-huge">
          <UserBlock
            theme={theme}
            name={user.fullname}
            avatar={user.profilePictureUrl}
            orientation={Orientation.vertical}
          />

          <div className="lumx-spacing-padding-vertical-huge">
            <Divider />
            <List>
              <ListItem
                key="google"
                size={Size.big}
                before={<Icon icon={mdiGoogle} />}
                after={(
                  <Button
                    onClick={() => (user.isAuthenticatedWith?.google
                      ? handleProviderLogout('google')
                      : handleProviderLogin('google'))}
                    leftIcon={
                      user.isAuthenticatedWith?.google
                        ? mdiWindowClose
                        : mdiGoogle
                    }
                    emphasis={Emphasis.low}
                  >
                    <FormattedMessage
                      id={
                        user.isAuthenticatedWith?.google
                          ? 'DISCONNECT_GOOGLE'
                          : 'CONNECT_GOOGLE'
                      }
                    />
                  </Button>
                )}
              >
                <div className="toolbox-user-profile-provider-entry__title">
                  Google
                </div>
                <div className="toolbox-user-profile-provider-entry__content">
                  <FormattedMessage
                    id={
                      user.isAuthenticatedWith?.google
                        ? user.isAuthenticatedWith?.googleUser || 'CONNECTED'
                        : 'NOT_CONNECTED'
                    }
                  />
                </div>
              </ListItem>
              <ListItem
                key="microsoft"
                size={Size.big}
                before={<Icon icon={mdiMicrosoft} />}
                after={(
                  <Button
                    onClick={
                      user.isAuthenticatedWith?.microsoft
                        ? () => handleProviderLogout('microsoft')
                        : () => handleProviderLogin('microsoft')
                    }
                    leftIcon={
                      user.isAuthenticatedWith?.microsoft
                        ? mdiWindowClose
                        : mdiMicrosoft
                    }
                    emphasis={Emphasis.low}
                  >
                    <FormattedMessage
                      id={
                        user.isAuthenticatedWith?.microsoft
                          ? 'DISCONNECT_MICROSOFT'
                          : 'CONNECT_MICROSOFT'
                      }
                    />
                  </Button>
                )}
              >
                <div className="toolbox-user-profile-provider-entry__title">
                  Microsoft
                </div>
                <div className="toolbox-user-profile-provider-entry__content">
                  <FormattedMessage
                    id={
                      user.isAuthenticatedWith?.microsoft
                        ? user.isAuthenticatedWith?.microsoftUser || 'CONNECTED'
                        : 'NOT_CONNECTED'
                    }
                  />
                </div>
              </ListItem>
            </List>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserProfile;
