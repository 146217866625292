/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ThScope, Theme } from '@lumx/react';

export interface DataTableHeader {
  label: string | React.ReactNode;
  name: string;
  searchable?: boolean;
  isSortable?: boolean;
  scope?: ThScope;
  download?: boolean;
  customBodyRender?: (
    cellValue: string,
    rowIndex: number,
    headerIndex: number,
    row: unknown
  ) => React.ReactNode | string;
}

export enum SelectableRowsOptions {
  multiple = 'multiple',
  single = 'single',
  none = 'none',
}

export interface DataTableOptions {
  paginated?: boolean;
  serverSide?: boolean;
  count?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: Array<number>;
  viewColumns?: boolean;
  filter?: boolean;
  search?: boolean;
  sort?: boolean;
  download?: boolean;
  rpp?: number;
  selectableRows?: SelectableRowsOptions;
  baseToolbar?: (theme?: Theme) => React.Component;
  actionToolbar?: (
    selectedRows: Array<any>,
    displayData: Array<any>,
    setSelectedRows: (selectedRows: Array<any>) => void,
    theme: Theme
  ) => React.Component;
  footerToolbar?: (
    count: number,
    page: number,
    rowsPerPage: number,
    changeRowsPerPage: (rowsPerPage: number) => void,
    changePage: (page: number) => void,
    theme: Theme
  ) => React.Component;
  customRowRender?: (data: Array<any>, index: number) => React.Component;
  onRowSelected?: (selectedRows: Array<any>) => void;
  onColumnSortChange?: (changedColumn: string, direction: string) => void;
  noDataFiller?: any;
  customToolbar?: (
    tableData: Array<any>,
    tableHeaders: Array<DataTableHeader>,
    displayedHeaders: Array<DataTableHeader>,
    setDisplayedHeaders: (headers: Array<DataTableHeader>) => void
  ) => React.Component;
  translatedPaginationText?: ITransalatedPaginationText;
  filterButtonTitle?: any;
  defaultSortHeaderName?: string
  buttonTooltips?: IButtonTooltips
}

interface IButtonTooltips {
  viewColumnsButtonTooltipLabel?: string;
  filterTableTooltipLabel?: string
}
export interface ITransalatedPaginationText {
  rowsPerPage?: string | React.ReactNode;
  of?: string | React.ReactNode;
}
