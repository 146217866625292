export enum DrivePickerActionType {
  setFiles = 'setFiles',
  setQuery = 'setQuery',
  setSelectedPickerMainCategory = 'setSelectedPickerMainCategory',
  setSelectedPickerSubCategory = 'setSelectedPickerSubCategory',
  setSelectPickerCategInfos = 'setSelectPickerCategInfos',
}

export interface IDrivePickerAction {
  type: DrivePickerActionType;
  payload: any;
}

export const setFiles = (payload: any) => ({
  type: DrivePickerActionType.setFiles,
  payload,
});

export const setQuery = (payload: string | undefined) => ({
  type: DrivePickerActionType.setQuery,
  payload,
});

export const setSelectedPickerMainCategory = (payload: string | undefined) => ({
  type: DrivePickerActionType.setSelectedPickerMainCategory,
  payload,
});

export const setSelectedPickerSubCategory = (payload: string | undefined) => ({
  type: DrivePickerActionType.setSelectedPickerSubCategory,
  payload,
});

export const setSelectPickerCategInfos = (payload: any) => ({
  type: DrivePickerActionType.setSelectPickerCategInfos,
  payload,
});
