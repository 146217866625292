import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Tab, Tabs, TabsLayout } from '@lumx/react';

import { useStoreActions, useStoreState } from 'hooks';
import { useTheme } from 'Theme';

import TransactionTable from 'containers/TransactionTable';
import PlanificationTable from 'containers/PlanificationTable';

const DashboardMainContent: React.FC = () => {
  const setTab = useStoreActions((actions) => actions.dashboard.setTab);
  const currentTab = useStoreState((state) => state.dashboard.tab);
  const { theme } = useTheme();

  const handleTabClick = ({ index }: any) => setTab(index);

  return (
    <main className="lum-toolbox-main-content">
      <Tabs
        theme={theme.theme}
        activeTab={currentTab}
        onTabClick={handleTabClick}
        layout={TabsLayout.clustered}
      >
        <Tab
          // @ts-ignore
          label={<FormattedMessage id="TRANSACTION" />}
        >
          <TransactionTable />
        </Tab>
      </Tabs>
    </main>
  );
};

export default DashboardMainContent;
