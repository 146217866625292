import React from 'react';

import { useStoreState } from 'hooks';
import { DashboardCategory } from 'models/dashboard';

import UserImportParams from './UserImportParams';

const Step2 = () => {
  const category = useStoreState((state) => state.workflow.modal.category);
  return (
    <div className="lumx-spacing-padding-horizontal-huge">
      {category === DashboardCategory.user && <UserImportParams />}
    </div>
  );
};

export default Step2;
