/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { mdiFilterVariant } from '@lumx/icons';
import {
  Emphasis,
  IconButton,
  List,
  ListItem,
  Popover,
  Select,
  Size,
  Theme,
} from '@lumx/react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useBooleanState from '../../hooks/useBooleanState';
import { DataTableHeader } from './models';

interface FilterSelectsProps {
  theme?: Theme;
  headerDataValues: Array<any>;
  onFilterSelected: any;
  index: number;
  header: any;
  filters: Array<any>;
}

const FilterSelects = ({
  theme = Theme.light,
  headerDataValues,
  onFilterSelected,
  index,
  header,
  filters,
}: FilterSelectsProps) => {
  const [value, setValue] = useState(headerDataValues[0]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, closeSelect, , toggleSelect] = useBooleanState(false);

  // Reset filters when removed via chips
  useEffect(() => {
    const filterNames = filters.map((el: any) => el.filter);
    if (filterNames && filterNames.length > 0) {
      const isInFilter = filterNames.includes(value);
      if (value !== 'ALL' && !isInFilter) {
        setValue(headerDataValues[0]);
      }
    } else {
      setValue(headerDataValues[0]);
    }
  }, [filters]);

  const handleFilterSelected = (filter: string, headerName: string) => {
    setValue([filter]);
    closeSelect();
    if (onFilterSelected) {
      onFilterSelected(filter, headerName);
    }
  };
  const [highlighted, setHighlighted] = useState<any>(null);

  return (
    <ListItem theme={theme} key={index}>
      <Select
        theme={theme}
        style={{ width: '100%' }}
        label={header.label}
        value={(<FormattedMessage id={value} /> as any)}
        isOpen={isOpen}
        onInputClick={toggleSelect}
        onDropdownClose={closeSelect}
      >
        <List theme={theme}>
          {headerDataValues && headerDataValues.length > 0
            ? headerDataValues.map((dataValue: string) => {
                const isSelected = value === dataValue;
                return (
                  <ListItem
                    className="lumx-spacing-padding-horizontal-big"
                    theme={theme}
                    key={dataValue}
                    isSelected={isSelected}
                    isHighlighted={highlighted && highlighted === dataValue}
                    onItemSelected={() =>
                      handleFilterSelected(dataValue, header.name)
                    }
                    size={Size.tiny}
                  >
                    <div onMouseEnter={() => setHighlighted(dataValue)}>
                      <FormattedMessage id={dataValue} />
                    </div>
                  </ListItem>
                );
              })
            : [
                <ListItem theme={theme} key={0} size={Size.tiny}>
                  No data
                </ListItem>,
              ]}
        </List>
      </Select>
    </ListItem>
  );
};
interface FilterProps {
  theme?: Theme;
  tableHeaders: Array<DataTableHeader>;
  tableData: Array<any>;
  onFilter: (newFilters: Array<any>) => void;
  filters: Array<any>;
  filterTableTooltipLabel?: string;
}

const Filter = ({
  theme = Theme.light,
  tableHeaders,
  tableData,
  onFilter,
  filters,
  filterTableTooltipLabel = 'Filter table',
}: FilterProps) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const anchorRef = useRef(null);

  /*
   * Filter values of the table
   */
  const onFilterSelected = (filter: string, headerName: string) => {
    const previousFilters = filters.filter(
      (el: any) => el.headerName !== headerName
    );
    const newFilters = [...previousFilters, { filter, headerName }];
    if (onFilter) {
      onFilter(newFilters);
    }
  };

  return (
    <>
      <div ref={anchorRef}>
        <IconButton
          theme={theme}
          title={filterTableTooltipLabel}
          emphasis={Emphasis.low}
          icon={mdiFilterVariant}
          onClick={() => setIsTooltipDisplayed(!isTooltipDisplayed)}
        />
      </div>
      <Popover
        className="lumx-spacing-padding-horizontal-huge"
        anchorRef={anchorRef}
        isOpen={isTooltipDisplayed}
      >
        <div
          className="lumx-spacing-padding-vertical-huge"
          onMouseLeave={() => setIsTooltipDisplayed(false)}
          style={{ minWidth: '200px' }}
        >
          <FormattedMessage id="FILTERS" />
          <List theme={theme}>
            {(tableHeaders || []).map(
              (header: DataTableHeader, index: number) => {
                const temp: Set<string> = new Set(
                  (tableData || [])
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    .map((data: any) => data[header.name]?.toString() as string)
                    .filter(Boolean)
                );
                const headerDataValues = Array.from(temp);
                headerDataValues.unshift('ALL');
                return (
                  <FilterSelects
                    theme={theme}
                    key={index}
                    headerDataValues={headerDataValues}
                    index={index}
                    header={header}
                    onFilterSelected={onFilterSelected}
                    filters={filters}
                  />
                );
              }
            )}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default Filter;
