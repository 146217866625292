import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Alignment,
  Grid,
  Toolbar,
  Size,
  Link,
  Icon,
  Orientation,
  FlexBox,
} from '@lumx/react';

import { useTheme } from 'Theme';
import { useStoreState } from 'hooks';
import UserProfile from 'containers/UserProfile';
import { mdiFile } from '@lumx/icons';

const DashboardTopBar = () => {
  const currentCategory = useStoreState((state) => state.dashboard.category);
  const { theme } = useTheme();

  return (
    <div className="lum-toolbox-top-bar">
      <Toolbar
        theme={theme}
        label={(
          <Grid theme={theme.theme} hAlign={Alignment.center}>
            <span className={theme.colorClass}>
              <FormattedMessage id={currentCategory.toUpperCase()} />
            </span>
          </Grid>
        )}
        after={(
          <FlexBox
            gap={Size.huge}
            orientation={Orientation.horizontal}
            hAlign={Alignment.center}
          >
            <Link href="/doc/" target="_blank">
              <FlexBox
                style={{ marginRight: '8px' }}
                orientation={Orientation.horizontal}
                hAlign={Alignment.center}
              >
                <Icon icon={mdiFile} />
                Doc
              </FlexBox>
            </Link>
            <UserProfile size={Size.m} theme={theme.theme} />
          </FlexBox>
        )}
      />
    </div>
  );
};

export default DashboardTopBar;
