import React from 'react';
import { FormattedMessage } from 'react-intl';

const SiteParams = () => {
  return (
    <div>
      <FormattedMessage id="NO_PARAMETERS" />
    </div>
  );
};

export default SiteParams;
