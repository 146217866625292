import { useStoreState } from 'hooks';
import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const ExportWorkflow = () => {
  const currentStep = useStoreState(
    (state) => state.workflow.modal.currentStep
  );
  return (
    <>
      {currentStep === 0 && <Step2 />}
      {currentStep === 1 && <Step3 />}
      {currentStep === 2 && <Step4 />}
    </>
  );
};

export default ExportWorkflow;
