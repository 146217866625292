/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { mdiFolder } from '@lumx/icons';
import { Icon, Size, Theme } from '@lumx/react';
import React from 'react';

import { getThemeRelatedTextClass } from '@lumapps-extensions-playground/common';
import { FormattedMessage } from '@lumapps-extensions-playground/translations';

interface EmptyStateProps {
  theme?: Theme,
}

const EmptyState: React.FC<EmptyStateProps> = ({
  theme=Theme.light,
}: EmptyStateProps) => {

  const themeRelatedTextClass = getThemeRelatedTextClass(theme);

  return (
    <div 
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="lumx-spacing-padding-huge"
    >
      <Icon
        icon={mdiFolder}
        hasShape
        theme={theme}
        size={Size.xxl}
      />
      <span className={`lumx-typography-subtitle1 lumx-spacing-margin-top-huge ${themeRelatedTextClass}`}>
        <FormattedMessage id="drivepicker.empty.title" />
      </span>
      <span className={`lumx-typography-body1 ${themeRelatedTextClass}`}>
        <FormattedMessage id="drivepicker.empty.description" />
      </span>
    </div>
  );
};

export default EmptyState;
