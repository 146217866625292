/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox, Divider, List, ListItem, Theme,
} from '@lumx/react';
import React from 'react';
import { DataTableHeader } from './models';

interface DataTableSmallProps {
  theme: Theme;
  tableData: Array<any>;
  tableHeaders: Array<DataTableHeader>;
  isSelectable: boolean;
  handleClickCheckbox?: (checked: boolean, row: any) => void;
  selectedRows?: Array<any>;
}

const DataTableSmall: React.FC<DataTableSmallProps> = ({
  theme,
  tableData,
  tableHeaders,
  isSelectable,
  handleClickCheckbox,
  selectedRows,
}: DataTableSmallProps) => (
  <>
    <List theme={theme}>
      {tableData.map((row: any, index: number) => (
        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          before={
                isSelectable && (
                  <div
                    style={{
                      padding: '8px',
                    }}
                  >
                    <Checkbox
                      value={
                        selectedRows && selectedRows.indexOf(row.id) !== -1
                      }
                      theme={theme}
                      onChange={(checked: boolean) => handleClickCheckbox && handleClickCheckbox(checked, row)}
                    />
                  </div>
                )
              }
        >
          {/* ROw block */}
          <List theme={theme}>
            {tableHeaders.map((header: DataTableHeader, hidx: number) => (
              <>
                {hidx > 0 && hidx < tableHeaders.length && (
                <Divider
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  }}
                />
                )}

                <ListItem
                  key={header.name}
                  style={{
                    minHeight: '40px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '50%',
                      }}
                    >
                      {header.label}
                    </div>
                    <div
                      style={{
                        width: '50%',
                      }}
                    >
                      {header.customBodyRender
                        ? header.customBodyRender(
                          row[header.name],
                          index,
                          hidx,
                          row,
                        )
                        : row[header.name]}
                    </div>
                  </div>
                </ListItem>
              </>
            ))}
          </List>
          <Divider />
        </ListItem>
      ))}
    </List>
  </>
);

export default DataTableSmall;
