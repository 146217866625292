import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  DirectoryImportDTO,
  GroupImportDTO,
  MetadataImportDTO,
  UserImportDTO,
  ContentImportDTO,
} from 'models/dtos';

export interface IImportService {
  getUploadUrl: (
    filename: string,
    platformID: string
  ) => Promise<AxiosResponse>;
  startImportUsers: (payload: UserImportDTO) => Promise<AxiosResponse>;
  startImportDirectory: (payload: DirectoryImportDTO) => Promise<AxiosResponse>;
  startImportGroup: (payload: GroupImportDTO) => Promise<AxiosResponse>;
  startImportContent: (payload: ContentImportDTO) => Promise<AxiosResponse>;
  startImportMetadata: (payload: MetadataImportDTO) => Promise<AxiosResponse>;
  uploadFile: (file: any, uploadUrl: string) => Promise<AxiosResponse>;
  deleteFile: (deleteUrl: string) => Promise<AxiosResponse>;
}

export const importServiceFactory = (client: AxiosInstance): IImportService => {
  const importService: IImportService = {
    startImportUsers: async (payload: UserImportDTO) =>
      client.post('/api/import/user', payload),
    startImportDirectory: async (payload: DirectoryImportDTO) =>
      client.post('/api/import/directory', payload),
    startImportGroup: async (payload: GroupImportDTO) =>
      client.post('/api/import/group', payload),
    startImportContent: async (payload: ContentImportDTO) =>
      client.post('/api/import/content', payload),
    startImportMetadata: async (payload: MetadataImportDTO) =>
      client.post('/api/import/metadata', payload),
    getUploadUrl: async (filename: string, platformID: string) => {
      const params = { filename, platformID };
      return client.get('/api/import/uploadUrl', { params });
    },
    uploadFile: async (file: any, uploadUrl: string) => {
      const headers = {
        'Content-Type': 'text/csv',
      };
      return axios({
        method: 'put',
        url: uploadUrl,
        data: file,
        headers,
      });
    },
    deleteFile: async (deleteUrl: string) => client.delete(deleteUrl),
  };
  return importService;
};
