import React from 'react';

import { FormattedMessage } from 'react-intl';

import { List, ListItem, ListSubheader } from '@lumx/react';

import { useStoreState } from 'hooks';

const CommunityExportSummary = () => {
  const {
    exportAllSiteCommunities,
    exportSiteCommunityPosts,
    communityName,
    communityLang,
  } = useStoreState((state) => state.workflow.export.community);
  return (
    <div>
      <List>
        <ListSubheader>
          <FormattedMessage id="EXPORT_CHOICE" />
        </ListSubheader>
        <ListItem>
          {exportAllSiteCommunities && (
            <FormattedMessage id="COMMUNITY_EXPORT_ALL_SITE_COMMUNITIES" />
          )}
          {exportSiteCommunityPosts && (
            <FormattedMessage id="COMMUNITY_EXPORT_ALL_COMMUNITY_POSTS" />
          )}
        </ListItem>
        {communityLang && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_LANG" />
            </ListSubheader>
            <ListItem>
              <span>{communityLang}</span>
            </ListItem>
          </>
        )}
        {exportSiteCommunityPosts && communityName && (
          <>
            <ListSubheader>
              <FormattedMessage id="EXPORT_SELECTED_COMMUNITY" />
            </ListSubheader>
            <ListItem>{communityName}</ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export default CommunityExportSummary;
